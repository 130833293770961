.combo-color-picker-wrapper {
    position: relative;
    display: inline-flex;
    width: 100%;
    height: 32px;
    .color-picker-container {
        width: 100%;
        display: inline-block;
        // .input-container {
        //     display: inline-flex;
        //     width: 100%;
        //     margin: 3px;
        //     background-color: #ffffff;
        //     border: 1px solid #E2E2E7;
        //     .tag-container {
        //         background-color: #ffffff;
        //         border: 1px solid #E2E2E7;
        //         display: inline-flex;
        //         padding-left: 10px;
        //         align-items: center;
        //         flex: 1;
        //         height: 32px;
        //     }
        // }
        .tag-container {
            width: 100%;
            background-color: @col_white;
            border: 1px solid @col_gray_1;
            display: inline-flex;
            padding-left: 10px;
            align-items: center;
            flex: 1;
            height: 32px;
        }
        .color-picker {
            background-color: @col_white;
            //.mixin-shadow-small;
            .flexbox;
            margin: 0;
            padding: 2px;
            list-style: none;
            flex-wrap: wrap;
            z-index: 1000;
            box-sizing: content-box;
            &.vertical {
                flex-direction: column-reverse;
            }
            &.horizontal {
                flex-direction: row;
            }
            li {
                margin: 0;
                list-style: none;
            }
            li:hover,
            li:focus,
            li.selected {
                border: 2px solid @col_white;
            }
        }
        .color-picker-dropdown {
            width: min-content;
            background-color: @col_white;
            .mixin-shadow-medium;
            .flexbox;
            position: absolute;
            right: 0;
            padding: 4px;
            margin: 12px 0 0 0;
            list-style: none;
            flex-wrap: wrap;
            z-index: 1000;
            box-sizing: content-box;
            &:before {
                content: '';
                position: absolute;
                top: -11px;
                right: 7px;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 11px solid @col_white;
            }
        }
    }

    // &.simple{
    //   .tag-container {
    //     height : 32px;
    //     width : 140px;
    //     > i{
    //       position:absolute;
    //       right:10px;
    //     }
    //   }
    // }
}
