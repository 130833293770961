.search-dropdown {
    .search-box {
        width: 98%;
        height: 34px;
        display: inline-flex;
        align-items: center;
        margin: 5px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 5px !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        border-radius: 0;
        input {
            border: 0;
        }
        .icon-remove-field {
            display: block;
            width: 24px;
            height: 24px;
            background-image: url(../assets/images/SVGs-24scale/icon_line_cancel_24-48-96.svg);
        }
    }
}
