#page-template-editor {
    .error-status {
        flex: 1;
        border-right: solid 3px @col_red;
    }

    .error-icon {
        .i24;
        .icon_solid_alert_critical_24;
    }

    .field-error {
        display: block;
        width: 100%;
        li {
            background-color: @col_red;
            list-style-type: none;
            color: @col_white;
            font-weight: bold;
            padding: 8px 16px 8px 56px;
        }
    }
    .condition-error {
        border-top: 1px solid;
        border-color: @col_gray_3;
        background-color: @col_red;
        display: block;
        width: 100%;
        padding: 4px 16px 4px 56px;
        & ul {
            list-style: none;
            padding-left: 30px;
            & li {
                color: @col_white;
            }
        }
    }
    .target-error {
        border-top: 1px solid;
        border-color: @col_gray_3;
        background-color: @col_trimble_blue;
        display: block;
        width: 100%;
        padding: 8px 16px 8px 56px;
        & ul {
            list-style: none;
            padding-left: 30px;
            & li {
                color: @col_white;
            }
        }
    }
}
