.mixin-notification {
    padding: @padding-small;
    border-radius: 2px;
    display: flex;
}

.notification {
    .mixin-notification;
    width: 100%;
    &.success {
        border: 1px solid @col_green;
        background: @col_green_pale;
        .notification-heading {
            color: @col_green;
        }
    }
    &.info {
        border-left: 5px solid @col_blue;
        background: @col_blue_pale;
        .messages {
            .hint-text-dark;
        }
        .notification-heading {
            color: @col_blue;
        }
    }
    &.error {
        border-left: 5px solid @col_red;
        background: @col_red_pale;
        .messages {
            .hint-text-dark;
            text-align: left;
        }
        .notification-heading {
            color: @col_red;
        }
    }
    &.critical {
        border: 1px solid @col_red;
        background: @col_red_pale;
        .notification-heading {
            color: @col_red;
        }
    }
    &.warning {
        border-left: 5px solid @col_orange_dark;
        background: @col_orange_pale;
        .messages {
            .hint-text-dark;
        }
        .notification-heading {
            color: @col_orange_dark;
        }
    }
    .messages {
        display: flex;
        width: 100%;

        justify-content: center;
        flex-direction: column;
        .notification-heading {
            font-size: 14px;
            font-weight: 600;
        }

        .processing-tips {
            font-size: 1.3rem;
            margin-top: 1em;
        }
    }
}

.info-message {
    .flex-box;
    .justify-center;
    .align-center;
    flex-direction: column;
    padding: @padding-default 0;
    background: @col_trimble_blue_mid;
    color: @col_white;
    font-weight: 600;

    .workspaces-count {
        font-weight: bold;
    }

    a {
        color: white;
    }
}

.info-hyperlink {
    cursor: pointer;
    text-decoration: underline;
}
