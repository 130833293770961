post-processing-tab {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    post-processing-options .post-processing .body {
        padding-bottom: 0;
    }

    .footer {
        .description {
            padding: 0 @padding-medium @padding-medium @padding-medium;
            background-color: #1f3d52;
            color: @col_white;
        }
        #custom-basemap-folder-selection-popup {
            .modal-content {
                height: 500px;
                .custom-basemap-folder-selection-popup-content {
                    .folder-selection-section-wrapper {
                        display: flex;
                    }
                }
            }
        }
    }
}

.post-processing {
    position: relative;

    .body {
        padding: @padding-medium;
        .font-title,
        .subtitle,
        .gsp-toggle-container {
            display: flex;
            margin-bottom: @padding-default;
        }
    }
}

.custom-base-station-body {
    padding: 0 @padding-medium @padding-medium @padding-medium;
    .font-title,
    .subtitle {
        display: flex;
        margin-bottom: @padding-default;
    }
    .custom-base-station-settings {
        padding: @padding-small 0 @padding-small 0;
        section {
            padding: @padding-small 0 @padding-small 0;
        }
        .section-title {
            .flex-box;
            align-items: center;
        }
        .folder-selection {
            .flex-box;
            align-items: center;
            padding: @padding-small 0 0 0;
            .selected-folder-details {
                background-color: @col_gray_0;
                border-radius: 2px;
                padding: @padding-tiny @padding-small @padding-tiny @padding-small;
                max-width: 300px;
                .text-overflow;
            }
            .folder-selector-handle {
                cursor: pointer;
                color: @col_trimble_blue_mid;
                padding: @padding-tiny @padding-small @padding-tiny @padding-default;
                font-weight: 600;
            }

            .disabled {
                cursor: default;
                pointer-events: none;
                opacity: 0.5;
            }
        }
        .time-selection-section {
            .flex-box;
            padding: @padding-small 0 @padding-small 0;
        }
    }
}

.offline-gnss-wizard-mode {
    .body {
        padding: @padding-medium 0;
    }

    .info-message {
        display: block;
        text-align: center;
        width: 109.5%;
        margin-left: -3rem;
    }
}
