// Tag Input Alternative -> MatChips + MatAutcomplete Fix
.tag-add-mode {
    background: #ffffff;
    border: 1px solid #e2e2e7 !important;
}
.tag-input-alternative {
    display: flex;
    align-self: center;
    border: 1px solid #e2e2e7;
    background: #fff;
    padding: 0 0 @padding-tiny;

    .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action {
        padding: 0;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip {
        margin-left: 4px !important;
        margin-bottom: 0;
    }

    .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
        margin-left: -3px;
    }

    mat-chip-list,
    mat-chip-grid {
        mat-chip-option,
        mat-chip-row {
            font-size: 1em;
            background: @col_gray_2 !important;
            border-radius: 100px;
            padding: @padding-small;
            height: 30px;
            margin-bottom: 0;

            .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
                font-size: 13px;
                font-weight: 600;
                font-family: Open Sans;
                margin-left: -7px;
                max-width: 119px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .remove-button {
                color: @col_gray_2 !important;
                background-color: @col_gray_dark_2;
                border-radius: 800px;
                font-weight: 400;
                font-size: 10px;
                padding: 0 !important;
                margin-left: 5px;
            }
        }

        .button-add-chip {
            margin-top: 4px;
            .button-icon-text {
                padding: @padding-tiny;
            }
        }

        .cancel-add-chip {
            padding: @padding-tiny @padding-small;
        }
    }

    .button-text-action {
        display: flex;
        align-items: center;
        button {
            background: transparent;
            border: none;
        }
    }

    input.mat-mdc-chip-input {
        flex: 1 0;
        margin-left: 0px;
        padding-left: @padding-tiny;
        padding-top: @padding-tiny;
        font-size: 1em;
        max-width: 100%;
        height: 34px;
        border: none;
    }

    &.task-detail-panel {
        display: flex;
        flex-direction: column;
        width: 100%;

        mat-chip-list,
        mat-chip-grid {
            mat-chip-option,
            mat-chip-row {
                .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
                    max-width: inherit;
                }
            }
        }

        input.mat-mdc-chip-input {
            max-width: inherit;
        }
    }

    &.tag-bulk-set {
        display: flex;
        flex-direction: column;
        padding: 0;
        input {
            min-height: 32px;
            padding-top: 0;
            padding-left: 10px;
        }
        .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
            margin-left: @padding-tiny;
        }
    }
}

.mat-mdc-option.mdc-list-item {
    font-size: 0.8em;
    min-height: 40px;
    .mdc-list-item__primary-text {
        word-break: break-all;
    }
}
