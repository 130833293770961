.height-full {
    height: 100% !important;
}

.width-full {
    width: 100% !important;
}

.max-width-100 {
    max-width: 100% !important;
}

.center-text {
    text-align: center;
}

.no-gutters {
    margin-left: 0;
    margin-right: 0;
}

// Margin
.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.mt-1 {
    margin-top: 1rem;
}

.mt-2 {
    margin-top: 2rem;
}

.pa-0 {
    padding: 0 !important;
}

// Padding
.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 1rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.show-inactive {
    opacity: 0.5;
    cursor: default !important;
}

// Font Weight
.font-thin {
    font-weight: 100;
}

.font-extralight {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}

// Text Align
.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}
.text-justify {
    text-align: justify;
}

.text-start {
    text-align: start;
}
.text-end {
    text-align: end;
}
