.i24 {
    width: 24px;
    height: 24px;
}

.i48 {
    width: 48px;
    height: 48px;
}

.i96 {
    width: 96px;
    height: 96px;
}

.i16 {
    width: 16px;
    height: 16px;
}

.i32 {
    width: 32px;
    height: 32px;
}

.i64 {
    width: 64px;
    height: 64px;
}

.icon_line_angle_24,
.icon_line_angle.i24,
.icon_line_angle.i48,
.icon_line_angle.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_angle_24-48-96.svg');
}

.icon_line_arrow_down_24,
.icon_line_arrow_down.i24,
.icon_line_arrow_down.i48,
.icon_line_arrow_down.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_arrow_down_24-48-96.svg');
}

.icon_line_arrow_up_24,
.icon_line_arrow_up.i24,
.icon_line_arrow_up.i48,
.icon_line_arrow_up.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_arrow_up_24-48-96.svg');
}

.icon_line_assistance_24,
.icon_line_assistance.i24,
.icon_line_assistance.i48,
.icon_line_assistance.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_assistance_24-48-96.svg');
}

.icon_line_asterisk_24,
.icon_line_asterisk.i24,
.icon_line_asterisk.i48,
.icon_line_asterisk.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_asterisk_24-48-96.svg');
}

.icon_line_barcode_24,
.icon_line_barcode.i24,
.icon_line_barcode.i48,
.icon_line_barcode.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_barcode_24-48-96.svg');
}

// .icon_line_b_24,
// .icon_line_b.i24,
// .icon_line_b.i48,
// .icon_line_b.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_line_b_24-48-96.svg');
// }

.icon_line_bluetooth_24,
.icon_line_bluetooth.i24,
.icon_line_bluetooth.i48,
.icon_line_bluetooth.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_bluetooth_24-48-96.svg');
}

.icon_line_bluetooth_active_24,
.icon_line_bluetooth_active.i24,
.icon_line_bluetooth_active.i48,
.icon_line_bluetooth_active.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_bluetooth_active_24-48-96.svg');
}

.icon_line_camera_24,
.icon_line_camera.i24,
.icon_line_camera.i48,
.icon_line_camera.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_camera_24-48-96.svg');
}

.icon_line_cancel_24,
.icon_line_cancel.i24,
.icon_line_cancel.i48,
.icon_line_cancel.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cancel_24-48-96.svg');
}

.icon_line_cancel_white_24,
.icon_line_cancel_white.i24,
.icon_line_cancel_white.i48,
.icon_line_cancel_white.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cancel_white_24-48-96.svg');
}

.icon_line_cart_24,
.icon_line_cart.i24,
.icon_line_cart.i48,
.icon_line_cart.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cart_24-48-96.svg');
}

.icon_line_chevron_down_24,
.icon_line_chevron_down.i24,
.icon_line_chevron_down.i48,
.icon_line_chevron_down.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_chevron_down_24-48-96.svg');
}

.icon_line_chevron_left_24,
.icon_line_chevron_left.i24,
.icon_line_chevron_left.i48,
.icon_line_chevron_left.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_chevron_left_24-48-96.svg');
}

.icon_line_chevron_right_24,
.icon_line_chevron_right.i24,
.icon_line_chevron_right.i48,
.icon_line_chevron_right.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_chevron_right_24-48-96.svg');
}

.icon_line_sort_arrow_down_24,
.icon_line_sort_arrow_down.i16,
.icon_line_sort_arrow_down.i24,
.icon_line_sort_arrow_down.i48,
.icon_line_sort_arrow_down.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_sort_arrow_down.svg');
}

.icon_line_sort_arrow_up_24,
.icon_line_sort_arrow_up.i16,
.icon_line_sort_arrow_up.i24,
.icon_line_sort_arrow_up.i48,
.icon_line_sort_arrow_up.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_sort_arrow_up.svg');
}

.icon_line_chevron_up_24,
.icon_line_chevron_up.i24,
.icon_line_chevron_up.i48,
.icon_line_chevron_up.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_chevron_up_24-48-96.svg');
}

.icon_line_choice_24,
.icon_line_choice.i24,
.icon_line_choice.i48,
.icon_line_choice.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_choice_24-48-96.svg');
}

.icon_line_clip_24,
.icon_line_clip.i24,
.icon_line_clip.i48,
.icon_line_clip.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_clip_24-48-96.svg');
}

.icon_line_cloud_24,
.icon_line_cloud.i24,
.icon_line_cloud.i48,
.icon_line_cloud.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_24-48-96.svg');
}

.icon_line_cloud_down_24,
.icon_line_cloud_down.i24,
.icon_line_cloud_down.i48,
.icon_line_cloud_down.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_down_24-48-96.svg');
}

// .icon_line_cloud_search_24-4_24,
// .icon_line_cloud_search_24-4.i24,
// .icon_line_cloud_search_24-4.i48,
// .icon_line_cloud_search_24-4.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_search_24-4_24-48-96.svg');
// }

.icon_line_cloud_search_24,
.icon_line_cloud_search.i24,
.icon_line_cloud_search.i48,
.icon_line_cloud_search.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_search_24-48-96.svg');
}

.icon_line_cloud_sync_24,
.icon_line_cloud_sync.i24,
.icon_line_cloud_sync.i48,
.icon_line_cloud_sync.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_sync_24-48-96.svg');
}

.icon_line_cloud_updown_24,
.icon_line_cloud_updown.i24,
.icon_line_cloud_updown.i48,
.icon_line_cloud_updown.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_updown_24-48-96.svg');
}

.icon_line_cloud_up_24,
.icon_line_cloud_up.i24,
.icon_line_cloud_up.i48,
.icon_line_cloud_up.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_cloud_up_24-48-96.svg');
}

.icon_line_column_24,
.icon_line_column.i24,
.icon_line_column.i48,
.icon_line_column.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_column_24-48-96.svg');
}

.icon_line_comment_24,
.icon_line_comment.i24,
.icon_line_comment.i48,
.icon_line_comment.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_comment_24-48-96.svg');
}

.icon_line_comment_add_24,
.icon_line_comment_add.i24,
.icon_line_comment_add.i48,
.icon_line_comment_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_comment_add_24-48-96.svg');
}

.icon_line_comment_conversation_24,
.icon_line_comment_conversation.i24,
.icon_line_comment_conversation.i48,
.icon_line_comment_conversation.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_comment_conversation_24-48-96.svg');
}

.icon_line_comment_edit_24,
.icon_line_comment_edit.i24,
.icon_line_comment_edit.i48,
.icon_line_comment_edit.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_comment_edit_24-48-96.svg');
}

.icon_line_comment_remove_24,
.icon_line_comment_remove.i24,
.icon_line_comment_remove.i48,
.icon_line_comment_remove.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_comment_remove_24-48-96.svg');
}

.icon_line_condition_24,
.icon_line_condition.i24,
.icon_line_condition.i48,
.icon_line_condition.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_condition_24-48-96.svg');
}

.icon_line_correction_source_24,
.icon_line_correction_source.i24,
.icon_line_correction_source.i48,
.icon_line_correction_source.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_correction_source_24-48-96.svg');
}

.icon_line_correction_source_24,
.icon_line_correction_source.i24,
.icon_line_correction_source.i48,
.icon_line_correction_source.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_correction_source_24-48-96.svg');
}

.icon_line_correction_status_24,
.icon_line_correction_status.i24,
.icon_line_correction_status.i48,
.icon_line_correction_status.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_correction_status_24-48-96.svg');
}

.icon_line_plus_white_24,
.icon_line_plus_white.i24,
.icon_line_plus_white.i48,
.icon_line_plus_white.i96 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_plus_white_16-32-64.svg');
}

.icon_line_correction_status_24,
.icon_line_correction_status.i24,
.icon_line_correction_status.i48,
.icon_line_correction_status.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_correction_status_24-48-96.svg');
}

.icon_line_date_24,
.icon_line_date.i24,
.icon_line_date.i48,
.icon_line_date.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_date_24-48-96.svg');
}

.icon_line_date_24,
.icon_line_date.i24,
.icon_line_date.i48,
.icon_line_date.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_date_24-48-96.svg');
}

.icon_line_date_creation_24,
.icon_line_date_creation.i24,
.icon_line_date_creation.i48,
.icon_line_date_creation.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_date_creation_24-48-96.svg');
}

.icon_line_date_creation_24,
.icon_line_date_creation.i24,
.icon_line_date_creation.i48,
.icon_line_date_creation.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_date_creation_24-48-96.svg');
}

// .icon_line_date_creation_2_24,
// .icon_line_date_creation_2.i24,
// .icon_line_date_creation_2.i48,
// .icon_line_date_creation_2.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_line_date_creation_2_24-48-96.svg');
// }

// .icon_line_device_id_24,
// .icon_line_device_id.i24,
// .icon_line_device_id.i48,
// .icon_line_device_id.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_line_device_id_24-48-96.svg');
// }

.icon_line_device_id_24,
.icon_line_device_id.i24,
.icon_line_device_id.i48,
.icon_line_device_id.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_device_id_24-48-96.svg');
}

.icon_line_device_type_24,
.icon_line_device_type.i24,
.icon_line_device_type.i48,
.icon_line_device_type.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_device_type_24-48-96.svg');
}

.icon_line_device_type_24,
.icon_line_device_type.i24,
.icon_line_device_type.i48,
.icon_line_device_type.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_device_type_24-48-96.svg');
}

.icon_line_done_24,
.icon_line_done.i24,
.icon_line_done.i48,
.icon_line_done.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_done_24-48-96.svg');
}

.icon_line_draft_24,
.icon_line_draft.i24,
.icon_line_draft.i48,
.icon_line_draft.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_draft_24-48-96.svg');
}

.icon_line_draft_24,
.icon_line_draft.i24,
.icon_line_draft.i48,
.icon_line_draft.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_draft_24-48-96.svg');
}

.icon_line_draft_template_24,
.icon_line_draft_template.i24,
.icon_line_draft_template.i48,
.icon_line_draft_template.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_draft_template_24-48-96.svg');
}

.icon_line_edit_24,
.icon_line_edit.i24,
.icon_line_edit.i48,
.icon_line_edit.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_edit_24-48-96.svg');
}

.icon_line_equal_24,
.icon_line_equal.i24,
.icon_line_equal.i48,
.icon_line_equal.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_equal_24-48-96.svg');
}

.icon_line_error_condition_24,
.icon_line_error_condition.i24,
.icon_line_error_condition.i48,
.icon_line_error_condition.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_error_condition_24-48-96.svg');
}

.icon_line_error_target_24,
.icon_line_error_target.i24,
.icon_line_error_target.i48,
.icon_line_error_target.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_error_target_24-48-96.svg');
}

.icon_line_fieds_24,
.icon_line_fieds.i24,
.icon_line_fieds.i48,
.icon_line_fieds.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_fieds_24-48-96.svg');
}

.icon_line_fields_24,
.icon_line_fields.i24,
.icon_line_fields.i48,
.icon_line_fields.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_fields_24-48-96.svg');
}

.icon_line_fields_24,
.icon_line_fields.i24,
.icon_line_fields.i48,
.icon_line_fields.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_fields_24-48-96.svg');
}

.icon_line_fields_v2_24,
.icon_line_fields_v2.i24,
.icon_line_fields_v2.i48,
.icon_line_fields_v2.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_fields_v2_24-48-96.svg');
}

.icon_line_tick_white_24,
.icon_line_tick_white.i24,
.icon_line_tick_white.i48,
.icon_line_tick_white.i96 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_tick_white_16-32-64.svg');
}

.icon_line_file_24,
.icon_line_file.i24,
.icon_line_file.i48,
.icon_line_file.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_file_24-48-96.svg');
}

.icon_line_filter_24,
.icon_line_filter.i24,
.icon_line_filter.i48,
.icon_line_filter.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_filter_24-48-96.svg');
}

.icon_line_folder_closed_24,
.icon_line_folder_closed.i24,
.icon_line_folder_closed.i48,
.icon_line_folder_closed.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_folder_closed_24-48-96.svg');
}

.icon_line_folder_open_24,
.icon_line_folder_open.i24,
.icon_line_folder_open.i48,
.icon_line_folder_open.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_folder_open_24-48-96.svg');
}

.icon_line_geometry_24,
.icon_line_geometry.i24,
.icon_line_geometry.i48,
.icon_line_geometry.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_geometry_24-48-96.svg');
}

.icon_line_geometry_24,
.icon_line_geometry.i24,
.icon_line_geometry.i48,
.icon_line_geometry.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_geometry_24-48-96.svg');
}

.icon_line_greaterThanEqualTo_24,
.icon_line_greaterThanEqualTo.i24,
.icon_line_greaterThanEqualTo.i48,
.icon_line_greaterThanEqualTo.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_greaterThanEqualTo_24-48-96.svg');
}

.icon_line_greaterThan_24,
.icon_line_greaterThan.i24,
.icon_line_greaterThan.i48,
.icon_line_greaterThan.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_greaterThan_24-48-96.svg');
}

.icon_line_group_24,
.icon_line_group.i24,
.icon_line_group.i48,
.icon_line_group.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_group_24-48-96.svg');
}

.icon_line_group_24,
.icon_line_group.i24,
.icon_line_group.i48,
.icon_line_group.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_group_24-48-96.svg');
}

.icon_line_hamburger_24,
.icon_line_hamburger.i24,
.icon_line_hamburger.i48,
.icon_line_hamburger.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_hamburger_24-48-96.svg');
}

.icon_line_hdop1_24,
.icon_line_hdop1.i24,
.icon_line_hdop1.i48,
.icon_line_hdop1.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_hdop1_24-48-96.svg');
}

.icon_line_hdop1_24,
.icon_line_hdop1.i24,
.icon_line_hdop1.i48,
.icon_line_hdop1.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_hdop1_24-48-96.svg');
}

.icon_line_info_24,
.icon_line_info.i24,
.icon_line_info.i48,
.icon_line_info.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_info_24-48-96.svg');
}

.icon_line_layers_24,
.icon_line_layers.i24,
.icon_line_layers.i48,
.icon_line_layers.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_layers_24-48-96.svg');
}

.icon_line_layers_24,
.icon_line_layers.i24,
.icon_line_layers.i48,
.icon_line_layers.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_layers_24-48-96.svg');
}

.icon_line_length_24,
.icon_line_length.i24,
.icon_line_length.i48,
.icon_line_length.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_length_24-48-96.svg');
}

.icon_line_length_24,
.icon_line_length.i24,
.icon_line_length.i48,
.icon_line_length.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_length_24-48-96.svg');
}

.icon_line_lessThanEqualTo_24,
.icon_line_lessThanEqualTo.i24,
.icon_line_lessThanEqualTo.i48,
.icon_line_lessThanEqualTo.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_lessThanEqualTo_24-48-96.svg');
}

.icon_line_lessThan_24,
.icon_line_lessThan.i24,
.icon_line_lessThan.i48,
.icon_line_lessThan.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_lessThan_24-48-96.svg');
}

.icon_line_link_24,
.icon_line_link.i24,
.icon_line_link.i48,
.icon_line_link.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_link_24-48-96.svg');
}

.icon_line_markup_arrow_24,
.icon_line_markup_arrow.i24,
.icon_line_markup_arrow.i48,
.icon_line_markup_arrow.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_markup_arrow_24-48-96.svg');
}

.icon_line_markup_cloud_24,
.icon_line_markup_cloud.i24,
.icon_line_markup_cloud.i48,
.icon_line_markup_cloud.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_markup_cloud_24-48-96.svg');
}

.icon_line_markup_line_24,
.icon_line_markup_line.i24,
.icon_line_markup_line.i48,
.icon_line_markup_line.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_markup_line_24-48-96.svg');
}

.icon_line_markup_note_24,
.icon_line_markup_note.i24,
.icon_line_markup_note.i48,
.icon_line_markup_note.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_markup_note_24-48-96.svg');
}

.icon_line_marquee_24,
.icon_line_marquee.i24,
.icon_line_marquee.i48,
.icon_line_marquee.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_marquee_24-48-96.svg');
}

.icon_line_measure_24,
.icon_line_measure.i24,
.icon_line_measure.i48,
.icon_line_measure.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_measure_24-48-96.svg');
}

.icon_line_minus_24,
.icon_line_minus.i24,
.icon_line_minus.i48,
.icon_line_minus.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_minus_24-48-96.svg');
}

.icon_line_notEqual_24,
.icon_line_notEqual.i24,
.icon_line_notEqual.i48,
.icon_line_notEqual.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_notEqual_24-48-96.svg');
}

.icon_line_number_24,
.icon_line_number.i24,
.icon_line_number.i48,
.icon_line_number.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_number_24-48-96.svg');
}

.icon_line_orbit_24,
.icon_line_orbit.i24,
.icon_line_orbit.i48,
.icon_line_orbit.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_orbit_24-48-96.svg');
}

.icon_line_pagebreak_24,
.icon_line_pagebreak.i24,
.icon_line_pagebreak.i48,
.icon_line_pagebreak.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_pagebreak_24-48-96.svg');
}

.icon_line_pagebreak_24,
.icon_line_pagebreak.i24,
.icon_line_pagebreak.i48,
.icon_line_pagebreak.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_pagebreak_24-48-96.svg');
}

.icon_line_pdop1_24,
.icon_line_pdop1.i24,
.icon_line_pdop1.i48,
.icon_line_pdop1.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_pdop1_24-48-96.svg');
}

.icon_line_pdop1_24,
.icon_line_pdop1.i24,
.icon_line_pdop1.i48,
.icon_line_pdop1.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_pdop1_24-48-96.svg');
}

.icon_line_plus_24,
.icon_line_plus.i24,
.icon_line_plus.i48,
.icon_line_plus.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_plus_24-48-96.svg');
}

.icon_line_polygon_24,
.icon_line_polygon.i24,
.icon_line_polygon.i48,
.icon_line_polygon.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_polygon_24-48-96.svg');
}

.icon_line_positioning_24,
.icon_line_positioning.i24,
.icon_line_positioning.i48,
.icon_line_positioning.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_positioning_24-48-96.svg');
}

.icon_line_rows_24,
.icon_line_rows.i24,
.icon_line_rows.i48,
.icon_line_rows.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_rows_24-48-96.svg');
}

.icon_line_rules_24,
.icon_line_rules.i24,
.icon_line_rules.i48,
.icon_line_rules.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_rules_24-48-96.svg');
}

.icon_line_rules_24,
.icon_line_rules.i24,
.icon_line_rules.i48,
.icon_line_rules.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_rules_24-48-96.svg');
}

.icon_line_screen_maximise_24,
.icon_line_screen_maximise.i24,
.icon_line_screen_maximise.i48,
.icon_line_screen_maximise.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_screen_maximise_24-48-96.svg');
}

.icon_line_screen_minimise_24,
.icon_line_screen_minimise.i24,
.icon_line_screen_minimise.i48,
.icon_line_screen_minimise.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_screen_minimise_24-48-96.svg');
}

.icon_line_search_24,
.icon_line_search.i24,
.icon_line_search.i48,
.icon_line_search.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_search_24-48-96.svg');
}

.icon_line_search_white_24,
.icon_line_search_white.i24,
.icon_line_search_white.i48,
.icon_line_search_white.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_search_white_24-48-96.svg');
}

.icon_line_select-polygon_24,
.icon_line_select-polygon.i24,
.icon_line_select-polygon.i48,
.icon_line_select-polygon.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-polygon_24-48-96.svg');
}

.icon_line_select-polygon_add_24,
.icon_line_select-polygon_add.i24,
.icon_line_select-polygon_add.i48,
.icon_line_select-polygon_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-polygon_add_24-48-96.svg');
}

.icon_line_select-polygon_subtract_24,
.icon_line_select-polygon_subtract.i24,
.icon_line_select-polygon_subtract.i48,
.icon_line_select-polygon_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-polygon_subtract_24-48-96.svg');
}

.icon_line_select-rectangle_24,
.icon_line_select-rectangle.i24,
.icon_line_select-rectangle.i48,
.icon_line_select-rectangle.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-rectangle_24-48-96.svg');
}

.icon_line_select-rectangle_add_24,
.icon_line_select-rectangle_add.i24,
.icon_line_select-rectangle_add.i48,
.icon_line_select-rectangle_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-rectangle_add_24-48-96.svg');
}

.icon_line_select-rectangle_subtract_24,
.icon_line_select-rectangle_subtract.i24,
.icon_line_select-rectangle_subtract.i48,
.icon_line_select-rectangle_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-rectangle_subtract_24-48-96.svg');
}

.icon_line_select-single_24,
.icon_line_select-single.i24,
.icon_line_select-single.i48,
.icon_line_select-single.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select-single_24-48-96.svg');
}

.icon_line_select_24,
.icon_line_select.i24,
.icon_line_select.i48,
.icon_line_select.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_24-48-96.svg');
}

.icon_line_select_polygon_24,
.icon_line_select_polygon.i24,
.icon_line_select_polygon.i48,
.icon_line_select_polygon.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_polygon_24-48-96.svg');
}

.icon_line_select_polygon_24,
.icon_line_select_polygon.i24,
.icon_line_select_polygon.i48,
.icon_line_select_polygon.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_polygon_24-48-96.svg');
}

.icon_line_select_polygon_add_24,
.icon_line_select_polygon_add.i24,
.icon_line_select_polygon_add.i48,
.icon_line_select_polygon_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_polygon_add_24-48-96.svg');
}

.icon_line_select_polygon_add_24,
.icon_line_select_polygon_add.i24,
.icon_line_select_polygon_add.i48,
.icon_line_select_polygon_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_polygon_add_24-48-96.svg');
}

.icon_line_select_polygon_subtract_24,
.icon_line_select_polygon_subtract.i24,
.icon_line_select_polygon_subtract.i48,
.icon_line_select_polygon_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_polygon_subtract_24-48-96.svg');
}

.icon_line_select_polygon_subtract_24,
.icon_line_select_polygon_subtract.i24,
.icon_line_select_polygon_subtract.i48,
.icon_line_select_polygon_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_polygon_subtract_24-48-96.svg');
}

.icon_line_select_rectangle_24,
.icon_line_select_rectangle.i24,
.icon_line_select_rectangle.i48,
.icon_line_select_rectangle.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_rectangle_24-48-96.svg');
}

.icon_line_select_rectangle_24,
.icon_line_select_rectangle.i24,
.icon_line_select_rectangle.i48,
.icon_line_select_rectangle.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_rectangle_24-48-96.svg');
}

.icon_line_select_rectangle_add_24,
.icon_line_select_rectangle_add.i24,
.icon_line_select_rectangle_add.i48,
.icon_line_select_rectangle_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_rectangle_add_24-48-96.svg');
}

.icon_line_select_rectangle_add_24,
.icon_line_select_rectangle_add.i24,
.icon_line_select_rectangle_add.i48,
.icon_line_select_rectangle_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_rectangle_add_24-48-96.svg');
}

.icon_line_select_rectangle_subtract_24,
.icon_line_select_rectangle_subtract.i24,
.icon_line_select_rectangle_subtract.i48,
.icon_line_select_rectangle_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_rectangle_subtract_24-48-96.svg');
}

.icon_line_select_rectangle_subtract_24,
.icon_line_select_rectangle_subtract.i24,
.icon_line_select_rectangle_subtract.i48,
.icon_line_select_rectangle_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_rectangle_subtract_24-48-96.svg');
}

.icon_line_select_single_24,
.icon_line_select_single.i24,
.icon_line_select_single.i48,
.icon_line_select_single.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_select_single_24-48-96.svg');
}

.icon_line_source_direct_24,
.icon_line_source_direct.i24,
.icon_line_source_direct.i48,
.icon_line_source_direct.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_source_direct_24-48-96.svg');
}

.icon_line_source_ntrip_24,
.icon_line_source_ntrip.i24,
.icon_line_source_ntrip.i48,
.icon_line_source_ntrip.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_source_ntrip_24-48-96.svg');
}

.icon_line_source_rtx_24,
.icon_line_source_rtx.i24,
.icon_line_source_rtx.i48,
.icon_line_source_rtx.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_source_rtx_24-48-96.svg');
}

.icon_line_source_sbas_24,
.icon_line_source_sbas.i24,
.icon_line_source_sbas.i48,
.icon_line_source_sbas.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_source_sbas_24-48-96.svg');
}

.icon_line_swapView_24,
.icon_line_swapView.i24,
.icon_line_swapView.i48,
.icon_line_swapView.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_swapView_24-48-96.svg');
}

.icon_line_tablet_24,
.icon_line_tablet.i24,
.icon_line_tablet.i48,
.icon_line_tablet.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tablet_24-48-96.svg');
}

.icon_line_target_24,
.icon_line_target.i24,
.icon_line_target.i48,
.icon_line_target.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_target_24-48-96.svg');
}

.icon_line_task_add_24,
.icon_line_task_add.i24,
.icon_line_task_add.i48,
.icon_line_task_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_task_add_24-48-96.svg');
}

.icon_line_task_add_24,
.icon_line_task_add.i24,
.icon_line_task_add.i48,
.icon_line_task_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_task_add_24-48-96.svg');
}

.icon_line_task_subtract_24,
.icon_line_task_subtract.i24,
.icon_line_task_subtract.i48,
.icon_line_task_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_task_subtract_24-48-96.svg');
}

.icon_line_task_subtract_24,
.icon_line_task_subtract.i24,
.icon_line_task_subtract.i48,
.icon_line_task_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_task_subtract_24-48-96.svg');
}

.icon_line_template_24,
.icon_line_template.i24,
.icon_line_template.i48,
.icon_line_template.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_template_24-48-96.svg');
}

.active {
    .icon_line_template.i24 {
        background-image: url('../assets/images/SVGs-24scale/icon_line_template_white_24-48-96.svg');
    }
}

.icon_line_template_24,
.icon_line_template.i24,
.icon_line_template.i48,
.icon_line_template.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_template_24-48-96.svg');
}

.active {
    .icon_line_template.i24 {
        background-image: url('../assets/images/SVGs-24scale/icon_line_template_white_24-48-96.svg');
    }
}

.icon_line_template_draft_24,
.icon_line_template_draft.i24,
.icon_line_template_draft.i48,
.icon_line_template_draft.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_template_draft_24-48-96.svg');
}

.icon_line_text_24,
.icon_line_text.i24,
.icon_line_text.i48,
.icon_line_text.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_text_24-48-96.svg');
}

.icon_line_thumbnail_24,
.icon_line_thumbnail.i24,
.icon_line_thumbnail.i48,
.icon_line_thumbnail.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_thumbnail_24-48-96.svg');
}

.icon_line_tick_24,
.icon_line_tick.i24,
.icon_line_tick.i48,
.icon_line_tick.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tick_24-48-96.svg');
}

.active {
    .icon_line_tick.i24 {
        background-image: url('../assets/images/SVGs-24scale/icon_line_tick_white_24-48-96.svg');
    }
}

.icon_line_tick_24,
.icon_line_tick.i24,
.icon_line_tick.i48,
.icon_line_tick.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tick_24-48-96.svg');
}

.active {
    .icon_line_tick.i24 {
        background-image: url('../assets/images/SVGs-24scale/icon_line_tick_white_24-48-96.svg');
    }
}

.icon_line_tick_gray_24,
.icon_line_tick_gray.i24,
.icon_line_tick_gray.i48,
.icon_line_tick_gray.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tick_gray_24-48-96.svg');
}

.icon_line_tick_green_24,
.icon_line_tick_green.i24,
.icon_line_tick_green.i48,
.icon_line_tick_green.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tick_green_24-48-96.svg');
}

.icon_line_tick_square_24,
.icon_line_tick_square.i24,
.icon_line_tick_square.i48,
.icon_line_tick_square.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tick_square_24-48-96.svg');
}

.icon_line_tick_square_24,
.icon_line_tick_square.i24,
.icon_line_tick_square.i48,
.icon_line_tick_square.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_tick_square_24-48-96.svg');
}

.icon_line_todo_24,
.icon_line_todo.i24,
.icon_line_todo.i48,
.icon_line_todo.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_todo_24-48-96.svg');
}

.icon_line_turn_head_24,
.icon_line_turn_head.i24,
.icon_line_turn_head.i48,
.icon_line_turn_head.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_turn_head_24-48-96.svg');
}

.icon_line_view_fit_24,
.icon_line_view_fit.i24,
.icon_line_view_fit.i48,
.icon_line_view_fit.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_view_fit_24-48-96.svg');
}

.icon_line_visibility_hide_24,
.icon_line_visibility_hide.i24,
.icon_line_visibility_hide.i48,
.icon_line_visibility_hide.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_visibility_hide_24-48-96.svg');
}

.icon_line_visibility_show_24,
.icon_line_visibility_show.i24,
.icon_line_visibility_show.i48,
.icon_line_visibility_show.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_visibility_show_24-48-96.svg');
}

.icon_line_tick_white_32,
.icon_line_tick_white.i16,
.icon_line_tick_white.i32,
.icon_line_tick_white.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_tick_white_16-32-64.svg');
}

.icon_line_visibility_toggle_24,
.icon_line_visibility_toggle.i24,
.icon_line_visibility_toggle.i48,
.icon_line_visibility_toggle.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_visibility_toggle_24-48-96.svg');
}

.icon_line_walk_24,
.icon_line_walk.i24,
.icon_line_walk.i48,
.icon_line_walk.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_walk_24-48-96.svg');
}

.icon_line_white_condition_24,
.icon_line_white_condition.i24,
.icon_line_white_condition.i48,
.icon_line_white_condition.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_condition_24-48-96.svg');
}

.icon_line_white_draft_24,
.icon_line_white_draft.i24,
.icon_line_white_draft.i48,
.icon_line_white_draft.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_draft_24-48-96.svg');
}

.icon_line_white_draft_template_24,
.icon_line_white_draft_template.i24,
.icon_line_white_draft_template.i48,
.icon_line_white_draft_template.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_draft_template_24-48-96.svg');
}

.icon_line_white_target_24,
.icon_line_white_target.i24,
.icon_line_white_target.i48,
.icon_line_white_target.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_target_24-48-96.svg');
}

.icon_line_white_template_24,
.icon_line_white_template.i24,
.icon_line_white_template.i48,
.icon_line_white_template.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_template_24-48-96.svg');
}

.icon_line_white_template_draft_24,
.icon_line_white_template_draft.i24,
.icon_line_white_template_draft.i48,
.icon_line_white_template_draft.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_template_draft_24-48-96.svg');
}

.icon_line_white_tick_24,
.icon_line_white_tick.i24,
.icon_line_white_tick.i48,
.icon_line_white_tick.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_line_white_tick_24-48-96.svg');
}

.icon_solid_alert_24,
.icon_solid_alert.i24,
.icon_solid_alert.i48,
.icon_solid_alert.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_alert_24-48-96.svg');
}

.icon_solid_alert_critical_24,
.icon_solid_alert_critical.i24,
.icon_solid_alert_critical.i48,
.icon_solid_alert_critical.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_alert_critical_24-48-96.svg');
}

.icon_solid_alert_info_24,
.icon_solid_alert_info.i24,
.icon_solid_alert_info.i48,
.icon_solid_alert_info.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_alert_info_24-48-96.svg');
}

.icon_solid_alert_positive_24,
.icon_solid_alert_positive.i24,
.icon_solid_alert_positive.i48,
.icon_solid_alert_positive.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_alert_positive_24-48-96.svg');
}

.icon_solid_alert_warning_24,
.icon_solid_alert_warning.i24,
.icon_solid_alert_warning.i48,
.icon_solid_alert_warning.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_alert_warning_24-48-96.svg');
}

.icon_solid_assistance_24,
.icon_solid_assistance.i24,
.icon_solid_assistance.i48,
.icon_solid_assistance.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_assistance_24-48-96.svg');
}

// .icon_solid_autofields_24,
// .icon_solid_autofields.i24,
// .icon_solid_autofields.i48,
// .icon_solid_autofields.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_solid_autofields_24-48-96.svg');
// }

.icon_solid_camera_24,
.icon_solid_camera.i24,
.icon_solid_camera.i48,
.icon_solid_camera.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_camera_24-48-96.svg');
}

.icon_solid_cart_24,
.icon_solid_cart.i24,
.icon_solid_cart.i48,
.icon_solid_cart.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_cart_24-48-96.svg');
}

.icon_solid_clash_bang_critical_24,
.icon_solid_clash_bang_critical.i24,
.icon_solid_clash_bang_critical.i48,
.icon_solid_clash_bang_critical.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_bang_critical_24-48-96.svg');
}

.icon_solid_clash_bang_gray_24,
.icon_solid_clash_bang_gray.i24,
.icon_solid_clash_bang_gray.i48,
.icon_solid_clash_bang_gray.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_bang_gray_24-48-96.svg');
}

// .icon_solid_clash_bang_positive_24_24,
// .icon_solid_clash_bang_positive_24.i24,
// .icon_solid_clash_bang_positive_24.i48,
// .icon_solid_clash_bang_positive_24.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_bang_positive_24_24-48-96.svg');
// }

// .icon_solid_clash_bang_positive_24_24,
// .icon_solid_clash_bang_positive_24.i24,
// .icon_solid_clash_bang_positive_24.i48,
// .icon_solid_clash_bang_positive_24.i96 {
//   background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_bang_positive_24_24-48-96.svg');
// }

.icon_solid_clash_bang_warning_24,
.icon_solid_clash_bang_warning.i24,
.icon_solid_clash_bang_warning.i48,
.icon_solid_clash_bang_warning.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_bang_warning_24-48-96.svg');
}

.icon_solid_clash_set_24,
.icon_solid_clash_set.i24,
.icon_solid_clash_set.i48,
.icon_solid_clash_set.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_set_24-48-96.svg');
}

.icon_solid_clash_set_add_24,
.icon_solid_clash_set_add.i24,
.icon_solid_clash_set_add.i48,
.icon_solid_clash_set_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_set_add_24-48-96.svg');
}

.icon_solid_clash_set_download_24,
.icon_solid_clash_set_download.i24,
.icon_solid_clash_set_download.i48,
.icon_solid_clash_set_download.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_set_download_24-48-96.svg');
}

.icon_solid_clash_set_remove_24,
.icon_solid_clash_set_remove.i24,
.icon_solid_clash_set_remove.i48,
.icon_solid_clash_set_remove.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_clash_set_remove_24-48-96.svg');
}

.icon_solid_collectedby_24,
.icon_solid_collectedby.i24,
.icon_solid_collectedby.i48,
.icon_solid_collectedby.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_collectedby_24-48-96.svg');
}

.icon_solid_collectedby_24,
.icon_solid_collectedby.i24,
.icon_solid_collectedby.i48,
.icon_solid_collectedby.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_collectedby_24-48-96.svg');
}

.icon_solid_comment_24,
.icon_solid_comment.i24,
.icon_solid_comment.i48,
.icon_solid_comment.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_comment_24-48-96.svg');
}

.icon_solid_comment_add_24,
.icon_solid_comment_add.i24,
.icon_solid_comment_add.i48,
.icon_solid_comment_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_comment_add_24-48-96.svg');
}

.icon_solid_comment_conversation_24,
.icon_solid_comment_conversation.i24,
.icon_solid_comment_conversation.i48,
.icon_solid_comment_conversation.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_comment_conversation_24-48-96.svg');
}

.icon_solid_comment_edit_24,
.icon_solid_comment_edit.i24,
.icon_solid_comment_edit.i48,
.icon_solid_comment_edit.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_comment_edit_24-48-96.svg');
}

.icon_solid_comment_remove_24,
.icon_solid_comment_remove.i24,
.icon_solid_comment_remove.i48,
.icon_solid_comment_remove.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_comment_remove_24-48-96.svg');
}

.icon_solid_fields_24,
.icon_solid_fields.i24,
.icon_solid_fields.i48,
.icon_solid_fields.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_fields_24-48-96.svg');
}

.icon_solid_filter_24,
.icon_solid_filter.i24,
.icon_solid_filter.i48,
.icon_solid_filter.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_filter_24-48-96.svg');
}

.icon_solid_filter_24,
.icon_solid_filter.i24,
.icon_solid_filter.i48,
.icon_solid_filter.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_filter_24-48-96.svg');
}

.icon_solid_folder_closed_24,
.icon_solid_folder_closed.i24,
.icon_solid_folder_closed.i48,
.icon_solid_folder_closed.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_folder_closed_24-48-96.svg');
}

.icon_solid_folder_open_24,
.icon_solid_folder_open.i24,
.icon_solid_folder_open.i48,
.icon_solid_folder_open.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_folder_open_24-48-96.svg');
}

.icon_solid_info_24,
.icon_solid_info.i24,
.icon_solid_info.i48,
.icon_solid_info.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_info_24-48-96.svg');
}

.icon_solid_layers_24,
.icon_solid_layers.i24,
.icon_solid_layers.i48,
.icon_solid_layers.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_layers_24-48-96.svg');
}

.icon_solid_locationservices_24,
.icon_solid_locationservices.i24,
.icon_solid_locationservices.i48,
.icon_solid_locationservices.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_locationservices_24-48-96.svg');
}

.icon_solid_lock_24,
.icon_solid_lock.i24,
.icon_solid_lock.i48,
.icon_solid_lock.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_lock_24-48-96.svg');
}

.icon_solid_lock_24,
.icon_solid_lock.i24,
.icon_solid_lock.i48,
.icon_solid_lock.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_lock_24-48-96.svg');
}

.icon_solid_map_24,
.icon_solid_map.i24,
.icon_solid_map.i48,
.icon_solid_map.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_map_24-48-96.svg');
}

.icon_solid_measure_24,
.icon_solid_measure.i24,
.icon_solid_measure.i48,
.icon_solid_measure.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_measure_24-48-96.svg');
}

.icon_solid_menu_24,
.icon_solid_menu.i24,
.icon_solid_menu.i48,
.icon_solid_menu.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_menu_24-48-96.svg');
}

.icon_solid_minus_24,
.icon_solid_minus.i24,
.icon_solid_minus.i48,
.icon_solid_minus.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_minus_24-48-96.svg');
}

.icon_solid_number_24,
.icon_solid_number.i24,
.icon_solid_number.i48,
.icon_solid_number.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_number_24-48-96.svg');
}

.icon_solid_number_24,
.icon_solid_number.i24,
.icon_solid_number.i48,
.icon_solid_number.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_number_24-48-96.svg');
}

.icon_solid_plus_24,
.icon_solid_plus.i24,
.icon_solid_plus.i48,
.icon_solid_plus.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_plus_24-48-96.svg');
}

.icon_solid_preferences_24,
.icon_solid_preferences.i24,
.icon_solid_preferences.i48,
.icon_solid_preferences.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_preferences_24-48-96.svg');
}

.icon_solid_select_single_24,
.icon_solid_select_single.i24,
.icon_solid_select_single.i48,
.icon_solid_select_single.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_select_single_24-48-96.svg');
}

.icon_solid_select_single_add_24,
.icon_solid_select_single_add.i24,
.icon_solid_select_single_add.i48,
.icon_solid_select_single_add.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_select_single_add_24-48-96.svg');
}

.icon_solid_select_single_subtract_24,
.icon_solid_select_single_subtract.i24,
.icon_solid_select_single_subtract.i48,
.icon_solid_select_single_subtract.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_select_single_subtract_24-48-96.svg');
}

.icon_solid_settings_24,
.icon_solid_settings.i24,
.icon_solid_settings.i48,
.icon_solid_settings.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_settings_24-48-96.svg');
}

.icon_solid_swapView_24,
.icon_solid_swapView.i24,
.icon_solid_swapView.i48,
.icon_solid_swapView.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_swapView_24-48-96.svg');
}

.icon_solid_text_24,
.icon_solid_text.i24,
.icon_solid_text.i48,
.icon_solid_text.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_text_24-48-96.svg');
}

.icon_solid_text_24,
.icon_solid_text.i24,
.icon_solid_text.i48,
.icon_solid_text.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_text_24-48-96.svg');
}

.icon_solid_todo_24,
.icon_solid_todo.i24,
.icon_solid_todo.i48,
.icon_solid_todo.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_todo_24-48-96.svg');
}

.icon_solid_trash_24,
.icon_solid_trash.i24,
.icon_solid_trash.i48,
.icon_solid_trash.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_trash_24-48-96.svg');
}

.icon_solid_user_24,
.icon_solid_user.i24,
.icon_solid_user.i48,
.icon_solid_user.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_user_24-48-96.svg');
}

.icon_solid_user_group_24,
.icon_solid_user_group.i24,
.icon_solid_user_group.i48,
.icon_solid_user_group.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_user_group_24-48-96.svg');
}

.icon_solid_white_alert_24,
.icon_solid_white_alert.i24,
.icon_solid_white_alert.i48,
.icon_solid_white_alert.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_white_alert_24-48-96.svg');
}

.icon_solid_white_trash_24,
.icon_solid_white_trash.i24,
.icon_solid_white_trash.i48,
.icon_solid_white_trash.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_white_trash_24-48-96.svg');
}

// .vect_solid_rotat_24,
// .vect_solid_rotat.i24,
// .vect_solid_rotat.i48,
// .vect_solid_rotat.i96 {
//   background-image: url('../assets/images/SVGs-24scale/vect_solid_rotat_24-48-96.svg');
// }

// .vect_solid_rotate_24,
// .vect_solid_rotate.i24,
// .vect_solid_rotate.i48,
// .vect_solid_rotate.i96 {
//   background-image: url('../assets/images/SVGs-24scale/vect_solid_rotate_24-48-96.svg');
// }

.icon_line_3D_component_32,
.icon_line_3D_component.i16,
.icon_line_3D_component.i32,
.icon_line_3D_component.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_3D_component_16-32-64.svg');
}

.icon_line_3D_isometric_32,
.icon_line_3D_isometric.i16,
.icon_line_3D_isometric.i32,
.icon_line_3D_isometric.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_3D_isometric_16-32-64.svg');
}

.icon_line_3D_left_32,
.icon_line_3D_left.i16,
.icon_line_3D_left.i32,
.icon_line_3D_left.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_3D_left_16-32-64.svg');
}

.icon_line_3D_perspective_32,
.icon_line_3D_perspective.i16,
.icon_line_3D_perspective.i32,
.icon_line_3D_perspective.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_3D_perspective_16-32-64.svg');
}

.icon_line_3D_right_32,
.icon_line_3D_right.i16,
.icon_line_3D_right.i32,
.icon_line_3D_right.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_3D_right_16-32-64.svg');
}

.icon_line_3D_top_32,
.icon_line_3D_top.i16,
.icon_line_3D_top.i32,
.icon_line_3D_top.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_3D_top_16-32-64.svg');
}

.icon_line_accuracy_32,
.icon_line_accuracy.i16,
.icon_line_accuracy.i32,
.icon_line_accuracy.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_accuracy_16-32-64.svg');
}

.icon_line_angle_32,
.icon_line_angle.i16,
.icon_line_angle.i32,
.icon_line_angle.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_angle_16-32-64.svg');
}

.icon_line_arrow_down_32,
.icon_line_arrow_down.i16,
.icon_line_arrow_down.i32,
.icon_line_arrow_down.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_arrow_down_16-32-64.svg');
}

.icon_line_arrow_up_32,
.icon_line_arrow_up.i16,
.icon_line_arrow_up.i32,
.icon_line_arrow_up.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_arrow_up_16-32-64.svg');
}

.icon_line_assistance_32,
.icon_line_assistance.i16,
.icon_line_assistance.i32,
.icon_line_assistance.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_assistance_16-32-64.svg');
}

.icon_line_auto_increment_32,
.icon_line_auto_increment.i16,
.icon_line_auto_increment.i32,
.icon_line_auto_increment.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_auto_increment_16-32-64.svg');
}

.icon_line_barcode_32,
.icon_line_barcode.i16,
.icon_line_barcode.i32,
.icon_line_barcode.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_barcode_16-32-64.svg');
}

.icon_line_bluetooth_32,
.icon_line_bluetooth.i16,
.icon_line_bluetooth.i32,
.icon_line_bluetooth.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_bluetooth_16-32-64.svg');
}

.icon_line_bluetooth_active_32,
.icon_line_bluetooth_active.i16,
.icon_line_bluetooth_active.i32,
.icon_line_bluetooth_active.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_bluetooth_active_16-32-64.svg');
}

.icon_line_camera_32,
.icon_line_camera.i16,
.icon_line_camera.i32,
.icon_line_camera.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_camera_16-32-64.svg');
}

.icon_line_cart_32,
.icon_line_cart.i16,
.icon_line_cart.i32,
.icon_line_cart.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cart_16-32-64.svg');
}

.icon_line_chevron_down_32,
.icon_line_chevron_down.i16,
.icon_line_chevron_down.i32,
.icon_line_chevron_down.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_chevron_down_16-32-64.svg');
}

.icon_line_chevron_left_32,
.icon_line_chevron_left.i16,
.icon_line_chevron_left.i32,
.icon_line_chevron_left.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_chevron_left_16-32-64.svg');
}

.icon_line_chevron_right_32,
.icon_line_chevron_right.i16,
.icon_line_chevron_right.i32,
.icon_line_chevron_right.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_chevron_right_16-32-64.svg');
}

.icon_line_chevron_up_32,
.icon_line_chevron_up.i16,
.icon_line_chevron_up.i32,
.icon_line_chevron_up.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_chevron_up_16-32-64.svg');
}

.icon_line_choice_32,
.icon_line_choice.i16,
.icon_line_choice.i32,
.icon_line_choice.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_choice_16-32-64.svg');
}

.icon_line_codedchoice_32,
.icon_line_codedchoice.i16,
.icon_line_codedchoice.i32,
.icon_line_codedchoice.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_coded_choice_16-32-64.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 105%;
}

.icon_line_clear_filter_32,
.icon_line_clear_filter.i16,
.icon_line_clear_filter.i32,
.icon_line_clear_filter.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_clear_filter_16-32-64.svg');
}

.icon_line_clip_32,
.icon_line_clip.i16,
.icon_line_clip.i32,
.icon_line_clip.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_clip_16-32-64.svg');
}

.icon_line_clip_cross_32,
.icon_line_clip_cross.i16,
.icon_line_clip_cross.i32,
.icon_line_clip_cross.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_clip_cross_16-32-64.svg');
}

.icon_line_clock_32,
.icon_line_clock.i16,
.icon_line_clock.i32,
.icon_line_clock.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_clock_16-32-64.svg');
}

.icon_line_cloud_32,
.icon_line_cloud.i16,
.icon_line_cloud.i32,
.icon_line_cloud.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cloud_16-32-64.svg');
}

.icon_line_cloud_down_32,
.icon_line_cloud_down.i16,
.icon_line_cloud_down.i32,
.icon_line_cloud_down.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cloud_down_16-32-64.svg');
}

.icon_line_cloud_search_32,
.icon_line_cloud_search.i16,
.icon_line_cloud_search.i32,
.icon_line_cloud_search.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cloud_search_16-32-64.svg');
}

.icon_line_cloud_sync_32,
.icon_line_cloud_sync.i16,
.icon_line_cloud_sync.i32,
.icon_line_cloud_sync.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cloud_sync_16-32-64.svg');
}

.icon_line_cloud_updown_32,
.icon_line_cloud_updown.i16,
.icon_line_cloud_updown.i32,
.icon_line_cloud_updown.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cloud_updown_16-32-64.svg');
}

.icon_line_cloud_up_32,
.icon_line_cloud_up.i16,
.icon_line_cloud_up.i32,
.icon_line_cloud_up.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cloud_up_16-32-64.svg');
}

.icon_line_column_32,
.icon_line_column.i16,
.icon_line_column.i32,
.icon_line_column.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_column_16-32-64.svg');
}

.icon_line_comment_32,
.icon_line_comment.i16,
.icon_line_comment.i32,
.icon_line_comment.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_comment_16-32-64.svg');
}

.icon_line_comment_add_32,
.icon_line_comment_add.i16,
.icon_line_comment_add.i32,
.icon_line_comment_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_comment_add_16-32-64.svg');
}

.icon_line_comment_conversation_32,
.icon_line_comment_conversation.i16,
.icon_line_comment_conversation.i32,
.icon_line_comment_conversation.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_comment_conversation_16-32-64.svg');
}

.icon_line_comment_edit_32,
.icon_line_comment_edit.i16,
.icon_line_comment_edit.i32,
.icon_line_comment_edit.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_comment_edit_16-32-64.svg');
}

.icon_line_comment_remove_32,
.icon_line_comment_remove.i16,
.icon_line_comment_remove.i32,
.icon_line_comment_remove.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_comment_remove_16-32-64.svg');
}

.icon_line_correction_source_32,
.icon_line_correction_source.i16,
.icon_line_correction_source.i32,
.icon_line_correction_source.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_correction_source_16-32-64.svg');
}

.icon_line_correction_status_32,
.icon_line_correction_status.i16,
.icon_line_correction_status.i32,
.icon_line_correction_status.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_correction_status_16-32-64.svg');
}

.icon_line_cross_section_32,
.icon_line_cross_section.i16,
.icon_line_cross_section.i32,
.icon_line_cross_section.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_cross_section_16-32-64.svg');
}

// .icon_line_cross_sect_32,
// .icon_line_cross_sect.i16,
// .icon_line_cross_sect.i32,
// .icon_line_cross_sect.i64 {
//   background-image: url('../assets/images/SVGs-32scale/icon_line_cross_sect_16-32-64.svg');
// }

.icon_line_date_32,
.icon_line_date.i16,
.icon_line_date.i32,
.icon_line_date.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_date_16-32-64.svg');
}

.icon_line_date_creation_32,
.icon_line_date_creation.i16,
.icon_line_date_creation.i32,
.icon_line_date_creation.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_date_creation_16-32-64.svg');
}

.icon_line_date_update_32,
.icon_line_date_update.i16,
.icon_line_date_update.i32,
.icon_line_date_update.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_date_update_16-32-64.svg');
}

.icon_line_device_id_32,
.icon_line_device_id.i16,
.icon_line_device_id.i32,
.icon_line_device_id.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_device_id_16-32-64.svg');
}

.icon_line_device_type_32,
.icon_line_device_type.i16,
.icon_line_device_type.i32,
.icon_line_device_type.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_device_type_16-32-64.svg');
}

.icon_line_done_32,
.icon_line_done.i16,
.icon_line_done.i32,
.icon_line_done.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_done_16-32-64.svg');
}

.icon_line_download_32,
.icon_line_download.i16,
.icon_line_download.i32,
.icon_line_download.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_download_16-32-64.svg');
}

.icon_line_draft_32,
.icon_line_draft.i16,
.icon_line_draft.i32,
.icon_line_draft.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_draft_16-32-64.svg');
}

.icon_line_draft_template_32,
.icon_line_draft_template.i16,
.icon_line_draft_template.i32,
.icon_line_draft_template.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_draft_template_16-32-64.svg');
}

.icon_line_edit_32,
.icon_line_edit.i16,
.icon_line_edit.i32,
.icon_line_edit.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_edit_16-32-64.svg');
}

.icon_line_equal_32,
.icon_line_equal.i16,
.icon_line_equal.i32,
.icon_line_equal.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_equal_16-32-64.svg');
}

.icon_line_expand_collapse_32,
.icon_line_expand_collapse.i16,
.icon_line_expand_collapse.i32,
.icon_line_expand_collapse.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_expand_collapse_16-32-64.svg');
}

// .icon_line_expand_colla_32,
// .icon_line_expand_colla.i16,
// .icon_line_expand_colla.i32,
// .icon_line_expand_colla.i64 {
//   background-image: url('../assets/images/SVGs-32scale/icon_line_expand_colla_16-32-64.svg');
// }

.icon_line_fields_32,
.icon_line_fields.i16,
.icon_line_fields.i32,
.icon_line_fields.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_fields_16-32-64.svg');
}

.active {
    .icon_line_fields.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_fields_white_16-32-64.svg');
    }
}

.icon_line_files_32,
.icon_line_files.i16,
.icon_line_files.i32,
.icon_line_files.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_files_16-32-64.svg');
}

.icon_line_file_32,
.icon_line_file.i16,
.icon_line_file.i32,
.icon_line_file.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_file_16-32-64.svg');
}

.icon_line_file_add_32,
.icon_line_file_add.i16,
.icon_line_file_add.i32,
.icon_line_file_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_file_add_16-32-64.svg');
}

.icon_line_file_export_32,
.icon_line_file_export.i16,
.icon_line_file_export.i32,
.icon_line_file_export.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_file_export_16-32-64.svg');
}

.icon_line_file_remove_32,
.icon_line_file_remove.i16,
.icon_line_file_remove.i32,
.icon_line_file_remove.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_file_remove_16-32-64.svg');
}

.icon_line_file_upload_32,
.icon_line_file_upload.i16,
.icon_line_file_upload.i32,
.icon_line_file_upload.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_file_upload_16-32-64.svg');
}

.icon_line_file_share_32,
.icon_line_file_share.i16,
.icon_line_file_share.i32,
.icon_line_file_share.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_file_share_16-32-64.svg');
}

.icon_line_filter_32,
.icon_line_filter.i16,
.icon_line_filter.i32,
.icon_line_filter.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_filter_16-32-64.svg');
}

.icon_line_folder_closed_32,
.icon_line_folder_closed.i16,
.icon_line_folder_closed.i32,
.icon_line_folder_closed.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_folder_closed_16-32-64.svg');
}

.icon_line_folder_open_32,
.icon_line_folder_open.i16,
.icon_line_folder_open.i32,
.icon_line_folder_open.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_folder_open_16-32-64.svg');
}

.icon_line_geometry_32,
.icon_line_geometry.i16,
.icon_line_geometry.i32,
.icon_line_geometry.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_geometry_16-32-64.svg');
}

.icon_line_greaterThanEqualTo_32,
.icon_line_greaterThanEqualTo.i16,
.icon_line_greaterThanEqualTo.i32,
.icon_line_greaterThanEqualTo.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_greaterThanEqualTo_16-32-64.svg');
}

.icon_line_greaterThan_32,
.icon_line_greaterThan.i16,
.icon_line_greaterThan.i32,
.icon_line_greaterThan.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_greaterThan_16-32-64.svg');
}

.icon_line_group_32,
.icon_line_group.i16,
.icon_line_group.i32,
.icon_line_group.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_group_16-32-64.svg');
}

.icon_line_hdop1_32,
.icon_line_hdop1.i16,
.icon_line_hdop1.i32,
.icon_line_hdop1.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_hdop1_16-32-64.svg');
}

.icon_line_info_32,
.icon_line_info.i16,
.icon_line_info.i32,
.icon_line_info.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_info_16-32-64.svg');
}

.icon_line_layers_32,
.icon_line_layers.i16,
.icon_line_layers.i32,
.icon_line_layers.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_layers_16-32-64.svg');
}

.active {
    .icon_line_layers.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_layers_white_16-32-64.svg');
    }
}

.icon_line_length_32,
.icon_line_length.i16,
.icon_line_length.i32,
.icon_line_length.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_length_16-32-64.svg');
}

.icon_line_lessThanEqualTo_32,
.icon_line_lessThanEqualTo.i16,
.icon_line_lessThanEqualTo.i32,
.icon_line_lessThanEqualTo.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_lessThanEqualTo_16-32-64.svg');
}

.icon_line_lessThan_32,
.icon_line_lessThan.i16,
.icon_line_lessThan.i32,
.icon_line_lessThan.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_lessThan_16-32-64.svg');
}

.icon_line_links_32,
.icon_line_links.i16,
.icon_line_links.i32,
.icon_line_links.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_links_16-32-64.svg');
}

.icon_line_link_32,
.icon_line_link.i16,
.icon_line_link.i32,
.icon_line_link.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_link_16-32-64.svg');
}

.active {
    .icon_line_link.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_link_white_16-32-64.svg');
    }
}

.icon_line_markup_arrow_32,
.icon_line_markup_arrow.i16,
.icon_line_markup_arrow.i32,
.icon_line_markup_arrow.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_markup_arrow_16-32-64.svg');
}

.icon_line_markup_cloud_32,
.icon_line_markup_cloud.i16,
.icon_line_markup_cloud.i32,
.icon_line_markup_cloud.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_markup_cloud_16-32-64.svg');
}

.icon_line_markup_line_32,
.icon_line_markup_line.i16,
.icon_line_markup_line.i32,
.icon_line_markup_line.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_markup_line_16-32-64.svg');
}

.icon_line_markup_note_32,
.icon_line_markup_note.i16,
.icon_line_markup_note.i32,
.icon_line_markup_note.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_markup_note_16-32-64.svg');
}

.icon_line_marquee_32,
.icon_line_marquee.i16,
.icon_line_marquee.i32,
.icon_line_marquee.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_marquee_16-32-64.svg');
}

.icon_line_measure_32,
.icon_line_measure.i16,
.icon_line_measure.i32,
.icon_line_measure.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_measure_16-32-64.svg');
}

.icon_line_menu_32,
.icon_line_menu.i16,
.icon_line_menu.i32,
.icon_line_menu.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_menu_16-32-64.svg');
}

.icon_line_menu_dots_32,
.icon_line_menu_dots.i16,
.icon_line_menu_dots.i32,
.icon_line_menu_dots.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_menu_dots_16-32-64.svg');
}

.icon_line_minus_32,
.icon_line_minus.i16,
.icon_line_minus.i32,
.icon_line_minus.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_minus_16-32-64.svg');
}

.icon_line_move_top_32,
.icon_line_move_top.i16,
.icon_line_move_top.i32,
.icon_line_move_top.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_move_top_16-32-64.svg');
    background-size: 90%;
}

.icon_line_move_bottom_32,
.icon_line_move_bottom.i16,
.icon_line_move_bottom.i32,
.icon_line_move_bottom.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_move_bottom_16-32-64.svg');
    background-size: 90%;
}

.icon_line_notEqual_32,
.icon_line_notEqual.i16,
.icon_line_notEqual.i32,
.icon_line_notEqual.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_notEqual_16-32-64.svg');
}

.icon_line_number_32,
.icon_line_number.i16,
.icon_line_number.i32,
.icon_line_number.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_number_16-32-64.svg');
}

.icon_line_orbit_32,
.icon_line_orbit.i16,
.icon_line_orbit.i32,
.icon_line_orbit.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_orbit_16-32-64.svg');
}

.icon_line_pagebreak_32,
.icon_line_pagebreak.i16,
.icon_line_pagebreak.i32,
.icon_line_pagebreak.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_pagebreak_16-32-64.svg');
}

.icon_line_pagefit_height_32,
.icon_line_pagefit_height.i16,
.icon_line_pagefit_height.i32,
.icon_line_pagefit_height.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_pagefit_height_16-32-64.svg');
}

.icon_line_pagefit_width_32,
.icon_line_pagefit_width.i16,
.icon_line_pagefit_width.i32,
.icon_line_pagefit_width.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_pagefit_width_16-32-64.svg');
}

.icon_line_page_not_available_32,
.icon_line_page_not_available.i16,
.icon_line_page_not_available.i32,
.icon_line_page_not_available.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_page_not_available_16-32-64.svg');
}

.icon_line_pdop1_32,
.icon_line_pdop1.i16,
.icon_line_pdop1.i32,
.icon_line_pdop1.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_pdop1_16-32-64.svg');
}

.icon_line_plus_32,
.icon_line_plus.i16,
.icon_line_plus.i32,
.icon_line_plus.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_plus_16-32-64.svg');
}

.active {
    .icon_line_plus.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_plus_white_16-32-64.svg');
    }
}

.icon_line_polygon_32,
.icon_line_polygon.i16,
.icon_line_polygon.i32,
.icon_line_polygon.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_polygon_16-32-64.svg');
}

.icon_line_positioning_32,
.icon_line_positioning.i16,
.icon_line_positioning.i32,
.icon_line_positioning.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_positioning_16-32-64.svg');
}

.icon_line_refresh_32,
.icon_line_refresh.i16,
.icon_line_refresh.i32,
.icon_line_refresh.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_refresh_16-32-64.svg');
}

.icon_line_rows_32,
.icon_line_rows.i16,
.icon_line_rows.i32,
.icon_line_rows.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_rows_16-32-64.svg');
}

.icon_line_rules_32,
.icon_line_rules.i16,
.icon_line_rules.i32,
.icon_line_rules.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_rules_16-32-64.svg');
}

.active {
    .icon_line_rules.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_rules_white_16-32-64.svg');
    }
}

.icon_line_screen_maximise_32,
.icon_line_screen_maximise.i16,
.icon_line_screen_maximise.i32,
.icon_line_screen_maximise.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_screen_maximise_16-32-64.svg');
}

.icon_line_screen_minimise_32,
.icon_line_screen_minimise.i16,
.icon_line_screen_minimise.i32,
.icon_line_screen_minimise.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_screen_minimise_16-32-64.svg');
}

.icon_line_search_32,
.icon_line_search.i16,
.icon_line_search.i32,
.icon_line_search.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_search_16-32-64.svg');
}

.icon_line_search_white_32,
.icon_line_search_white.i16,
.icon_line_search_white.i32,
.icon_line_search_white.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_search_white_16-32-64.svg');
}

.icon_line_select_32,
.icon_line_select.i16,
.icon_line_select.i32,
.icon_line_select.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_16-32-64.svg');
}

.icon_line_select_polygon_32,
.icon_line_select_polygon.i16,
.icon_line_select_polygon.i32,
.icon_line_select_polygon.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_polygon_16-32-64.svg');
}

.active {
    .icon_line_select_polygon.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_select_polygon_white_16-32-64.svg');
    }
}

.icon_line_select_polygon_add_32,
.icon_line_select_polygon_add.i16,
.icon_line_select_polygon_add.i32,
.icon_line_select_polygon_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_polygon_add_16-32-64.svg');
}

.active {
    .icon_line_select_polygon_add.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_select_polygon_add_white_16-32-64.svg');
    }
}

.icon_line_select_polygon_subtract_32,
.icon_line_select_polygon_subtract.i16,
.icon_line_select_polygon_subtract.i32,
.icon_line_select_polygon_subtract.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_polygon_subtract_16-32-64.svg');
}

.active {
    .icon_line_select_polygon_subtract.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_select_polygon_subtract_white_16-32-64.svg');
    }
}

.icon_line_select_rectangle_32,
.icon_line_select_rectangle.i16,
.icon_line_select_rectangle.i32,
.icon_line_select_rectangle.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_rectangle_16-32-64.svg');
}

.active {
    .icon_line_select_rectangle.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_select_rectangle_white_16-32-64.svg');
    }
}

.icon_line_sensor_32,
.icon_line_sensor.i16,
.icon_line_sensor.i32,
.icon_line_sensor.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_sensor_16-32-64.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.icon_line_select_rectangle_add_32,
.icon_line_select_rectangle_add.i16,
.icon_line_select_rectangle_add.i32,
.icon_line_select_rectangle_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_rectangle_add_16-32-64.svg');
}

.active {
    .icon_line_select_rectangle_add.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_select_rectangle_add_white_16-32-64.svg');
    }
}

.icon_line_select_rectangle_subtract_32,
.icon_line_select_rectangle_subtract.i16,
.icon_line_select_rectangle_subtract.i32,
.icon_line_select_rectangle_subtract.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_rectangle_subtract_16-32-64.svg');
}

.active {
    .icon_line_select_rectangle_subtract.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_select_rectangle_subtract_white_16-32-64.svg');
    }
}

.icon_line_select_single_32,
.icon_line_select_single.i16,
.icon_line_select_single.i32,
.icon_line_select_single.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_select_single_16-32-64.svg');
}

.icon_line_sign_32,
.icon_line_sign.i16,
.icon_line_sign.i32,
.icon_line_sign.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_sign_16-32-64.svg');
}

.icon_line_source_direct_32,
.icon_line_source_direct.i16,
.icon_line_source_direct.i32,
.icon_line_source_direct.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_source_direct_16-32-64.svg');
}

.icon_line_source_ntrip_32,
.icon_line_source_ntrip.i16,
.icon_line_source_ntrip.i32,
.icon_line_source_ntrip.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_source_ntrip_16-32-64.svg');
}

.icon_line_source_rtx_32,
.icon_line_source_rtx.i16,
.icon_line_source_rtx.i32,
.icon_line_source_rtx.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_source_rtx_16-32-64.svg');
}

.icon_line_source_sbas_32,
.icon_line_source_sbas.i16,
.icon_line_source_sbas.i32,
.icon_line_source_sbas.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_source_sbas_16-32-64.svg');
}

.icon_line_swapView_32,
.icon_line_swapView.i16,
.icon_line_swapView.i32,
.icon_line_swapView.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_swapView_16-32-64.svg');
}

.icon_line_sync_32,
.icon_line_sync.i16,
.icon_line_sync.i32,
.icon_line_sync.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_sync_16-32-64.svg');
}

.icon_line_tablet_32,
.icon_line_tablet.i16,
.icon_line_tablet.i32,
.icon_line_tablet.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_tablet_16-32-64.svg');
}

.icon_line_task_add_32,
.icon_line_task_add.i16,
.icon_line_task_add.i32,
.icon_line_task_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_task_add_16-32-64.svg');
}

.icon_line_task_subtract_32,
.icon_line_task_subtract.i16,
.icon_line_task_subtract.i32,
.icon_line_task_subtract.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_task_subtract_16-32-64.svg');
}

.icon_line_template_32,
.icon_line_template.i16,
.icon_line_template.i32,
.icon_line_template.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_template_16-32-64.svg');
}

.active {
    .icon_line_template.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_template_white_16-32-64.svg');
    }
}

.icon_line_template_draft_32,
.icon_line_template_draft.i16,
.icon_line_template_draft.i32,
.icon_line_template_draft.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_template_draft_16-32-64.svg');
}

.icon_line_text_32,
.icon_line_text.i16,
.icon_line_text.i32,
.icon_line_text.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_text_16-32-64.svg');
}

.icon_line_thumbnail_32,
.icon_line_thumbnail.i16,
.icon_line_thumbnail.i32,
.icon_line_thumbnail.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_thumbnail_16-32-64.svg');
}

.icon_line_tick_32,
.icon_line_tick.i16,
.icon_line_tick.i32,
.icon_line_tick.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_tick_16-32-64.svg');
}

.active {
    .icon_line_tick.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_line_tick_white_16-32-64.svg');
    }
}

.icon_line_tick_square_32,
.icon_line_tick_square.i16,
.icon_line_tick_square.i32,
.icon_line_tick_square.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_tick_square_16-32-64.svg');
}

.icon_line_todo_32,
.icon_line_todo.i16,
.icon_line_todo.i32,
.icon_line_todo.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_todo_16-32-64.svg');
}

.icon_line_turn_head_32,
.icon_line_turn_head.i16,
.icon_line_turn_head.i32,
.icon_line_turn_head.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_turn_head_16-32-64.svg');
}

.icon_line_accuracy,
.icon_line_accuracy.i16,
.icon_line_accuracy.i32,
.icon_line_accuracy.i64,
.icon_line_horizontal_accuracy,
.icon_line_horizontal_accuracy.i16,
.icon_line_horizontal_accuracy.i32,
.icon_line_horizontal_accuracy.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_horizontal_accuracy_16-32-64.svg');
}

.icon_line_vertical_accuracy,
.icon_line_vertical_accuracy.i16,
.icon_line_vertical_accuracy.i32,
.icon_line_vertical_accuracy.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_vertical_accuracy_16-32-64.svg');
}

.icon_line_view_fit_32,
.icon_line_view_fit.i16,
.icon_line_view_fit.i32,
.icon_line_view_fit.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_view_fit_16-32-64.svg');
}

.icon_line_visibility_hide_32,
.icon_line_visibility_hide.i16,
.icon_line_visibility_hide.i32,
.icon_line_visibility_hide.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_visibility_hide_16-32-64.svg');
}

.icon_line_visibility_hide_others_32,
.icon_line_visibility_hide_others.i16,
.icon_line_visibility_hide_others.i32,
.icon_line_visibility_hide_others.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_visibility_hide_others_16-32-64.svg');
}

.icon_line_visibility_show_32,
.icon_line_visibility_show.i16,
.icon_line_visibility_show.i32,
.icon_line_visibility_show.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_visibility_show_16-32-64.svg');
}

.icon_line_visibility_show_all_32,
.icon_line_visibility_show_all.i16,
.icon_line_visibility_show_all.i32,
.icon_line_visibility_show_all.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_visibility_show_all_16-32-64.svg');
}

.icon_line_visibility_show_only_32,
.icon_line_visibility_show_only.i16,
.icon_line_visibility_show_only.i32,
.icon_line_visibility_show_only.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_visibility_show_only_16-32-64.svg');
}

.icon_line_visibility_toggle_32,
.icon_line_visibility_toggle.i16,
.icon_line_visibility_toggle.i32,
.icon_line_visibility_toggle.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_visibility_toggle_16-32-64.svg');
}

.icon_line_walk_32,
.icon_line_walk.i16,
.icon_line_walk.i32,
.icon_line_walk.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_walk_16-32-64.svg');
}

.icon_line_white_plus_32,
.icon_line_white_plus.i16,
.icon_line_white_plus.i32,
.icon_line_white_plus.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_white_plus_16-32-64.svg');
}

.icon_line_white_tick_32,
.icon_line_white_tick.i16,
.icon_line_white_tick.i32,
.icon_line_white_tick.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_white_tick_16-32-64.svg');
}

.icon_line_x_32,
.icon_line_x.i16,
.icon_line_x.i32,
.icon_line_x.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_x_16-32-64.svg');
}

.icon_line_yesno_32,
.icon_line_yesno.i16,
.icon_line_yesno.i32,
.icon_line_yesno.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_line_yesno_16-32-64.svg');
}

.icon_solid_3D_isometric_32,
.icon_solid_3D_isometric.i16,
.icon_solid_3D_isometric.i32,
.icon_solid_3D_isometric.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_3D_isometric_16-32-64.svg');
}

.icon_solid_3D_reset_32,
.icon_solid_3D_reset.i16,
.icon_solid_3D_reset.i32,
.icon_solid_3D_reset.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_3D_reset_16-32-64.svg');
}

.icon_solid_alert_32,
.icon_solid_alert.i16,
.icon_solid_alert.i32,
.icon_solid_alert.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_alert_16-32-64.svg');
}

.icon_solid_alert_critical_32,
.icon_solid_alert_critical.i16,
.icon_solid_alert_critical.i32,
.icon_solid_alert_critical.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_alert_critical_16-32-64.svg');
}

.icon_solid_alert_info_32,
.icon_solid_alert_info.i16,
.icon_solid_alert_info.i32,
.icon_solid_alert_info.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_alert_info_16-32-64.svg');
}

.icon_solid_alert_positive_32,
.icon_solid_alert_positive.i16,
.icon_solid_alert_positive.i32,
.icon_solid_alert_positive.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_alert_positive_16-32-64.svg');
}

.icon_solid_alert_warning_32,
.icon_solid_alert_warning.i16,
.icon_solid_alert_warning.i32,
.icon_solid_alert_warning.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_alert_warning_16-32-64.svg');
}

.icon_solid_assistance_32,
.icon_solid_assistance.i16,
.icon_solid_assistance.i32,
.icon_solid_assistance.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_assistance_16-32-64.svg');
}

.icon_solid_autofields_32,
.icon_solid_autofields.i16,
.icon_solid_autofields.i32,
.icon_solid_autofields.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_autofields_16-32-64.svg');
}

.icon_solid_cache_32,
.icon_solid_cache.i16,
.icon_solid_cache.i32,
.icon_solid_cache.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_cache_16-32-64.svg');
}

.icon_solid_camera_32,
.icon_solid_camera.i16,
.icon_solid_camera.i32,
.icon_solid_camera.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_camera_16-32-64.svg');
}

// .icon_solid_cam_32,
// .icon_solid_cam.i16,
// .icon_solid_cam.i32,
// .icon_solid_cam.i64 {
//   background-image: url('../assets/images/SVGs-32scale/icon_solid_cam_16-32-64.svg');
// }

.icon_solid_cart_32,
.icon_solid_cart.i16,
.icon_solid_cart.i32,
.icon_solid_cart.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_cart_16-32-64.svg');
}

.icon_solid_clash_bang_critical_32,
.icon_solid_clash_bang_critical.i16,
.icon_solid_clash_bang_critical.i32,
.icon_solid_clash_bang_critical.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_bang_critical_16-32-64.svg');
}

.icon_solid_clash_bang_gray_32,
.icon_solid_clash_bang_gray.i16,
.icon_solid_clash_bang_gray.i32,
.icon_solid_clash_bang_gray.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_bang_gray_16-32-64.svg');
}

.icon_solid_clash_bang_information_32,
.icon_solid_clash_bang_information.i16,
.icon_solid_clash_bang_information.i32,
.icon_solid_clash_bang_information.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_bang_information_16-32-64.svg');
}

.icon_solid_clash_bang_positive_32,
.icon_solid_clash_bang_positive.i16,
.icon_solid_clash_bang_positive.i32,
.icon_solid_clash_bang_positive.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_bang_positive_16-32-64.svg');
}

.icon_solid_clash_bang_warning_32,
.icon_solid_clash_bang_warning.i16,
.icon_solid_clash_bang_warning.i32,
.icon_solid_clash_bang_warning.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_bang_warning_16-32-64.svg');
}

.icon_solid_clash_set_32,
.icon_solid_clash_set.i16,
.icon_solid_clash_set.i32,
.icon_solid_clash_set.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_set_16-32-64.svg');
}

.icon_solid_clash_set_add_32,
.icon_solid_clash_set_add.i16,
.icon_solid_clash_set_add.i32,
.icon_solid_clash_set_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_set_add_16-32-64.svg');
}

.icon_solid_clash_set_download_32,
.icon_solid_clash_set_download.i16,
.icon_solid_clash_set_download.i32,
.icon_solid_clash_set_download.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_set_download_16-32-64.svg');
}

.icon_solid_clash_set_remove_32,
.icon_solid_clash_set_remove.i16,
.icon_solid_clash_set_remove.i32,
.icon_solid_clash_set_remove.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clash_set_remove_16-32-64.svg');
}

.icon_solid_collectedby_32,
.icon_solid_collectedby.i16,
.icon_solid_collectedby.i32,
.icon_solid_collectedby.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_collectedby_16-32-64.svg');
}

.icon_solid_comment_32,
.icon_solid_comment.i16,
.icon_solid_comment.i32,
.icon_solid_comment.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_comment_16-32-64.svg');
}

.icon_solid_comment_add_32,
.icon_solid_comment_add.i16,
.icon_solid_comment_add.i32,
.icon_solid_comment_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_comment_add_16-32-64.svg');
}

.icon_solid_comment_conversation_32,
.icon_solid_comment_conversation.i16,
.icon_solid_comment_conversation.i32,
.icon_solid_comment_conversation.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_comment_conversation_16-32-64.svg');
}

.icon_solid_comment_edit_32,
.icon_solid_comment_edit.i16,
.icon_solid_comment_edit.i32,
.icon_solid_comment_edit.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_comment_edit_16-32-64.svg');
}

.icon_solid_comment_remove_32,
.icon_solid_comment_remove.i16,
.icon_solid_comment_remove.i32,
.icon_solid_comment_remove.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_comment_remove_16-32-64.svg');
}

.icon_solid_fields_32,
.icon_solid_fields.i16,
.icon_solid_fields.i32,
.icon_solid_fields.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_fields_16-32-64.svg');
}

.icon_solid_filter_32,
.icon_solid_filter.i16,
.icon_solid_filter.i32,
.icon_solid_filter.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_filter_16-32-64.svg');
}

.active {
    .icon_solid_filter {
        background-image: url('../assets/images/SVGs-32scale/icon_solid_filter_white_16-32-64.svg');
    }
}

.icon_solid_folder_closed_32,
.icon_solid_folder_closed.i16,
.icon_solid_folder_closed.i32,
.icon_solid_folder_closed.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_folder_closed_16-32-64.svg');
}

.icon_solid_folder_open_32,
.icon_solid_folder_open.i16,
.icon_solid_folder_open.i32,
.icon_solid_folder_open.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_folder_open_16-32-64.svg');
}

.icon_solid_info_32,
.icon_solid_info.i16,
.icon_solid_info.i32,
.icon_solid_info.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_info_16-32-64.svg');
}

.icon_solid_layers_32,
.icon_solid_layers.i16,
.icon_solid_layers.i32,
.icon_solid_layers.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_layers_16-32-64.svg');
}

.icon_solid_line_32,
.icon_solid_line.i16,
.icon_solid_line.i32,
.icon_solid_line.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_line_16-32-64.svg');
}

.icon_solid_locationservices_32,
.icon_solid_locationservices.i16,
.icon_solid_locationservices.i32,
.icon_solid_locationservices.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_locationservices_16-32-64.svg');
}

.icon_solid_lock_32,
.icon_solid_lock.i16,
.icon_solid_lock.i32,
.icon_solid_lock.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_lock_16-32-64.svg');
}

.icon_solid_map_32,
.icon_solid_map.i16,
.icon_solid_map.i32,
.icon_solid_map.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_map_16-32-64.svg');
}

.icon_solid_measure_32,
.icon_solid_measure.i16,
.icon_solid_measure.i32,
.icon_solid_measure.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_measure_16-32-64.svg');
}

.icon_solid_menu_32,
.icon_solid_menu.i16,
.icon_solid_menu.i32,
.icon_solid_menu.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_menu_16-32-64.svg');
}

.icon_solid_minus_32,
.icon_solid_minus.i16,
.icon_solid_minus.i32,
.icon_solid_minus.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_minus_16-32-64.svg');
}

.icon_solid_number_32,
.icon_solid_number.i16,
.icon_solid_number.i32,
.icon_solid_number.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_number_16-32-64.svg');
}

.icon_solid_pagefit_height_32,
.icon_solid_pagefit_height.i16,
.icon_solid_pagefit_height.i32,
.icon_solid_pagefit_height.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_pagefit_height_16-32-64.svg');
}

.icon_solid_pagefit_width_32,
.icon_solid_pagefit_width.i16,
.icon_solid_pagefit_width.i32,
.icon_solid_pagefit_width.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_pagefit_width_16-32-64.svg');
}

.icon_solid_plus_32,
.icon_solid_plus.i16,
.icon_solid_plus.i32,
.icon_solid_plus.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_plus_16-32-64.svg');
}

.icon_solid_point_32,
.icon_solid_point.i16,
.icon_solid_point.i32,
.icon_solid_point.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_point_16-32-64.svg');
}

.icon_solid_poly_32,
.icon_solid_poly.i16,
.icon_solid_poly.i32,
.icon_solid_poly.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_poly_16-32-64.svg');
}

.icon_solid_preferences_32,
.icon_solid_preferences.i16,
.icon_solid_preferences.i32,
.icon_solid_preferences.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_preferences_16-32-64.svg');
}

.icon_solid_select_single_32,
.icon_solid_select_single.i16,
.icon_solid_select_single.i32,
.icon_solid_select_single.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_16-32-64.svg');
}

.active {
    .icon_solid_select_single.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_white_16-32-64.svg');
    }
}

.icon_solid_select_single_add_32,
.icon_solid_select_single_add.i16,
.icon_solid_select_single_add.i32,
.icon_solid_select_single_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_add_16-32-64.svg');
}

.active {
    .icon_solid_select_single_add.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_add_white_16-32-64.svg');
    }
}

.icon_solid_select_single_add_32,
.icon_solid_select_single_add.i16,
.icon_solid_select_single_add.i32,
.icon_solid_select_single_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_add_16-32-64.svg');
}

.active {
    .icon_solid_select_single_add.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_add_white_16-32-64.svg');
    }
}

.icon_solid_select_single_subtract_32,
.icon_solid_select_single_subtract.i16,
.icon_solid_select_single_subtract.i32,
.icon_solid_select_single_subtract.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_subtract_16-32-64.svg');
}

.active {
    .icon_solid_select_single_subtract.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_solid_select_single_subtract_white_16-32-64.svg');
    }
}

.icon_solid_settings_32,
.icon_solid_settings.i16,
.icon_solid_settings.i32,
.icon_solid_settings.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_settings_16-32-64.svg');
}

.icon_solid_space_32,
.icon_solid_space.i16,
.icon_solid_space.i32,
.icon_solid_space.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_space_16-32-64.svg');
}

.icon_solid_swapView_32,
.icon_solid_swapView.i16,
.icon_solid_swapView.i32,
.icon_solid_swapView.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_swapView_16-32-64.svg');
}

.icon_solid_text_32,
.icon_solid_text.i16,
.icon_solid_text.i32,
.icon_solid_text.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_text_16-32-64.svg');
}

.icon_solid_todo_32,
.icon_solid_todo.i16,
.icon_solid_todo.i32,
.icon_solid_todo.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_todo_16-32-64.svg');
}

.active {
    .icon_solid_todo.i32 {
        background-image: url('../assets/images/SVGs-32scale/icon_solid_todo_white_16-32-64.svg');
    }
}

.icon_solid_todo_add_32,
.icon_solid_todo_add.i16,
.icon_solid_todo_add.i32,
.icon_solid_todo_add.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_todo_add_16-32-64.svg');
}

.icon_solid_todo_field_32,
.icon_solid_todo_field.i16,
.icon_solid_todo_field.i32,
.icon_solid_todo_field.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_todo_field_16-32-64.svg');
}

.icon_solid_trash_32,
.icon_solid_trash.i16,
.icon_solid_trash.i32,
.icon_solid_trash.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_trash_16-32-64.svg');
}

.icon_solid_user_32,
.icon_solid_user.i16,
.icon_solid_user.i32,
.icon_solid_user.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_user_16-32-64.svg');
}

.icon_solid_user_group_32,
.icon_solid_user_group.i16,
.icon_solid_user_group.i32,
.icon_solid_user_group.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_user_group_16-32-64.svg');
}

.icon_solid_white_alert_32,
.icon_solid_white_alert.i16,
.icon_solid_white_alert.i32,
.icon_solid_white_alert.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_white_alert_16-32-64.svg');
}

.icon_vect_line_move_hand_closed_32,
.icon_vect_line_move_hand_closed.i16,
.icon_vect_line_move_hand_closed.i32,
.icon_vect_line_move_hand_closed.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_vect_line_move_hand_closed_16-32-64.svg');
}

.icon_vect_line_move_hand_open_32,
.icon_vect_line_move_hand_open.i16,
.icon_vect_line_move_hand_open.i32,
.icon_vect_line_move_hand_open.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_vect_line_move_hand_open_16-32-64.svg');
}

.icon_vect_solid_move_hand_closed_32,
.icon_vect_solid_move_hand_closed.i16,
.icon_vect_solid_move_hand_closed.i32,
.icon_vect_solid_move_hand_closed.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_vect_solid_move_hand_closed_16-32-64.svg');
}

// .icon_vect_solid_move_hand_open_32,
// .icon_vect_solid_move_hand_open.i16,
// .icon_vect_solid_move_hand_open.i32,
// .icon_vect_solid_move_hand_open.i64 {
//   background-image: url('../assets/images/SVGs-32scale/icon_vect_solid_move_hand_open_16-32-64.svg');
// }

.icon_repeat_field_32,
.icon_repeat_field.i16,
.icon_repeat_field.i32,
.icon_repeat_field.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_repeat_field_16-32-64.svg');
}

.icon_repeat_field_24,
.icon_repeat_field.i24,
.icon_repeat_field.i48,
.icon_repeat_field.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_repeat_field_24-48-96.svg');
}

.icon_increment_repeat_field_32,
.icon_increment_repeat_field.i16,
.icon_increment_repeat_field.i32,
.icon_increment_repeat_field.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_increment_repeat_field_16-32-64.svg');
}

.icon_increment_repeat_field_24,
.icon_increment_repeat_field.i24,
.icon_increment_repeat_field.i48,
.icon_increment_repeat_field.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_increment_repeat_field_24-48-96.svg');
}

.icon_duplicate_template_32,
.icon_duplicate_template.i16,
.icon_duplicate_template.i32,
.icon_duplicate_template.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_duplicate_template_16-32-64.svg');
}

.icon_duplicate_template_24,
.icon_duplicate_template.i24,
.icon_duplicate_template.i48,
.icon_duplicate_template.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_duplicate_template_24-48-96.svg');
}

.icon_file_link_32,
.icon_file_link.i16,
.icon_file_link.i32,
.icon_file_link.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_file_link_16-32-64.svg');
}

.icon_file_link_24,
.icon_file_link.i24,
.icon_file_link.i48,
.icon_file_link.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_file_link_24-48-96.svg');
}

.icon_solid_archive_32,
.icon_solid_archive.i16,
.icon_solid_archive.i32,
.icon_solid_archive.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_archive_16-32-64.svg');
}

.icon_solid_archive_24,
.icon_solid_archive.i24,
.icon_solid_archive.i48,
.icon_solid_archive.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_archive_24-48-96.svg');
}

.icon_solid_unarchive_32,
.icon_solid_unarchive.i16,
.icon_solid_unarchive.i32,
.icon_solid_unarchive.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_unarchive_16-32-64.svg');
}

.icon_solid_clipboard_32,
.icon_solid_clipboard.i16,
.icon_solid_clipboard.i32,
.icon_solid_clipboard.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clipboard_16-32-64.svg');
    background-size: 80% 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.icon_solid_clipboard_white_32,
.icon_solid_clipboard_white.i16,
.icon_solid_clipboard_white.i32,
.icon_solid_clipboard_white.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_clipboard_white_16-32-64.svg');
    background-size: 80% 80%;
    background-repeat: no-repeat;
    background-position: center;
}

.icon_solid_paper_plus_32,
.icon_solid_paper_plus.i16,
.icon_solid_paper_plus.i32,
.icon_solid_paper_plus.i64 {
    background-image: url('../assets/images/SVGs-32scale/icon_solid_paper_plus_16-32-64.svg');
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
}

.icon_solid_unarchive_24,
.icon_solid_unarchive.i24,
.icon_solid_unarchive.i48,
.icon_solid_unarchive.i96 {
    background-image: url('../assets/images/SVGs-24scale/icon_solid_unarchive_24-48-96.svg');
}

.icon_trimble_connect {
    background-image: url('../assets/images/icons/icon_trimble_connect.svg');
    background-size: 100%;
}

.icon_check {
    background: url('../assets/images/icons/check.svg');
    background-size: 100%;
}

.icon_cross {
    background: url('../assets/images/icons/content_close.svg');
    background-size: 100%;
}

.icon_create {
    background: url('../assets/images/icons/edit.svg');
    background-size: 100%;
}

.icon_assign {
    background: url('../assets/images/icons/assign_to_workspace.svg');
    background-size: 100%;
}

.icon_unassign {
    background: url('../assets/images/icons/unassign_from_workspace.svg');
    background-size: 100%;
}
.icon-settings {
    background: url('../assets/images/icons/solid_preferences.svg');
    background-size: 100%;
}

.icon-hint-info {
    background-image: url('../assets/images/icons/info_outline.svg');
    background-size: 100%;
}

.icon-hint-info-white {
    background-image: url('../assets/images/icons/info_outline_white.svg');
    background-size: 100%;
}

.icon-lock-outline {
    background-image: url('../assets/images/icons/lock_outline.svg');
    background-size: 100%;
}

.icon-lock-open {
    background-image: url('../assets/images/icons/lock_open.svg');
    background-size: 100%;
}

.icon-history {
    background-image: url('../assets/images/icons/icon_history.svg');
    background-size: 100%;
}

.icon-history-white {
    background-image: url('../assets/images/icons/icon_history_white.svg');
    background-size: 100%;
}

.icon-history-with-update {
    background-image: url('../assets/images/icons/icon_history_with_update.svg');
    background-size: 100%;
}

.icon-notification {
    background-image: url('../assets/images/icons/icon_notification.svg');
    background-size: 100%;
}

.icon-notification-white {
    background-image: url('../assets/images/icons/icon_notification_white.svg');
    background-size: 100%;
}

.icon-notification-with-update {
    background-image: url('../assets/images/icons/icon_notification_with_update.svg');
    background-size: 100%;
}

.icon-person {
    background-image: url('../assets/images/icons/icon_person.svg');
    background-size: 100%;
}

.icon-person-white {
    background-image: url('../assets/images/icons/icon_person_white.svg');
    background-size: 100%;
}

.icon-person-with-update {
    background-image: url('../assets/images/icons/icon_person_with_update.svg');
    background-size: 100%;
}

.icon-alert-circle-solid {
    background-image: url('../assets/images/icons/icon_alert_circle_solid.svg');
    background-size: 100%;
}

.icon-tick-in-circle {
    background-image: url('../assets/images/icons/icon_tick_in_circle.svg');
    background-size: 100%;
}

.icon-upload-solid {
    background-image: url('../assets/images/icons/icon_upload_solid.svg');
    background-size: 100%;
}

.icon-time {
    background-image: url('../assets/images/icons/icon_time.svg');
    background-size: 100%;
}

.icon-horizontal-spacer {
    background-image: url('../assets/images/icons/icon_horizontal_spacer.svg');
    background-size: 100%;
}

.icon-rotate-left-alt-white {
    background-image: url('../assets/images/icons/icon_rotate_left_alt_white.svg');
    background-size: 100%;
}

.icon-accuracy {
    background-image: url('../assets/images/icons/icon_accuracy.svg');
    background-size: 100%;
}

.icon-accuracy-white {
    background-image: url('../assets/images/icons/icon_accuracy_white.svg');
    background-size: 100%;
}

.icon-arrow-back-white {
    background-image: url('../assets/images/icons/icon_arrow_back_white.svg');
    background-size: 100%;
}

.icon-update {
    background-image: url('../assets/images/icons/update.svg');
    background-size: 100%;
}

.icon-map-marker {
    background-image: url('../assets/images/icons/icon_map_marker.svg');
    background-size: 100%;
}

.icon-map-marker-x {
    background-image: url('../assets/images/icons/icon_map_marker_x.svg');
    background-size: 100%;
}

.icon-map-marker-y {
    background-image: url('../assets/images/icons/icon_map_marker_y.svg');
    background-size: 100%;
}

.icon-map-marker-z {
    background-image: url('../assets/images/icons/icon_map_marker_z.svg');
    background-size: 100%;
}

.icon-resize-handle-horizontal {
    background-image: url('../assets/images/icons/icon_resize_handle_horizontal.svg');
    background-size: 100%;
    background-repeat: no-repeat;
}

.icon-sensor-child-diamond {
    background-image: url('../assets/images/icons/icon_sensor_child.svg');
    background-size: 100%;
}

.icon-sensor-child {
    background-image: url('../assets/images/SVGs-32scale/icon_line_sensor_16-32-64.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    opacity: 0.4;
}

.icon-summary {
    margin-top: 10px;
    background-image: url('../assets/images/SVGs-32scale/icon_summary_choice.svg');
    background-size: 100%;
}

.icon-summary-active {
    margin-top: 10px;
    background-image: url('../assets/images/SVGs-32scale/icon_summary_choice_active.svg');
    background-size: 100%;
}
