// Variables for this file

@dropdown-default-width: 15rem;
@dropdown-default-height: 3.2rem;
@dropdown-font-size: 1.6rem; //btn-font-size (same?)
@dropdown-font-weight: 300;

// File
.dropdown-container {
    position: relative;
    .mixin-dropdown-width;
    &.open {
        .dropdown-menu {
            display: block;
            bottom: inherit;
            padding: 0;
        }
    }

    .placeholder {
        color: @col_gray_5;
    }

    .multi-selected-options {
        overflow: hidden;
        list-style: none;
        margin: 0;
        padding: 0 35px 0 0;
        height: auto !important;
        min-height: 32px;
        color: @col_trimble_gray;
        cursor: pointer;
        white-space: nowrap;
        border: 1px solid @col_gray_1;
        border-radius: @border-radius-value;
        background-color: @col_white;

        .collected-selections {
            ul {
                .text-ellipsis;
                padding: 7px;
            }

            li {
                position: relative;
                display: inline;
                &.placeholder {
                    padding: @padding-tiny 0 @padding-small;
                }
            }
        }

        .icon_line_arrow_down {
            position: absolute;
            top: 0;
            right: @padding-small;
        }
    }

    .option-remove {
        display: block;
        width: 12px;
        height: 13px;
        position: absolute;
        right: 3px;
        top: 4px;
        font-size: 1px;
        outline: none;
    }

    .dd-toggle {
        width: 100%;
        .toggle-text {
            flex: auto;
            width: 100px;
            .text-overflow;
            border-right: 0;
            border-left: 0;
        }
    }

    .check-mark {
        float: right;
    }

    .dropdown-icon {
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        span {
            display: inline-block;
        }
    }

    .right-align {
        left: initial;
        right: 0;
    }
    .toggle-button {
        display: inline-block;
        margin-bottom: 0;
        .mixin-input-padding;
        // font-weight: @dropdown-font-weight;
        // font-size: @dropdown-font-size;

        color: @col_trimble_gray;
        height: @dropdown-default-height;
        width: @dropdown-default-width;
        cursor: pointer;
        white-space: nowrap;
        border: 1px solid @col_gray_1;
        border-radius: @border-radius-value;
        .flex-row;
        .justify-between;
        .align-center;
        background-color: @col_white;
        width: 100%;
        &:hover {
            text-decoration: none;
        }
        > i {
            flex: 0 0 auto;
        }
    }

    &[disabled] {
        .toggle-button {
            cursor: default;
            &:hover {
                text-decoration: none;
            }
        }
    }

    .dd-item-color:hover .dd-inner-li {
        background-color: inherit !important;
    }
    .dd-item-color {
        height: 38px;
    }
}

// This should be indepedent so it can be reused in overlap dropdowns
.dropdown-menu {
    width: 100%;
    position: absolute;
    bottom: inherit;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 140px;
    margin: 2px 0 0;
    font-size: 14px;
    list-style: none;
    background-color: @col_white;
    border: 1px solid @col_gray_1;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    cursor: pointer;
    padding: 0;
    &.overlap-1 {
        z-index: 2000;
    }
    &.overlap-2 {
        z-index: 3000;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 290px;
        overflow-y: auto;
        min-height: 20px;
        & > .dd-li-item {
            border-bottom: 1px solid lightgray;
            &:focus {
                color: @col_gray_dark_1;
                background-color: @col_gray_half;
                outline: 0;
            }
            .option-description {
                font-style: italic;
                margin-left: 25px;
            }
            .option-label {
                .mixin-input-padding;
            }
            &.selected {
                background-color: @col_gray_half;

                &.multiple-selected {
                    background-color: @col_trimble_blue_pale_light;

                    .modus-icons {
                        font-size: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 0px 0 5px;
                        color: @col_trimble_blue_mid;
                    }

                    .option-label {
                        color: @col_trimble_blue_mid;
                    }
                }
            }
        }
        & > .dd-li-item.dd-item-color {
            &:focus {
                outline: dotted;
                border: 2px solid @col_gray_half;
            }
        }
        li.dropdown-empty-text {
            padding-left: 10px;
        }
    }

    .search-box {
        padding: @padding-tiny;
    }

    .dd-inner-li {
        .flex-ct-row;
        .justify-start;
        .align-center;
        color: @col_trimble_gray;

        //IE 10 bugfix
        height: auto;
        min-height: @dropdown-default-height;
        padding: 8px 0;

        &:hover {
            color: @col_gray_dark_1;
            text-decoration: none;
            background-color: @col_gray_half;
        }
    }

    .empty-text {
        padding: @padding-tiny @padding-tiny @padding-tiny 0;
    }
}

/*
quick hack fix for IE in template editor. IE does not like width 100%
need to revisit and fix this
*/

.tile .dropdown-container {
    width: 168px !important;
}

.map-dropdown-inner-container {
    .dropdown-container;
    background: none;
    height: 40px;
    &.map-provider-dropdown-container {
        width: 110px;
    }
    &.map-modes-dropdown-container {
        width: 150px;
    }
    &.open {
        .dropdown-menu {
            border-top: none;
            margin-top: 1px;
            border: none;
        }
    }
    .dd-toggle {
        .toggle-text {
            padding-left: 15px;
        }
    }
    .dropdown-menu {
        min-width: inherit;
        background-color: @col_gray_light;
        margin: 0;
        border: none;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
        ul {
            & > .dd-li-item {
                border-bottom: none;
                &:focus {
                    background-color: @col_white;
                    outline: 0;
                }
            }
        }
        .dd-inner-li {
            min-height: 3.4rem;
            padding: 0px 10px;
            &:hover {
                background-color: @col_white;
            }
        }
    }
    .toggle-button {
        border: none;
        height: 40px;
        &:hover {
            text-decoration: none;
        }
    }
}

.attr-value-dropdown {
    .dropdown-container {
        > .toggle-button {
            padding: 2px 0;
            height: auto;

            .toggle-text {
                margin-left: @padding-small;
            }
        }

        .dropdown-menu .dd-inner-li {
            padding: @padding-tiny 0;
        }
    }
}

.hide-option {
    display: none;
}

gsp-dropdown {
    &.has-error {
        border: 1px solid;
        border-left: 5px solid;
        border-color: @col_red_dark;
        display: flex;
    }
}
