.import-files-mapviewer,
.workspace-create-from-file-wizard {
    .modal-popup {
        .modal-content {
            .modal-body {
                .browse-data-panel {
                    footer {
                        text-align: right;
                        margin-top: @padding-default;
                    }
                }
                .ng-scroll-content {
                    max-width: 100%;
                }
            }
        }

        .upload-options {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .error-container {
            .mixin-critical;

            .error-message {
                font-weight: 600;
                margin-top: 8px;
            }
        }

        .upload-file-list {
            height: 160px;
            overflow: hidden;
        }
    }
}

.browse-data-menu {
    padding: 15px 0;
    overflow: hidden;
    width: 100%;
    position: relative;

    .icon_line_chevron_left {
        position: absolute;
        left: 0;
        cursor: pointer;
    }

    .browse-data-title {
        margin: 0 auto;
        font-weight: 600;
        text-align: center;
        padding-top: 6px;
        width: 100%;
        .text-overflow;
    }
}

.browse-data-list {
    margin-left: -30px;
    margin-right: -30px;
    height: 450px;

    ul {
        margin: 0;
        padding: 0;
        border-top: solid 1px @border-color;
        .mixin-custom-scroll;

        li {
            margin: 0;
            padding: 0;
            list-style: none;
            height: 64px;
            border-bottom: solid 1px @border-color;
            clear: both;
            cursor: pointer;

            &:hover,
            &.selected {
                border-bottom: solid 1px @col_blue;
                background-color: @col_blue;
                color: @col_white;

                .icon_line_chevron_right {
                    filter: brightness(5);
                }

                .list-item-detail {
                    .modified {
                        color: @col_white;
                    }
                }
            }

            .list-thumb {
                float: left;
                display: inline-block;
                width: 64px;
                height: 64px;
                margin-right: 20px;
                object-fit: cover;

                img {
                    width: 64px;
                    height: 64px;
                    object-fit: cover;
                }

                i {
                    margin: 0 auto;
                    margin-top: 7px;
                }
            }

            .list-item-detail {
                float: left;
                display: inline-block;
                padding-top: 13px;
                height: 64px;
                font-size: 13px;
                width: 70%;

                .file-name {
                    font-weight: 600;
                    .text-overflow;
                }

                .modified {
                    color: @col_gray_7;
                    .text-overflow;
                }
            }

            .list-item-select {
                float: right;
                padding-top: 16px;
                padding-right: 32px;
            }
        }
    }

    .no-files {
        padding: @padding-default;
        text-align: center;
        color: @col_gray_7;
    }
}

.file-status {
    padding-right: 15px; // allow space for scrollbar
    li {
        padding: @padding-small 0;
    }
    display: flex;
    flex-direction: column;

    .import-item-container {
        padding: 0 @padding-default;
    }

    .import-accordion-container {
        padding-left: @padding-default;
    }

    .import-error-message {
        padding: @padding-small @padding-default 0 0;
    }
    .import-error-request-id {
        padding-top: @padding-tiny;
        display: flex;
        justify-content: space-between;
        line-height: 24px;
        vertical-align: middle;
    }

    .accordion-toggle {
        margin-top: 5px;
        font-size: 1.2rem;
        display: flex;
        align-items: flex-end;
        text-decoration: underline;
        text-decoration-color: black;
        cursor: pointer;
    }

    &.fileviewer {
        loading {
            .loading-small {
                margin-top: 15vh;
            }
        }
        .exit-message {
            .menu-sub-heading;
            .center-text;
            padding: @padding-default;
        }

        .import-errors-summary {
            padding-top: @padding-default;
        }

        .notification {
            .file-summary {
                display: flex;
                .error-messages {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 90%;
                }
            }
        }
    }

    .file-scanned-contents {
        padding: 1rem;

        ul {
            padding: 0 2rem;
        }

        li {
            list-style: disc;
            margin: initial;
            padding: 0.5rem 0;
        }
    }
}

.import-file-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: @padding-tiny;
    &.file-error-info {
        flex-direction: column;
        .name {
            width: 100%;
        }
    }
    .name {
        .text-overflow;
        align-self: center;
    }
    .status {
        display: flex;
        h6 {
            .text-overflow;
            line-height: 2.4rem;
        }
    }

    .success-dialog,
    .error-dialog {
        margin: 4px;
    }
}

.workspace-create-from-file-wizard {
    ng-scrollbar {
        height: auto;
        max-height: auto;
        overflow: visible;
    }

    .modal-popup.medium-modal {
        .modal-content {
            min-height: 526px;
            height: auto;
        }
    }

    .upload-error-wrapper {
        padding: 2rem 0 0;

        .file-scanned-contents {
            padding: 0.5rem 0 0;
        }
    }
}
