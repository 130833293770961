/* Variables for this File / Layout */

.mixin-icon-margins {
    margin: 0.8rem;
}

@map-toolbar-height: 4rem;

/* Page Design and Layout */

.menu-hamburger {
    min-width: 53px;
}

/* Common styling for all elements across the top */

.nav-menu,
.map-title,
#selection-tools,
.collaboration-tools,
#zoom-tool,
#search-tool,
#refresh-tool {
    .mixin-floating-panel;
    /* Comes from the variables.less file */
    height: @map-toolbar-height;
    display: block;
    float: left;
    margin: 0 @padding-tiny @padding-tiny 0;
    padding-right: 0.4rem;
}

.nav-menu,
.map-title,
#selection-tools,
#refresh-tool {
    padding-left: 0.4rem;
}

.nav-menu {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button-confirm-wrapper {
        margin-left: 0;
    }
}

/* This is the workspace title  TODO perhaps it needs to go into a different less file*/

.top-toolbar {
    .map-title,
    .search {
        width: @left-panel-width;
    }
    .map-title {
        ul {
            .border-radius(@border-radius-value);
            .box-shadow(@shadow);

            & li {
                background: @col_white;
                border: 1px solid @col_gray_1;
                padding: @padding-tiny @padding-small;
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover,
                &.active {
                    background: @col_gray_1;
                }

                span {
                    font-weight: bold;
                }
            }
        }

        form {
            display: flex;
            width: 75%;
            .flex(1, 0, auto);
        }

        .input-text {
            .mixin-input-common();
            .text-overflow;
            height: 4rem;
            line-height: 4rem;
            border: none;
            width: 100%;
            display: block;
            .flex(1, 0, auto);
        }

        .map-title-text {
            min-width: 212px;
            font-size: 22px;
            line-height: 4rem;
            height: 4rem;
            padding-left: 12px;
            padding-right: 12px;
            .text-overflow;
            border-left: none;
            border-right: none;
            border-top: none;
        }
    }

    .map-title {
        display: flex;
        flex-direction: row;
        .flex(0, 0, auto);

        .input-text {
            font-size: 22px; //TODO lets put a mixin to the font style for a larger font here
        }

        .folder {
            flex-shrink: 0;
            flex-grow: 0;
            padding: 0.8rem;
            padding-left: @padding-default;
            padding-right: @padding-default;
            cursor: pointer;
        }
    }
}

refresh-tools {
    z-index: 10;
    position: fixed;
    margin-top: @padding-small;
    right: @padding-tiny;
}

#refresh-tool {
    .over-map;
    margin-left: auto;
    .button-confirm-wrapper {
        margin-left: 0;
    }
}

body:not(.right-panel-open) {
    #refresh-tool,
    #toast-container {
        margin-right: auto !important;
    }
}

/* Content that has been copied over */

.selection-tools-menu.menu-open {
    .mixin-floating-panel;
    border-radius: 0 0 2px 2px;
}

#map-controls,
#refresh-tool,
.map-toolbar-group-icons {
    .small-radio-group {
        /* default state for the small radio group is none, this is only shown when the window size is small */
        display: none;
    }

    .selection-tools {
        display: flex;
        position: relative;

        .large-radio-group {
            display: none;
            background-color: @col_white;

            &.show-group {
                display: block;
            }
        }
    }

    .open .map-toolbar-group-icons {
        .mixin-floating-panel-light;
    }

    .btn,
    .button-icon {
        .btn;
        /* bootstrap */
        .btn-icon;
        /* bootstrap */
        .mixin-button();
        .mixin-button-icon;
        padding-left: 4px;
        padding-right: 4px;
        .mixin-active-colors-strong;
        border-radius: 0;
    }
}

/* ############################################ */

/* Search Place component styles */

/* ############################################ */
#search-tool {
    .search-box.input-text {
        .mixin-input-common();
        .text-overflow;
        height: 4rem;
        line-height: 4rem;
        border: none;
        width: 100%;
        display: block;
        .flex(1, 0, auto);
    }

    ul {
        .border-radius(@border-radius-value);
        .box-shadow(@shadow);

        & li {
            background: @col_white;
            border: 1px solid @col_gray_1;
            padding: @padding-tiny @padding-small;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover,
            &.active {
                background: @col_gray_1;
            }

            span {
                font-weight: bold;
            }
        }
    }

    form {
        display: flex;
        width: 75%;
        .flex(1, 0, auto);
    }
}

/* ############################################ */

/* Media Queries */

/* ############################################ */

/**
For small monitor
*/

@media all and (max-width: 1024px) {
    #map-controls {
        // TODO: MY re-instate selection-tools responsive styles
        // .selection-tools {
        //   .large-radio-group {
        //     // display: none;

        //     &.show-group {
        //       // display: none;
        //     }
        //   }

        //   .small-radio-group {
        //     display: block;
        //   }
        // }

        .search {
            width: 14rem;
        }

        .open .map-toolbar-group-icons {
            position: absolute;
            left: 0px;
            top: 48px;
            width: 120px;
        }
    }
}

/* This one styles the google geolocation search box to have a min width */

.pac-container {
    min-width: 220px !important;
}
