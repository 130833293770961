.crosshair-cur {
    cursor: url('../assets/images/cursors/crosshair.cur'), default !important;
}

.crosshair-minus-cur {
    cursor: url('../assets/images/cursors/crosshair-minus.cur'), default !important;
}

.crosshair-plus-cur {
    cursor: url('../assets/images/cursors/crosshair-plus.cur'), default !important;
}

.select-cur {
    cursor: url('../assets/images/cursors/select.cur'), default !important;
}

.select-minus-cur {
    cursor: url('../assets/images/cursors/select-minus.cur'), default !important;
}

.select-plus-cur {
    cursor: url('../assets/images/cursors/select-plus.cur'), default !important;
}

.disableselection {
    &.single-new-mode,
    &.single-append-mode,
    &.single-remove-mode,
    &.rectangle-new-mode,
    &.polygon-new-mode,
    &.rectangle-append-mode,
    &.polygon-append-mode,
    &.rectangle-remove-mode,
    &.polygon-remove-mode {
        & {
            .leaflet-mouse-marker,
            .leaflet-clickable {
                .select-cur;
            }

            .select-cur;
        }
    }
}

.single-new-mode {
    .select-cur;

    .leaflet-mouse-marker,
    .leaflet-clickable {
        .select-cur;
    }
}

.single-append-mode {
    .select-plus-cur;

    .leaflet-mouse-marker,
    .leaflet-clickable {
        .select-plus-cur;
    }
}

.single-remove-mode {
    .select-minus-cur;

    .leaflet-mouse-marker,
    .leaflet-clickable {
        .select-minus-cur;
    }
}

.rectangle-new-mode,
&.polygon-new-mode {
    .crosshair-cur;

    .leaflet-mouse-marker,
    .leaflet-clickable {
        .crosshair-cur;
    }
}

.rectangle-append-mode,
&.polygon-append-mode {
    .crosshair-plus-cur;

    .leaflet-mouse-marker,
    .leaflet-clickable {
        .crosshair-plus-cur;
    }
}

.rectangle-remove-mode,
&.polygon-remove-mode {
    .crosshair-minus-cur;

    .leaflet-mouse-marker,
    .leaflet-clickable {
        .crosshair-minus-cur;
    }
}
