/* 
   Should only contain base settings, eg. default padding, margins for specific elements,
   root font size, etc.
*/

* {
    box-sizing: border-box;
    .base_font;
}

html {
    font-size: 62.5%; // allows rem sizing to match pixel, eg. 1.6rem = 16 pixels
    min-width: 800px; /* TODO This min widht is here to reduce errors on small screen sizes until we support responsive design */
}

body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: @col_gray_light;
    color: @col_trimble_gray;
}

ul {
    margin: 0;
    padding: 0;
    li {
        list-style: none;
    }
}

input,
button {
    outline: 0;
    &:focus {
        outline: 0;
    }
}

input[type='text'],
input[type='number'] {
    width: 100%;
    //padding: 0 12px;
    min-height: 32px;
    .border-radius;
    border: @border-style;
    //border: 1px solid @col_markup; // TODO: fix the global input styles Please turn this back on to see what overrides are around the app
}

input[type='checkbox'],
input[type='radio'] {
    margin: 0;
}

hr {
    // Remove the default hr border
    margin: 0;
    border: none;
    border-bottom: 1px solid @col_gray_1;
}

input[type='radio'] {
    margin: 0;
    margin-right: 10px;
    outline: 0 !important;
}

label {
    font-weight: normal;
}

textarea {
    &:focus {
        outline: 0;
    }
    padding: 10px;
    border-radius: @border-radius-value;
    border: solid 1px @col_gray_1;
}

.btn-circle {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: @col_trimble_blue;
    .flexbox;
    justify-content: center;
    align-items: center;
}

input[type='text']::-ms-clear {
    display: none;
}

.i24,
.i48,
.i96,
.i16,
.i32,
.i64 {
    display: block;
}
