.mat-mdc-tab-group {
    width: 100%;
    overflow: hidden;

    .mat-mdc-tab-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .mat-mdc-tab-label-container {
            .mat-mdc-tab {
                min-width: 160px;
                letter-spacing: normal;

                .mdc-tab__content .mdc-tab__text-label gsp-help-text {
                    opacity: 0.5;
                    transition: 150ms opacity linear;
                }

                &.mdc-tab--active .mdc-tab__content .mdc-tab__text-label gsp-help-text {
                    opacity: 1;
                }
            }
            .mat-mdc-tab-labels {
                justify-content: center;
                .mat-mdc-tab {
                    flex-grow: unset !important;

                    .mdc-tab__ripple::before {
                        // Disable hover color
                        background-color: initial;
                    }
                    .mat-ripple-element {
                        // Tab focus color
                        background-color: @col_gray_5;
                    }
                }
                .mdc-tab__text-label {
                    color: @col_gray_5;
                }
                .mdc-tab--active .mdc-tab__text-label {
                    color: @col_trimble_blue;
                    opacity: 0.96;
                }
            }
            .mdc-tab-indicator {
                &__content--underline {
                    border-color: @col_trimble_blue_mid;
                    background-color: @col_trimble_blue_mid;
                    height: 4px;
                }
                &--active &__content {
                    opacity: 0.96;
                }
            }
        }
    }
    .mat-mdc-tab-nav-bar {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .mat-mdc-tab-body-wrapper {
        height: 100vh;
    }
}
