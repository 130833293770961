.side-panel {
    min-width: 320px;
    max-width: 33.33%;
    height: 100%;
    bottom: 0;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 11;
    background-color: @col_gray_half;

    .resize-handle {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: -5px;
        width: 5px;
        height: 100%;
        cursor: ew-resize;
        z-index: 1000;

        .icon-resize-handle-horizontal {
            transform: scale(1.4);
            position: relative;
            right: -7px;
        }
    }

    .mixin-shadow-large;
    header {
        .mixin-panel-header;
        h2 {
            .text-overflow;
            color: @col_trimble_blue_mid;
        }
    }
}

.sidebar-form-group {
    > div {
        margin: 0 0 30px;
        &.mt10 {
            margin-top: 10px !important;
        }
        &:last-child {
            margin-bottom: 0 !important;
        }
    }
    input {
        width: 100%;
    }
    .secondary-header {
        margin: 35px 0 0;
    }
    p {
        margin: 10px 0 15px;
    }
    h3 {
        font-size: 14px;
        font-weight: bold;
    }
}

.existing-layers {
    display: flex;
    flex-direction: column;
    // height: 100vh;

    header.content,
    .search.content,
    footer {
        flex-shrink: 0;
        flex-grow: 0;
    }

    .add-layer-list {
        flex: 1 1 auto;
        overflow: hidden;
        .height-full;
        .no-template-message {
            margin: @padding-small @padding-default;
            width: 280px;
            p {
                margin: 0;
            }
        }
    }
}

.task_detail_panel {
    width: 320px;
}

.selected_features_panel {
    min-width: 330px; // Extra width to make the resize handle stick out
}

/* mixin for side panels */

.mixin-side-panel {
    .mixin-floating-panel;
    .flex-column;
    padding: 0;
    height: 100%;

    header {
        .mixin-fixed;
        padding: @padding-medium;
        .flex-row;
        .mixin-fw;
        justify-content: space-between;
        .title {
            .mixin-fw;
        }
        button {
            background: transparent;
            border: none;
            padding: 0;
        }
    }
    .icon-cancel {
        .i24;
        .icon_line_cancel_24;
    }
    section {
        .mixin-scrollable;
    }
    footer {
        .flex-row;
        .mixin-fixed;
        padding: @padding-default;
        justify-content: flex-end;
        gsp-button {
            margin-right: 5px;
            &:first-of-type {
                margin-right: 0;
            }
        }
    }
}
