.error {
    background-color: @col_red;
    width: 100%;
    color: @col_white;
    font-weight: 300;
    font-size: 1.4rem;
    height: auto;
    text-align: center;
    padding: 5px 0;
}
