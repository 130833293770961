gsp-image-cropper {
    max-width: 700px;

    .browse-again-wrapper {
        display: flex;
        align-content: center;
        justify-content: center;
        margin: @padding-medium;
    }

    .crop-wrapper {
        max-height: 680px;
    }

    img {
        display: initial;
        max-height: 400px !important;
    }
}
