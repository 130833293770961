export-data {
    .export-panel {
        height: 20px;
        recent-exports {
            width: 100%;
        }

        .mat-mdc-tab {
            &-body-wrapper {
                height: 100%;
            }
            &-body-content {
                display: flex;
                overflow: hidden;
            }
            &-group {
                display: flex;
                width: 100%;
            }
            &-label {
                opacity: 1;
            }
        }

        .export-form {
            &-options {
                padding-right: 2rem;
                border-right: 1px solid @col_gray_1;
                height: 515px;
            }

            &-summary-section {
                border-top: 1px solid @col_gray_1;
                border-bottom: 1px solid @col_gray_1;
                padding: 1rem 0;
                margin: 2rem 0;
            }

            &-group {
                min-height: 40px;
                .flex-row-new;

                &-label {
                    .flex4-new;
                    padding: 0 1rem;
                }

                &-input {
                    .flex8-new;
                    padding: 0 1rem;
                }

                gsp-dropdown {
                    .dropdown-container .dropdown-menu .dd-inner-li {
                        padding: 0;
                    }
                }
            }
        }

        .export-count {
            text-align: center;
            font-size: 2rem;
        }
    }

    .recent-exports {
        display: flex;
        height: 535px;

        h4 {
            .text-overflow;
            padding: @padding-tiny;
            padding-right: 15px;
            max-width: 350px;
        }

        .flex7-new {
            padding-top: 0px;
            width: 100%;
        }

        &-message {
            display: flex;
            justify-content: center;
            width: 100%;
            padding: 40px;
        }

        &-item {
            display: flex;
            min-height: 85px;
            margin: 2px;
            border-bottom: 1px solid #e2e2e7;

            &:hover {
                background-color: white;
            }

            &-first-row {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: @padding-tiny;
            }

            &-second-row {
                display: flex;
                gap: 5%;
                flex-wrap: wrap;
                align-items: center;
                padding: 0 @padding-tiny 0;
            }

            &-validuntil-text {
                font-size: 12px;
                text-align: right;
            }

            &-timeanduser {
                //date, time and name of export creator
                .text-overflow;
                flex-grow: 1;
                align-self: flex-end;
                width: 30%;
                font-weight: 400;
                font-size: 13px;
                padding: @padding-tiny;
            }

            &-info {
                //Status, format, features, size fields
                min-width: 130px;
                .complete-status {
                    color: @col_green_dark;
                }
            }
        }

        .left-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 75%;
            padding-left: 3%;
        }

        .right-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            width: 25%;
            padding-right: 3%;
        }
    }
}
