/* For styles related to overall app layout and structure */

// wrapper for app component
.app-wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
    router-outlet + * {
        height: 100%;
        overflow: auto;
    }
}

.map-layout-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.map-frame {
    display: flex;
    position: relative;
    overflow: hidden;
    flex-flow: column wrap;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
    //flex-direction: column-reverse;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.announcement-banner {
    flex: 0 0 auto;
    display: flex;
    height: 60px;
    width: 100%;
    z-index: 11;
    font-weight: 400;
    align-items: center;
    padding: 0 @padding-default 0 @padding-default;
    justify-content: space-between;
    background-color: @col_trimble_blue;
    color: @col_white;
    border-bottom: 1px solid @col_trimble_blue_dark;
    .announcement {
        display: flex;
        .announcement-link {
            width: 150px;
            padding-left: @padding-small;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            align-items: center;
            a {
                color: @col_white;
                text-decoration: underline;
            }
        }
    }
}

.app-top-bar {
    flex: 0 0 auto;
    display: flex;
    height: 44px;
    width: 100%;
    z-index: 13;
    font-weight: 400;
    align-items: center;
    padding: 0 @padding-default 0 @padding-default;
    justify-content: space-between;
    background-color: @col_white;
    .box-shadow(@shadow);
    .partition {
        display: flex;
        justify-content: center;
    }
    .trimble-connect-icon {
        display: flex;
        flex: auto;
        background-repeat: no-repeat;
        width: 182px;
        height: 24px;
    }
    .app-top-bar-icons {
        justify-content: flex-end;
        align-items: center;
    }

    .tab-trigger {
        cursor: pointer;
        padding: @padding-small;

        &.active {
            background-color: @col_trimble_blue_mid;
        }

        &.activity-trigger-icon {
            .trigger-icon {
                .icon-history;
                &.has-update {
                    .icon-history-with-update;
                }
                &.active {
                    .icon-history-white;
                }
            }
        }

        &.notification-tab-trigger-icon {
            .trigger-icon {
                .icon-notification;
                &.has-update {
                    .icon-notification-with-update;
                }
                &.active {
                    .icon-notification-white;
                }
            }
        }

        &.user-tab-trigger-icon {
            .profile-thumbnail-wrapper {
                transition: all 0.1s ease-in-out;

                &:hover {
                    box-shadow: 0 0 0 7px fade(@col_gray_5, 50%);
                    border-radius: 50%;
                    overflow: hidden;
                }
            }

            #user-thumbnail-i24 {
                max-width: 24px;
                max-height: 24px;
                border-radius: 50%;
            }

            .trigger-icon {
                .icon-person;
                &.has-update {
                    .icon-person-with-update;
                }
                &.active {
                    .icon-person-white;
                }
            }
        }
    }

    .back-to-map-viewer {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: @padding-small;
        cursor: pointer;
        h4 {
            font-weight: 400;
        }
        &:hover {
            background-color: @col_gray_light;
        }
    }
    .workspace-details {
        display: flex;
        justify-content: center;
        align-items: center;
        .workspace-name {
            font-weight: 400;
            max-width: 500px;
            .text-overflow;
        }
        .archive-badge {
            cursor: pointer;
            .archive-badge-content {
                height: 16px;
                width: 48px;
                display: flex;
                align-items: center;
                transition: width 0.3s;
                .info-icon {
                    padding-left: @padding-tiny;
                }
                &:hover {
                    width: 68px;
                }
            }
        }
        .filter-badge {
            .filter-badge-content {
                height: 16px;
                display: flex;
                align-items: center;
            }
        }
    }
}

.activity-tab-container {
    display: flex;
    flex: 1 1 auto;
    max-height: 600px;
    width: 400px;
    z-index: 13;
    background-color: @col_white;
    position: absolute;
    right: 10px;
    top: 44px;
    border-radius: 0 0 2px 2px;
    .box-shadow(@shadow);
    .activity-tab-scroll-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    .activity-tab-content {
        padding: @padding-default 0 @padding-default 0;
        .activity-tab-header {
            margin-bottom: @padding-small;
            padding: 0 @padding-default 0 @padding-default;
        }
        .activity-timeline {
            .activity-timeline-header {
                margin: @padding-small 0 @padding-small 0;
                padding: 0 @padding-default 0 @padding-default;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                .activity-timeline-spacer {
                    margin: 0 @padding-small 0 @padding-small;
                    width: 100%;
                }
                .activity-timeline-select-handle {
                    cursor: pointer;
                    font-weight: 400;
                    color: @col_trimble_blue_mid;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .activity-item {
        display: flex;
        align-items: center;
        .activity-wrapper {
            margin: @padding-small 0 @padding-small 0;
            padding: 0 @padding-default 0 @padding-default;
            min-height: 40px;
            display: flex;
            align-items: center;
            .activity-description {
                margin: 0 @padding-small 0 @padding-small;
                max-width: 310px;
            }
        }
        &.active:hover {
            cursor: pointer;
            background-color: @col_gray_1;
            text-decoration: underline;
            color: @col_trimble_blue_mid;
        }
    }
    .activity-loader-wrapper {
        cursor: pointer;

        &:hover {
            background-color: @col_trimble_blue;
            .activity-loader-description {
                color: #fff;
            }
        }

        .activity-loader-description {
            display: flex;
            align-items: center;
            justify-content: center;
            color: @col_trimble_blue;
        }

        .activity-loader {
            display: flex;
            justify-content: center;
            padding: @padding-small 0;
            .activity-loader-icon {
                background-color: @col_trimble_blue;
                padding: 8px;
                border-radius: 50px;
                margin: @padding-small;
            }
            .activity-tab-loading-icon {
                height: 60px;
            }
        }
    }
}

.notification-tab-container {
    display: flex;
    flex: 1 1 auto;
    max-height: 550px;
    width: 400px;
    z-index: 13;
    background-color: @col_white;
    position: absolute;
    right: 10px;
    top: 44px;
    border-radius: 0 0 2px 2px;
    .box-shadow(@shadow);
    .notification-tab-scroll-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }
    .notification-tab-content {
        .notification-tab-item:last-child {
            .spacer {
                display: none;
            }
        }
    }
    .notification-tab-item {
        display: flex;
        flex-direction: column;
        .notification-item-content {
            padding: @padding-default;
            cursor: pointer;
            .notification-item-header {
                margin: @padding-tiny 0 @padding-small 0;
                font-weight: 700;
            }
            .notification-item-description {
                margin-bottom: @padding-small;
            }
            .notification-item-footer {
                font-size: 12px;
                color: @col_gray_5;
                padding-bottom: @padding-tiny;
            }
            &:hover {
                background-color: @col_gray_1;
            }
        }
        .spacer {
            border-bottom: 1px solid @col_gray_2;
        }
    }
    .no-notification-placeholder {
        display: flex;
        justify-content: center;
        align-self: center;
        padding: @padding-small;
    }
}

.forbidden-form-popup-content {
    height: 100%;
    .loading-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .forbidden-form-description {
        margin: @padding-tiny 0px @padding-tiny 0px;
    }
    .spacer {
        margin: @padding-small 0px @padding-small 0px;
        border-bottom: 1px solid @col_gray_2;
    }
    .forbidden-form-section {
        .forbidden-form-section-header {
            font-weight: 700;
            margin: @padding-small 0px @padding-small 0px;
        }
        .forbidden-form-section-description {
            margin: @padding-small 0px @padding-small 0px;
        }
        .forbidden-form-section-actions {
            margin: @padding-small 0px @padding-small 0px;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.user-tab-container {
    display: flex;
    flex: 1 1 auto;
    max-height: 550px;
    width: 232px;
    z-index: 13;
    background-color: @col_white;
    position: absolute;
    right: 10px;
    top: 44px;
    border-radius: 0 0 2px 2px;
    align-content: center;
    .box-shadow(@shadow);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: @padding-small 0 0;

    .spacer {
        border-bottom: 1px solid @col_gray_2;
    }

    .user-tab-scroll-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        .text-ellipsis;
    }

    .user-tab-item {
        display: flex;
        flex-direction: column;
        padding: @padding-small-medium 0;
        .user-item-content {
            padding: 0 @padding-small;

            .user-item-thumbnail {
                display: flex;
                align-items: center;
                justify-content: center;

                #user-thumbnail {
                    max-width: 40px;
                    border-radius: 50%;
                }

                .modus-icons {
                    font-size: 40px;
                }
            }

            .user-item-name {
                margin: @padding-small 0 0 0;
                font-weight: 700;
                font-size: 1.125em;
                .text-ellipsis;
            }

            .user-item-email {
                font-size: 0.75em;
                .text-ellipsis;
            }
        }
    }

    .user-tab-panel-button {
        padding: 0;

        [class^='user-'] {
            cursor: pointer;
            padding: @padding-small-medium;
            color: @col_trimble_blue_mid;
            font-weight: bold;
            &:hover {
                background-color: @col_gray_1;
            }
        }
    }
}

.archive-workspace-info-message {
    height: 150px;
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: @padding-default 0 @padding-default 0;
}

.top-toolbar {
    .over-map;
    position: fixed;
    flex: 0 0 auto;
    display: flex;
    margin-top: 10px;
    max-width: 1000px;
}

.map-canvas {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
    //flex-direction: row-reverse;
}

.map-menu-tabs-container {
    z-index: 10;
    flex: 0 0 auto;
    padding-top: 105px;
    max-height: 210px;
}

.map-menus-container {
    padding-top: 55px;
    padding-bottom: 15px;
    flex: 0 0 auto;
    display: flex;
    flex-flow: column;
    height: 100%;
}

.coord-display {
    .over-map;
    .flex-row;
    background: @col_red;
    height: 32px;
    margin: 0 auto;
    padding: 0 @padding-default;
}

.coord-message {
    color: @col_white;
}

.over-map {
    z-index: 11;
}

.menu-panel {
    z-index: 10;
    margin-top: 5px;
    margin-left: 5px;
    display: flex;
    flex-shrink: 1;
    flex-grow: 0;
    background-color: @col_gray_half;
    .border-radius(@border-radius-value);
    .box-shadow(@shadow);
    max-height: 90%;

    &.open {
        height: 100%;
    }
}

.app-menus {
    display: flex;
    border-left: solid 1px @col_gray_1;
}

.map-menus {
    display: flex;
    flex-direction: column;
    width: @left-panel-width;
}

.vertical-content-block {
    display: flex;
    flex-direction: column;
}

.horizontal-content-block {
    display: flex;
    flex-direction: row;
}

.scrollable-y {
    overflow-y: auto;
}

.inline {
    display: inline-block;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: right;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: fade(@col_trimble_gray, 65%);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 12;
}

body.workspace-loading .overlay {
    background-color: transparent;
}

.full-screen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 400; /* DO - Was 10,000 - and was interfering with browser sync messages */
    background-color: @col_gray_1;
}

error-page:first-of-type + map-container {
    display: none;
}

export-downloader:first-of-type + map-container {
    display: none;
}
