#filter-panel {
    height: 100%;
    width: 210px;

    .mixin-flexi-menu-content;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    height: 100%;

    header {
        .mixin-flexi-menu-header;
        height: 52px;
        padding-top: 11px;
        h2 {
            line-height: 32px;
        }
    }
    .filter-group-container {
        .mixin-flexi-menu-content;
        .filter-group {
            min-height: 48px;
            width: 210px;
            .flex-column;
            .flex(1, 1, auto);

            // all filter accordion
            &.filter-accordion {
                padding-top: 0;
                padding-bottom: 0;

                .radio-label-wrap {
                    display: flex;
                    margin-top: @padding-tiny;

                    > * {
                        cursor: pointer;
                        margin-bottom: 0;
                        margin-top: 8px;
                    }
                }

                .filter-panel-multi-select-choice-field {
                    label,
                    input {
                        cursor: pointer;
                    }
                }

                .filter-accordion-wrapper {
                    display: flex;
                    flex-direction: column;

                    > section {
                        overflow: hidden;
                        transition: all 0.2s ease-in-out;

                        .filter-header {
                            display: flex;
                            align-items: center;

                            .icon_line_chevron_left {
                                position: absolute;
                                right: @padding-small-medium;
                                transition: all 0.2s ease-in-out;
                                rotate: 0deg;
                            }

                            .filter-group-title {
                                padding-top: @padding-small-medium;
                                padding-bottom: @padding-small-medium;
                                padding-left: @default-padding-value;
                                padding-right: @padding-large;
                            }

                            &:hover {
                                background: @col_gray_1;
                                cursor: pointer;
                            }
                        }

                        .filter-contents {
                            height: 0;
                            margin-top: -1px;
                            padding: 0;
                            overflow: hidden;

                            > * {
                                display: inline-block;
                                width: 100%;
                                margin-bottom: @padding-small-medium;
                            }
                        }

                        &.open {
                            max-height: inherit;

                            .icon_line_chevron_left {
                                rotate: -90deg;
                            }

                            .filter-contents {
                                padding: @padding-small @padding-default 0;
                                height: auto;
                            }
                        }

                        &.disabled {
                            > * {
                                color: @col_gray_2;
                            }

                            .icon_line_chevron_left {
                                opacity: 0.2;
                            }

                            .filter-header {
                                cursor: not-allowed;
                                &:hover {
                                    background: transparent;
                                }
                            }
                        }
                    }
                }
            }

            &.modified-by-filter {
                min-height: auto;
            }
            border-bottom: solid 1px @col_gray_1;
            &:last-child {
                border-bottom: solid 1px transparent;
            }
            .filter-group-title {
                font-weight: 600;
                font-size: 14px;
                padding-bottom: 10px;
            }

            .list-item {
                .mixin-list-actions;
                padding-top: 0;
                padding-bottom: 0;
                .test-ellipsis {
                    max-width: 70%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            label {
                .flex(1, 0, 0);
                .text-overflow;
            }

            tag-input {
                .flex-row;
                .mixin-fw;
                width: 80%;
                align-self: center;
                .ng2-tags-container {
                    border: 1px solid @col_gray_1;
                    border-radius: @border-radius-value;
                    box-shadow: none;
                }
                .ng2-tags-container .input {
                    .mixin-input-padding;
                }
                .host {
                    margin: 0;
                    .mixin-fw;
                }

                .custom-tag-data {
                    max-width: 120px;
                }
            }
        }
    }
}
