.folder-selection-component-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    .loading-icon {
        width: 100%;
        display: flex;
        min-height: 305px;
        align-items: center;
        justify-content: center;
    }
    .folder-selection-component {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 320px;
        background-color: @col_white;
        border: 1px solid @col_gray_2;
        .folder-selection-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            border-bottom: 1px solid @col_gray_2;
            .previous-folder-navigation {
                height: 100%;
                display: flex;
                align-items: center;
                &:hover {
                    background-color: @col_gray_light;
                }
                .button-confirm-wrapper {
                    margin-left: 0px;
                }
            }
            .current-folder-name {
                padding: @padding-default;
                padding-left: @padding-small;
                .font-title;
            }
        }
        .folder-selection-body {
            display: flex;
            width: 100%;
            height: calc(100% - 96px);
            .folder-list {
                width: 100%;
                height: 100%;
                .list-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-left: @padding-small;
                    &.selected {
                        background-color: @col_blue_pale !important;
                        color: @col_blue_dark;
                        .font-title;
                    }
                    &:hover {
                        background-color: @col_gray_light;
                    }
                    .folder-icon {
                        background-repeat: no-repeat;
                    }
                    .folder-name {
                        width: 400px;
                        .text-overflow;
                        display: flex;
                        align-items: center;
                    }
                    .folder-link {
                        background-repeat: no-repeat;
                        height: 100%;
                        .button-confirm-wrapper {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
        .folder-selection-footer {
            display: flex;
            height: 48px;
            align-items: center;
            padding: @padding-default;
            padding-left: @padding-small;
            .font-title;
            border-top: 1px solid @col_gray_2;
            .current-folder-indicator {
                padding-left: @padding-small;
                color: @col_gray_6 !important;
                .hint-text;
            }
            .selected-folder-name {
                padding-left: @padding-small;
            }
        }
    }
}
