.task-list {
    max-height: 80vh;
    .ng-scroll-content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    header {
        .mixin-flexi-menu-header;
    }
    .scroll-wrapper {
        height: 74%;
        padding-top: @padding-default;
    }
    .list-content {
        margin-bottom: 20px;
    }
    footer {
        .mixin-flexi-menu-footer;
    }

    .task-search {
        padding: @padding-default @padding-default 0 @padding-default;

        .task-search-bar {
            width: 100%;
            display: flex;
            align-items: center;
            .search-icon {
                padding: calc(@padding-tiny - 1px);
                background-color: @col_trimble_blue_mid;
            }
        }
    }

    .panel-notification {
        padding: 0 @padding-default @padding-tiny @padding-default;
    }

    .task-tabs {
        flex-shrink: 0;
        display: flex;
        border-bottom: 1px solid @col_gray_2;
        margin: 0 20px;

        h3 {
            font-weight: 400;
            font-size: 1.4rem;
        }

        li {
            cursor: pointer;
            padding: 10px @padding-default;
            width: 50%;
            text-align: center;
        }
        .active-tab {
            border-bottom: 5px solid @col_trimble_blue_mid;
            h3 {
                font-weight: 600;
                color: @col_trimble_blue_mid;
            }
        }
    }

    .task-view {
        cursor: pointer;
        &.hide_layer {
            opacity: 0.5;
        }
    }

    .task-loading {
        .task-view {
            position: relative;
        }
        .task-view:before {
            content: '*';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            color: @col_markup;
        }
    }

    .list-content li {
        .mixin-panel-list-item;
        padding-bottom: 5px;
        padding-top: 5px;
        .task-title {
            .flex(1, 1, 0);
            align-self: center;
            text-align: left;
            .text-overflow;
            .heading {
                font-size: 13px;
                font-weight: 600;
                margin: 0;
                color: @col_gray_8;
                word-wrap: break-word;
            }
            .heading,
            .description {
                .text-overflow;
            }
            .description {
                font-size: 13px;
                color: @col_gray_8;
                line-height: 1.3em;
            }
            padding-left: 5px;
            padding-right: 5px;
        }
        .import-error {
            align-self: center;
        }
        .task-count {
            flex: 0 auto;
        }
        .task-edit {
            flex: 0 32px;
            height: 32px;
            i {
                display: block;
                width: 32px;
                height: 32px;
            }
        }
        .task-view {
            flex: 0 24px;
            height: 100%;
            cursor: pointer;
            .i24 {
                margin: 0.4rem;
            }
        }
        .task-more {
            visibility: hidden;
            flex: 0 32px;
        }
        &:hover {
            .task-more {
                visibility: visible;
            }
        }
        &.no-hover {
            text-align: center;
            min-height: 44px;
            justify-content: center;
            &:hover,
            &.selected {
                background-color: transparent;
            }
            loading {
                width: 100%;
            }
        }
    }

    .map-provider {
        li {
            padding: 8px 18px 8px 18px;
        }
    }
    .task-add-icon {
        img {
            display: block;
            margin: auto;
        }
        display: block;
        border-bottom-left-radius: @border-radius-value;
        border-bottom-right-radius: @border-radius-value;
        &:hover {
            background: @col_trimble_blue;
        }
        cursor: pointer;
        padding: @default-padding-value;
    }
    //todo - this should be moved to generic menu component
    .dropdown-menu {
        left: 280px;
        bottom: 0px;
        top: initial;
        min-width: 220px;
        .menu-group {
            border-bottom: solid 1px @col_gray_1;
            h5 {
                padding: 15px 20px 5px 20px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .list-item {
            padding: 10px 20px;
            &:hover {
                cursor: pointer;
                background-color: @col_blue_pale;
            }
            a.list-item-title {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}
