.main-menu {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 12;

    header {
        .mixin-panel-header;

        h2 {
            flex: 1 1 auto;
        }

        .close-button {
            flex-shrink: 0;
            cursor: pointer;
            margin-right: -20px;
        }
    }

    .panel-description {
        padding: @padding-large @padding-large 0 @padding-large;
    }

    .panel-controls {
        display: flex;
        flex-direction: column;
        padding: 0 @padding-large 0 @padding-small;
        justify-content: space-between;

        gsp-text {
            padding: @padding-small @padding-medium;
        }
        input {
            width: 40rem;
        }
    }

    .menu-overlay {
        position: absolute;
        z-index: 12;
        width: 100%;
        height: 100%;
        background-color: fade(@col_trimble_gray, 65%);
    }
}

.main-menu-tabs {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 30;
    background: @col_gray_1;
    width: 80px;
    .content {
        flex: 1;
        padding: 0;
        background: @col_gray_1;
        margin-top: 80px;
        .list-content {
            .list-item {
                width: 80px;
                height: 80px;
                .flex-column;
                justify-content: center;
                align-items: center;
                &:hover,
                &.active {
                    border-top: solid 1px @col_gray_2;
                    border-bottom: solid 1px @col_gray_2;
                    background-color: @col_gray_half;
                }
                .list-item-title {
                    flex: 0 0 auto;
                }
            }
        }
    }
    .connect-logo {
        height: 80px;
    }
    .app-version {
        margin: @padding-small @padding-tiny 0;
        font-size: 0.7em;
    }
    .copyright {
        margin: @padding-tiny @padding-tiny @padding-small;
        font-size: 0.7em;
        text-align: center;
    }
}

.first-panel {
    display: flex;
    flex-direction: column;
    z-index: 30;
    height: 100%;
    background: @col_gray_half;

    width: 641px;
    border-right: 1px solid @col_gray_2;

    > header {
        padding: @padding-large @padding-large 0 @padding-large;
        height: 10%;
        display: flex;
    }

    > positioning {
        display: flex;
        height: 90%;

        .mat-mdc-tab-body-content {
            overflow: hidden;
        }
        rtc-panel {
            height: 100%;
        }
    }

    .second-column {
        margin-left: 20px;
        margin-top: -52px;
        min-width: 320px;
        height: 100vh;
        border-left: 1px solid @col_gray_2;
        padding-top: 10px;
        margin-right: -30px;
    }

    .user-settings-section {
        .flex-row;
        .settings-components {
            .flex-row;
            justify-content: space-between;
            padding: 20px 20px 0 0;
            .settings-dd {
                width: 160px;
            }
        }
    }
}

.second-panel {
    z-index: 30;
    flex: 0 1 auto;
    overflow: hidden;
    background: @col_gray_half;
}

#main-menu {
    position: absolute;
    .transition(all 0.4s);
    width: 100%;
    height: 100%;

    .main-menu-tabs::after {
        opacity: 0;
    }
}

#main-menu.ng-enter {
    .main-menu-tabs {
        .animated(0.4s);
        .slideInLeft;
        z-index: 25;
    }
    .main-menu-tabs::after {
        .animated(0.3s);
        .fadeOut;
        opacity: 0.4;
        position: absolute;
        content: '';
        top: 0;
        width: 2px;
        left: 78px;
        height: 100%;
        background-color: rgba(54, 53, 69, 0.65);
    }
    .first-panel {
        .animated(0.4s);
        .slideInLeft;
        z-index: 20;
    }
}

#main-menu.ng-leave {
    .main-menu-tabs {
        .animated(0.4s);
        box-shadow: 2px 2px 4px rgba(54, 53, 69, 0.65);
        .slideOutLeft;
        z-index: 25;
    }
    .first-panel {
        .animated(0.4s);
        .slideOutLeft;
        z-index: 20;
    }
}

// Make sure this animation time here aligns with MAIN_MENU_ANIMATION_DURATION
.main-menu.ng-leave {
    .main-menu-tabs {
        .animated(0.3s);
        box-shadow: 2px 2px 4px rgba(54, 53, 69, 0.65);
        .slideOutLeft;
        z-index: 25;
    }
    .first-panel {
        .animated(0.3s);
        .slideOutLeft;
        z-index: 20;
    }
}
