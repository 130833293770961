@font-size-base: 14px;
@font-size-large: ceil((@font-size-base * 1.25)); // ~18px
@font-size-small: ceil((@font-size-base * 0.85)); // ~12px

.base_font {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    //color: @col_gray_10;
    //color: @col_trimble_blue;
    line-height: 1.3;
}

h1 {
    font-weight: 300;
    font-size: 3.2rem;
}

h2 {
    color: @col_trimble_blue_mid;
    font-weight: 600;
    font-size: 2rem;
}

h3 {
    font-weight: 300;
    font-size: 1.8rem;
}

h4 {
    font-weight: 300;
    font-size: 1.6rem;
}

h5 {
    font-weight: 300;
    font-size: 1.4rem;
}

h6 {
    font-weight: 300;
    font-size: 1.2rem;
}

.menu-heading,
.menu-sub-heading {
    color: @col_trimble_blue;
}

.menu-heading {
    font-weight: 600;
    font-size: 1.6rem;
}
.menu-sub-heading {
    font-weight: 400;
    font-size: 1.4rem;
}
.font-title {
    font-weight: 600;
}

.field-label {
    font-weight: 300;
    font-size: 1.4rem;
    margin-bottom: 0; //Bootstrap override
}

.hint-text {
    font-weight: 400;
    font-size: 11px;
    color: @col_gray_8;
}

.hint-text-dark {
    .hint-text;
    color: @col_black;
}

.section-label {
    font-weight: 500;
    color: @col_gray_8;
}
