.export-downloader {
    text-align: center;
    background-color: @col_gray_light;
    height: 100vmax;
    .content {
        padding-top: 35vh;
        h2 {
            font-weight: 400;
            color: @col_trimble_gray;
        }
    }

    .download-text {
        .default-link;
        color: @col_trimble_blue_mid;
    }
}
