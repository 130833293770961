//Variables
@small-max : ~"screen and (max-width: 40em)";
@medium-max : ~"screen and (max-width: 70em)";
@large-max : ~"screen and (max-width: 80em)";
@tablet : ~"screen and (max-width: 50em)";

// Mixin
.responsive(@media-query; @rules) {
    @media only @media-query {
        @rules();
    }
}

.responsiveflex(@media-width; @rules) {
    @media only screen and (max-width: @media-width) {
        @rules();
    }
}
.responsivemax(@max-width; @rules) {
    @media only screen and (max-width: @max-width) {
        @rules();
    }
}

.responsivemin(@min-width; @rules) {
    @media only screen and (min-width: @min-width) {
        @rules();
    }
}
