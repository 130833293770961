#page-template-editor {
    .gsp-tabs {
        .flex-column;
        .justify-start;
        .align-center;
        cursor: pointer;
        background-color: @col_gray_0;
        font-weight: 300;
        border-right: solid 1px @col_gray_1;

        section {
            div {
                .flex-column;
                .align-center;
                padding: 13px 0;
                overflow: hidden;
                cursor: pointer;
                font-size: 12px;
                flex: 1 1 auto;
                min-width: 64px;

                &.active {
                    background-color: @col_trimble_blue_mid;
                    color: @col_white;
                }
            }
        }
    }
}
