.map-draw-action-tooltip {
    background: rgb(54, 54, 54);
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid transparent;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #fff;
    font: 12px/18px 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin-left: 20px;
    margin-top: -15px;
    padding: 4px 8px;
    position: absolute;
    white-space: nowrap;
    z-index: 1000;
}

.map-draw-action-tooltip:before {
    border-right: 6px solid black;
    border-right-color: rgba(0, 0, 0, 0.5);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    content: '';
    position: absolute;
    top: 7px;
    left: -7px;
}

.map-draw-action-rectangle-vertex {
    display: 'none';
}
