// Paths
@image-path: '/assets/images/';
@templateEditorPath: '..//assets/images/templateEditor';

/* Sytling Mixins for TE Fields */

.mixin-field-panel {
    background-color: transparent;
    border: 1px solid @col_gray_1;
    /* sets borders */
    border-left-color: transparent;
    /* keeps width of border, hides display */
    border-right-color: transparent;

    /* keeps width of border, hides display */
    .active {
        .form-element {
            z-index: 2;
            background-color: @col_white;
            .mixin-shadow-large;
        }

        .field-header-update

    /* This contains the updation options for visibility and required status */ {
            background-color: @col_white;
            background: -webkit-linear-gradient(right, #fff 80%, #f0f0f0);
            background: -ms-linear-gradient(right, #fff 80%, #f0f0f0);
            background: -moz-linear-gradient(right, #fff 80%, #f0f0f0);
            /* TODO This needs a rearrange in HTML? */
        }

        &.update-options-icons {
            box-shadow: none;
        }

        li {
            border-left: none;
        }

        .editor-field {
            background-color: transparent;
        }

        .contextSpecific {
            padding: @padding-small 25px;
            margin: 0;
        }
    }
}

/* Page Styling / Layout */

#page-template-editor {
    > * {
        z-index: 5;
    }

    main {
        .flex-column;
        height: 100%;
        background: @col_gray_light;
    }

    /* Overrides custom scroll top shadow on Template editor header */
    .scroll-wrapper.scroll-up::before {
        background-image: none;
    }

    .master-form {
        composer-header {
            display: flex;
        }
        .flex-column;
        flex: 1 1 auto;
        overflow: hidden;

        > *:not(section) > header {
            display: flex;
            .align-center;
        }
        > *:not(section) {
            position: relative;
            flex-flow: row nowrap;
            height: 75px;
            -ms-preferred-size: @header-height;
            .align-center;
            .justify-between;
            align-self: center;
            width: 100%;
            max-width: 800px;
        }

        > * > header {
            width: 100%;
            .input-text {
                .mixin-input-common();
                .text-overflow;
                height: 4rem;
                font-size: 2rem;
                font-weight: 600;
                padding-left: @padding-default;
                color: @col_gray_9;
            }

            .template-title-text {
                font-size: 2rem;
                font-weight: 600;
                padding-left: @padding-default;
                .text-overflow;
            }

            > div {
                .flex(1, 0, auto);

                &:last-child {
                    text-align: right;
                }

                &:first-child {
                    width: 35%;
                }
            }

            &:after {
                content: ' ';
                background: url(../../../../assets/images/shadow.png) no-repeat;
                height: 14px;
                width: 970px;
                display: block;
                position: absolute;
                bottom: -14px;
                left: 50%;
                margin-left: -485px;
                z-index: 3;
            }
        }
    }

    .fields-group {
        .flex-row;
        flex-wrap: wrap;
        .justify-content(space-between);

        .field-icon {
            .flex-box;
            .center;
            width: 48px;
            height: 100%;
            flex: 0 0 auto;
        }

        .field-name {
            flex: 1 1 auto;
            word-wrap: break-word;
            overflow: hidden;
            margin-right: 8px;
            h5 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .contextSpecific {
        padding: 0 @padding-medium @padding-default;
        margin: 0 -30px;

        &.accuracy {
            .flex-row;
        }

        &.update {
            margin-bottom: 10px;
        }

        .updateOptions {
            .flex-row;
        }

        .contextAccuracyInfo {
            margin-left: 20px;
            width: 215px;
        }
    }

    .updateOptions {
        > div {
            .flex-row;
        }
    }

    .update-options-icons {
        flex-flow: row wrap;
        display: inline-flex;
        justify-content: center;
        box-shadow: none;
        align-items: center;

        &.rules-tab {
            width: 64px;
        }

        .field-required,
        .field-read-only,
        .field-sitevision-visible {
            width: 32px;
            height: 32px;
            .flex-row;
            justify-content: center;
            align-items: center;
            &.inactive {
                opacity: 0.5;
                width: 40px;
                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    /* First level control group is the drag and drop list for the entire template editor */
    .control-group {
        .mixin-field-panel;
        .list-unstyled;
        .flex-column;
        padding: @padding-medium;
        max-width: 800px;
        list-style: none;
        margin: 40px auto 150px auto;
        background-color: @col_gray_half;
        /*adds color to the background of the main page*/
        border-color: @col_gray_1;
        /*overrides the mixin field panel left and right border colors*/
        border-radius: @border-radius-value;

        //min-width: 600px;
        /*sets a minimum width on the parent control gorup */
        > li.drag-subject {
            display: none;
        }

        > li,
        > * > li {
            .flex-box;
            position: relative;
            margin-top: -1px;
            border: 1px solid @col_gray_1;
            border-left-color: @col_gray_half;
            border-right-color: @col_gray_half;
            //background-color: @col_blue_light;
            /* pulls in panel styling */
        }

        > li:not(:first-child) > .drop-element {
            .flex-row;
        }

        .active .drop-element {
            background: transparent;
        }

        /*This makes the drop element in a group smaller after the first item is added*/
        .control-group {
            border-bottom: none;

            /*overrides the border in a group to avoid 2px*/
            li:last-child {
                border-bottom: none;
            }

            /*.drop-element{
            border:none;
        }*/
            > li:not(:first-child) > .drop-element {
                min-height: 32px;
                padding: 15px;

                &.tfx-top-target,
                &.tfx-bottom-target {
                    min-height: 64px;
                    padding: 0px;
                }
            }
        }

        /* Styles the group element drag and drop list */
        /* second level control group is the drag and drop list within the group elements */
        li {
            .control-group {
                padding: 0;
                margin: 0;
                border-left: none;
                border-right: none;
                min-width: 500px;
                /*overrides the minimum width on the parent control gorup */
                background-color: transparent;

                field-item {
                    padding-left: @default-padding-value;
                }

                .drop-element-placeholder {
                    padding: 0 0 0 @default-padding-value;
                    background-clip: content-box;
                }

                /*> li{
            &.active{
              background-color: @col_white;
            }
          }
          > li:first-of-type {
           // border-top: none;
          }*/
            }

            &.active {
                border-color: @col_gray_1;

                /*overrides the mixin field panel left and right border colors*/
                .control-group {
                    background-color: inherit;
                }
            }
        }
    }

    .templateFields li {
        .mixin-full-width;
        height: inherit;
        position: relative;
        display: flex;
        flex-flow: row nowrap;
    }

    .templateFields .control-group-icons {
        z-index: 1;
    }

    /* ############################## */
    /* Warning message & Status indicator common to rules and other tabs  */
    .rule-warning-container {
        .mixin-rule-error;
        margin: 0 -30px;

        .rule-warning {
            max-width: 800px;
            list-style: none;
            margin: 0 auto 0 auto;

            p {
                margin: 0;
                display: inline;
            }

            i {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .status-indicator {
        .flex-column;
        height: 64px;
        align-items: flex-start;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;

        .rule-condition,
        .rule-target {
            width: 32px;
            margin-left: 0;
            flex: 1;
        }

        .rule-condition {
            border-left: solid 3px @col_green_dark;

            > div {
                position: absolute;
                left: -24px;
                z-index: 1;
                background-color: @col_green_dark;
            }
        }

        .rule-target {
            border-left: solid 3px @col_blue_dark;

            > div {
                position: absolute;
                left: -24px;
                z-index: 1;
                background-color: @col_blue_dark;
            }
        }

        &.has-condition-errors {
            .rule-condition {
                border-left: solid 3px @col_red_dark;

                > div {
                    background-color: @col_red_dark;
                }
            }
        }

        &.has-target-errors {
            .rule-target {
                border-left: solid 3px @col_red_dark;
            }
        }
    }

    /* ############################## */
}

/* ############################## */

/* DROP TARGET DESIGN */

/* ############################## */

.tfx-bottom-target.drop-element,
.tfx-top-target.drop-element {
    padding: 0px;
    background-color: @col_blue_light;

    span {
        display: none;
    }
}

.drop-element {
    padding: @padding-default;
    min-height: 64px;
    text-align: center;
    .mixin-field-panel;
    .mixin-full-width;
    /*border-color:transparent;  overriding the borders from the panel design */
    border: none;
    background: @col_gray_half;
}

.tfx-top-target {
    position: relative;
    padding-top: 64px;
    background-color: @col_blue_light;
}

.tfx-bottom-target {
    position: relative;
    padding-bottom: 64px;
    background-color: @col_blue_light;
}

/* ############# cdkDragAndDrop styles ############# */
.drop-element-placeholder {
    padding: @padding-default;
    min-height: 64px;
    text-align: center;
    .mixin-field-panel;
    .mixin-full-width;
    background-color: @col_blue_light;
}

.cdk-drag {
    cursor: move;
    &.cdk-drag-disabled {
        cursor: auto;
    }
}

.cdk-drag-preview {
    background-color: @col_white;
    display: flex;
    justify-content: space-around;
    .mixin-shadow-medium;
    cursor: copy;
    position: absolute;
    z-index: 10000;
    opacity: 0.5;
    max-height: 65px;
    overflow: hidden;
}

.styles-panel .drop-element-placeholder {
    height: 0;
    width: 0;
    border: 0;
    background-color: @col_gray_light;
}

.styles-panel {
    .cdk-drop-list {
        min-height: 0px !important;
        .cdk-drag.cdk-drag-disabled {
            opacity: 0.5;
        }
    }
}

.cdk-drop-list {
    min-height: 0px !important;
}

field-group {
    .cdk-drag-placeholder {
        opacity: 0.5;
    }
}

/* ------------- cdkDragAndDrop styles ------------- */

// to handle field item drag preview
body > field-item {
    form,
    ng-form {
        width: 100%;
        li {
            width: 100%;
            list-style: none;
            .editor-field {
                -webkit-box-flex: 1;
                flex: 1 1 auto;
                display: -webkit-box;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                flex-flow: column nowrap;
                background-color: #f9f9fb;
                border-bottom: 1px solid #e2e2e7;
                &.tfx-group {
                    // to prevent staggered box shadow on group fields on drag preview
                    width: 100%;
                }
                .page-divider {
                    // to prevent page-divider spacer on drag preview
                    display: none !important;
                }
                .field-header-inputs {
                    display: flex;
                    height: 64px;
                    padding-right: @padding-default;
                    .field-status {
                        width: 24px;
                    }
                    field-icon {
                        display: flex;
                        .field-icon {
                            display: flex;
                            align-items: center;
                        }
                    }
                    field-name {
                        display: flex;
                        align-items: center;
                        margin-left: 20px;
                    }
                }
                .editor-field-section {
                    margin-left: 20px;
                }
            }
        }
    }
}

// Added for composer header jump off screen issue on adding new fields continuously to trigger scroll
.field-list-container {
    height: calc(100% - 75px) !important;
}

// style for disabled fields
.disabled-field {
    opacity: 0.5;
    &.disabled-field-radio {
        pointer-events: none;
    }
}

// Added for layout alignments in the repeat fields property section
.properties-edit {
    .repeatField {
        .hint-text {
            margin-left: 24px;
        }
    }

    .incrementRepeatField {
        margin-left: 15px;
        width: 25%;
        float: left;
    }

    .incrementRepeatValue {
        width: 40%;
    }
}

// Styles for popups in template editor
.locked-schema-popup {
    .modal-popup {
        z-index: 201;
    }
    p {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    ul {
        margin-bottom: 2rem;
        li {
            margin-left: 2rem;
            list-style-type: disc;
        }
    }
}
