.gsp-alert {
    padding: 15px;
    margin-bottom: 1px;
    border: 1px solid transparent;
    border-radius: 0px;
    opacity: 0.9;
    //  .h5;
    //  .b;
}

/* ngAnimate automatically adds/removes these classes in Angular 1.2 + */
.gsp-alert.ng-enter,
.gsp-alert.ng-leave {
    -webkit-transition: 0.35s linear all;
    transition: 0.35s linear all;
}

.gsp-alert.ng-enter,
.gsp-alert.ng-leave.ng-leave-active {
    opacity: 0;
}
.gsp-alert.ng-leave,
.gsp-alert.ng-enter.ng-enter-active {
    opacity: 1;
}

// THINK WE CAN REMOVE THIS
/*
.alert-success {
  color: @col_trimble_gray;
  background-color: #AEEC94;
  border-color: #86C74F;
}
.alert-warning{
  color: @col_trimble_gray;
  background-color: #FDA7A7;
  border-color: #C5818C;
}
.alert-info{
  color: @col_trimble_gray;
  background-color: #FFF09E;
  border-color: #E2CB9C;
}

.alert-close {
  cursor: pointer;
  float: right;
  color: @col_black;
  opacity: .7;
  filter: alpha(opacity=20);
}*/
