.task-panel {
    .mixin-side-panel;

    &.todo-edit-mode {
        .task-label + textarea,
        .task-label + tag-input {
            margin-top: 5px;
        }
    }

    /*.edit-options{
        padding:0 @padding-default;
        position:fixed;
        right:0;
        top:55px;
    }*/

    .button-confirm-wrapper {
        .confirm-box {
            /*right:initial;
            left: -4px;*/
            top: 0px;
            display: flex;
            flex-direction: row;

            .button-cancel {
                margin-left: 0px;
            }

            .button-danger {
                margin-left: 4px;
            }
        }
    }

    .task-content {
        padding: 0 @padding-medium;

        .error-message {
            .mixin-error-message;
        }

        .todo-status {
            .flex-row;
            align-items: flex-start;

            .todo-status-value {
                line-height: 1.2em;
                margin-left: 10px;

                span {
                    font-weight: 600;
                }
            }
        }
    }

    .task-data {
        color: @col_trimble_blue_mid;
        .text-overflow;
    }

    .task-description-scroll .ng-scroll-content {
        max-height: 150px;

        .task-data {
            white-space: pre-line;
            overflow-wrap: break-word;
        }
    }

    .task-assignees {
        margin-top: 10px;

        li {
            .flex-row;
            margin-bottom: 10px;

            > div {
                &.icon {
                    flex: 0 0 28px;
                }

                &.name {
                    flex: 1 1 auto;
                    font-weight: 600;
                    .text-overflow;
                }

                &.status {
                    flex: 0 0 auto;
                }
            }
        }
    }

    header {
        padding-bottom: @padding-default;
    }

    footer {
        div {
            width: 100%;
            .flex-row;
            justify-content: space-between;
        }
    }

    > section {
        .flex-column;

        .input-group {
            .mixin-fw;
            .flex-column;
            margin-bottom: @padding-default;

            &:last-of-type {
                padding-bottom: 0;
            }

            label {
                .flex-row;
                .mixin-fw;
                .field-label;
            }

            input,
            gsp-dropdown,
            tag-input {
                .flex-row;
                .mixin-fw;
            }

            .dropdown-menu {
                bottom: inherit;
            }

            tag-input {
                border: 1px solid @col_gray_1;
                border-radius: @border-radius-value;
                box-shadow: none;

                .tags .input {
                    .mixin-input-padding;
                }

                .host {
                    margin: 0;
                    .mixin-fw;
                }
            }
        }
    }

    .layers-list {
        .flex-column;

        .has-no-layer {
            opacity: 0.5;
        }

        > li {
            .flex-row;
            .mixin-fw;
            padding-top: @padding-small;

            svg-icon,
            .layer-edit {
                flex: 0 auto;
            }

            .layer-title {
                .flex(3, 0, 0);
                .text-overflow;
                max-width: 100%;
                padding-right: 5px;

                .layer-removed {
                    font-weight: bold;
                }
            }

            .feature-count {
                width: 48px;
                height: 28px;
                border-radius: 50%;
                color: @col_white;
                display: inline-block;
                line-height: 28px;
                text-align: center;
            }
        }

        .button-confirm-wrapper {
            margin-left: 0px !important;
        }
    }

    tag-input {
        background-color: @col_white !important;

        .ng2-tag-input {
            width: 100% !important;

            tag-input-form {
                width: 100% !important;
            }
        }
    }

    .error-notification {
        border-left: 5px solid @col_red;
        background: @col_red_pale;
        margin-bottom: @padding-default;
        p {
            .hint-text;
            color: @col_black;
            padding: @padding-small;
        }
    }

    .task-notification {
        padding: @padding-default;
    }
}
