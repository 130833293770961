/* Mixins for Active colors of Tabs, and buttons */
/* LIGHT BLUE */
.mixin-active-colors-pale {
    background-color: transparent;

    &:hover {
        background-color: @col_gray_light;
    }

    &.active,
    &:active,
    &:focus {
        background-color: @col_blue_pale;
    }
}

.mixin-active-colors-transparent {
    background-color: transparent;

    &:hover {
        background-color: @col_gray_light;
    }

    &.active,
    &:active,
    &:focus {
        background-color: transparent;
    }
}

/* DARK BLUE */
.mixin-active-colors-strong {
    &:hover {
        background-color: @col_gray_light;
    }

    &.active,
  &:active

  /*, TODO commenting this out for now
  &:focus*/
 {
        background-color: @col_trimble_blue_mid;
    }
}

// ERROR MESSAGES
.mixin-error-common {
    padding: @padding-default;
    border-width: 1px;
    border-style: solid;
    border-radius: @border-radius-value;
}

.mixin-information {
    background-color: fade(@col_blue, 20%);
    border-color: @col_blue;
    .mixin-error-common;
}

.mixin-success {
    background-color: fade(@col_green, 20%);
    border-color: @col_green;
    .mixin-error-common;
}

.mixin-warning {
    background-color: fade(@col_orange, 20%);
    border-color: @col_orange;
    .mixin-error-common;
}

.mixin-critical {
    background-color: fade(@col_red, 20%);
    border-color: @col_red;
    .mixin-error-common;
}

.mixin-rule-error {
    background-color: @col_red;
    color: @col_white;
    padding: 10px;
}

//  THESE ARE THE FLEX BOX MIXINS FOR FLEXIBLE HEADERS

.mixin-flexi-menu-header {
    display: flex;
    .mixin-content-box;
    padding-top: @padding-default;

    h2 {
        flex: 1 1 auto;
        align-self: center;
    }

    button {
        flex-shrink: 0;
    }
}

.mixin-flexi-menu-header,
.mixin-flexi-menu-footer {
    flex-shrink: 0;
}

.mixin-flexi-menu-content {
    .flex(1, 1, auto);
    overflow-y: hidden;
}

.mixin-content-box {
    padding: 0 @padding-default;
}

/* Some existing Mixins */

.mixin-list-actions {
    padding: 7px 20px;
    margin: 0 -20px;
    height: 32px;
    .mixin-active-colors-pale;

    &.fade {
        opacity: 0.5;
    }
}

.mixin-icon-button-actions {
    &:hover {
        background-color: @col_gray_light;
    }

    &.active,
    &:focus,
    &.selected {
        background-color: @col_gray_1;
    }
}

.transform(@args) {
    -webkit-transform: @args;
    -moz-transform: @args;
    -ms-transform: @args;
    -o-transform: @args;
    transform: @args;
}

.border-radius(@args: @border-radius-value) {
    -webkit-border-radius: @args;
    border-radius: @args;

    background-clip: padding-box;
}

/* Defines the states for the color changes etc... should be the same as general button states so could possibly be merged into there */
.mixin-tab-states {
    background-color: @col_gray_1;
    border: 1px solid @col_gray_3;

    &.selected,
    &:active,
    &:focus {
        background-color: @col_blue;
    }

    &:hover {
        background-color: @col_blue_light;
    }

    &.disabled {
        opacity: 0.5;
    }
}

.mixin-tabs(@size: 4rem) {
    .mixin-tab-states;
    width: @size;
    height: @size;
}

.mixin-panel-header {
    .flex-row;
    align-items: center;
    justify-content: space-between;
}

.mixin-panel-list-item {
    .flex-row;
    cursor: pointer;
    padding: 0 @default-padding-value;

    &:hover {
        cursor: pointer;
        background-color: @col_gray_1;
    }
}

/* ################################################# */
/* Text Input */
/* ################################################# */

.mixin-input-common {
    color: @col_trimble_gray;
    padding-left: 12px;
    padding-right: 12px;
    .fnt_os_med;

    &:placeholder {
        font-style: italic;
    }

    &:focus {
        color: @col_trimble_blue_mid; // TODO probably remove this junk
        font-weight: 500; // TODO probably remove this junk
        background-color: @col_white;
    }
}

.mixin-invisible-input(@vheight: 32px) {
    .mixin-input-common;
    border: none;
    width: 100%;
    height: @vheight;
    line-height: @vheight;
    height: @vheight;
    background-color: transparent;
}

.mixin-invisible-input-border(@map-toolbar-height) {
    .mixin-invisible-input(@map-toolbar-height);

    &:focus {
        border: solid 1px @col_gray_1;
    }
}

.mixin-edit-panel-form {
    input {
        width: 100%;
    }

    label {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 8px 0 8px 0px;
    }
}

/* ################################################# */
/* Shadows */
/* ################################################# */

.mixin-shadow-none {
    box-shadow: none;
}

.mixin-shadow-small {
    box-shadow: 1px 1px 2px fade(@col_trimble_gray, 65%);
}

.mixin-shadow-medium {
    box-shadow: 1px 1px 5px fade(@col_trimble_gray, 50%);
}

.mixin-shadow-large {
    box-shadow: 1px 1px 10px fade(@col_trimble_gray, 35%);
}

/* ################################################# */
/* Block styles */
/* ################################################# */

/*The mixin block light, is a style designed to work on TILES, and other elements that are lighter than their background surroundings, but which contain other possible states*/
.mixin-block-light {
    background-color: @col_gray_light;
    border-color: @col_gray_0;
    .mixin-shadow-small;

    &.hover,
    &:hover {
        .mixin-shadow-medium;
    }

    &.active,
    &.selected,
    &:active {
        .mixin-shadow-large;
    }
}

/* this mixin defines the general block styles that sit on top of the map background as floating pabels */
.mixin-floating-panel {
    background-color: @col_gray_half;
    .border-radius(@border-radius-value);
    .mixin-shadow-small;
}

.mixin-floating-panel-dark {
    background-color: @col_gray_light;
    .border-radius(@border-radius-value);
    .mixin-shadow-small;
}

.mixin-floating-panel-light {
    background-color: @col_white;
    .border-radius(@border-radius-value);
    .mixin-shadow-small;
}

.mixin-popup-panel {
    background-color: @col_gray_half;
    .border-radius(@border-radius-value);
    .mixin-shadow-large;
}

.mixin-feature-list {
    &:hover,
    &.selected {
        background-color: @col_gray_half;
    }
}

.mixin-gradient-shadow-top {
    background-image: linear-gradient(to bottom, fade(@col_trimble_gray, 15%) 0%, fade(@col_trimble_gray, 0%) 100%);
}

.mixin-gradient-shadow-bottom {
    background-image: linear-gradient(to top, fade(@col_trimble_gray, 15%) 0%, fade(@col_trimble_gray, 0%) 100%);
}

.mixin-gradient-shadow-right {
    background-image: linear-gradient(to right, fade(@col_trimble_gray, 15%) 0%, fade(@col_trimble_gray, 0%) 100%);
}

.mixin-custom-scroll {
    flex: 1 1 auto;
    overflow: hidden;
}

.mixin-context-specific {
    background: @col_yellow_pale;
    border-top: solid 1px @col_yellow_dark;
    border-bottom: solid 1px @col_yellow_dark;
    //color:@col_white;
}

.mixin-message-default {
    .mixin-context-specific;
    padding: @padding-default @padding-medium;
    margin: 0;
}

.mixin-input-padding {
    padding-left: 10px;
    padding-right: 10px;
}

.mixin-menu-padding {
    padding-top: @menu-padding;
    /*create a mixin for this */
    padding-bottom: @menu-padding;
}

@input-width-default: 100%;
@input-width-small: 60px;
@input-width-medium: 140px;
@input-width-large: 250px;

.mixin-input-width {
    &.default {
        width: @input-width-default;
    }

    &.small {
        width: @input-width-small;
    }

    &.medium {
        width: @input-width-medium;
    }

    &.large {
        width: @input-width-large;
    }
}

.mixin-dropdown-width {
    width: 100%;

    &.small {
        width: 25%;
    }

    &.medium {
        width: 50%;
    }

    &.large {
        width: 100%;
    }
}

.mixin-placeholder-size(@args) {
    &::-webkit-input-placeholder {
        font-size: @args;
    }

    &:-moz-placeholder {
        font-size: @args;
    }

    &::-moz-placeholder {
        font-size: @args;
    }

    &:-ms-input-placeholder {
        font-size: @args;
    }
}

.mixin-tip {
    padding: 20px 0;
    border-top: solid 1px @border-color;
}
