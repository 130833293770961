/* Sytling Mixins for TE Rules */

#page-template-editor {
    &.rule {
        .rules-panel {
            padding: @padding-medium;
            .sidebar-rule {
                border: 1px solid @col_gray_2;
                //border-right: 1px solid @col_gray_2;
                font-size: 1.4rem;
                position: relative;
                background-color: @col_gray_half;
                margin-bottom: @padding-default;
                .border-radius(@border-radius-value);

                &.selected {
                    background-color: @col_white;
                    .mixin-shadow-large;
                    .condition {
                        border-right: solid 3px @col_green_dark;
                    }
                    .action {
                        border-right: solid 3px @col_blue_dark;
                    }
                    .trash-rule {
                        visibility: visible;
                    }

                    &.has-target-errors {
                        .action {
                            border-right: solid 3px @col_red_dark;
                            .action-icon {
                                .icon_line_error_target_24;
                                .i24;
                            }
                        }
                    }
                }

                header {
                    background-color: @col_gray_1;
                    .flex-row;
                    justify-content: space-between;
                }

                &:hover {
                    background-color: @col_white;
                    .mixin-shadow-medium;
                }

                &.has-condition-errors {
                    .condition {
                        border-right: solid 3px @col_red_dark;
                    }
                    .icon-32-color-condition {
                        .icon_line_error_condition_24;
                        .i24;
                    }
                }
                .condition,
                .action,
                .rule-error {
                    padding: 5px 5px 5px 15px;
                }
                .rule-error {
                    background: @col_red;
                    color: @col_white;
                }

                .condition,
                .action {
                    position: relative;
                    padding-right: @padding-medium;
                    .rule-condition-icon,
                    .rule-target-icon {
                        position: absolute;
                        right: 0;
                    }
                    .rule-condition-icon {
                        bottom: 0;
                    }
                    .rule-target-icon {
                        top: 0;
                    }
                }

                .condition {
                    //border-right: solid 3px @col_green_dark;
                    .rule-title {
                        font-weight: 400;
                        font-size: 1.6rem;
                        .flex-row;
                        justify-content: space-between;
                        height: 32px;
                    }
                    .value {
                        word-wrap: break-word;
                    }
                    .placeholder {
                        color: @col_green_dark;
                    }
                    .rule-info {
                        padding-top: 0;
                        padding-bottom: 0;
                        min-height: 32px;
                    }
                }
                .action {
                    //border-right: solid 3px @col_blue_dark;
                    .placeholder {
                        color: @col_blue_dark;
                    }
                }
                .rule-info {
                    .flex-row;
                    .justify-content(space-between);
                    padding: 10px 0;
                    div,
                    li {
                        .text-overflow;
                    }
                }
            }
            .trash-rule {
                position: absolute;
                right: 5px;
                top: 5px;
                .icon_line_cancel_24;
                .i24;
                visibility: hidden;
                z-index: 1;
            }
            .new-rule-button {
                .flex-row;
                padding-bottom: @padding-medium;
                justify-content: flex-end;
            }
        }

        .editorPanel {
            .control-group {
                > * > * > li,
                > * > * > li:first-of-type {
                    .active {
                        /*overrides field mixin to get rid of the active styling for the rules context only */
                        .mixin-shadow-none;
                        //background-color:@col_gray_half;
                        border-right-color: transparent;
                        border-left-color: transparent;
                    }
                    .highlight-condition {
                        background-color: @col_green_pale;
                    }
                    .highlight-target {
                        li {
                            border-left: transparent;
                            .editor-field,
                            .drop-element {
                                background-color: @col_blue_pale;
                            }
                        }
                        background-color: @col_blue_pale;
                        &.pageheader {
                            background-color: transparent;
                            .form-element {
                                background-color: @col_blue_pale;
                            }
                        }
                    }
                    .group-error {
                        background-color: @col_red_pale;
                    }
                    &.group {
                        .field-header-update {
                            position: absolute;
                            right: 1px;
                        }
                    }
                    // .has-condition {
                    //     i {
                    //         .icon_line_white_condition_24;
                    //         .i24;
                    //     }
                    // }

                    &.text {
                        .editor-field-row {
                            align-items: flex-start;
                        }
                    }

                    .editor-field-header {
                        padding-right: 0;
                    }

                    /* Code to hide rule buttons until hovered, or until the field is clicked */

                    .rule-action {
                        opacity: 0.1;
                    }
                    :hover .rule-action,
                    .highlight-target .rule-action,
                    .highlight-condition .rule-action,
                    .active .rule-action {
                        opacity: 1;
                    }

                    .editor-field .editor-field-header .field-header-options .field-header-inputs .field-actions {
                        display: flex;
                        flex-flow: row nowrap;
                        flex-shrink: 0;
                        height: 100%;
                        justify-content: flex-end;
                    }

                    .status-indicator {
                        .flex-column;
                        height: 64px;
                        align-items: flex-start;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        .rule-condition,
                        .rule-target {
                            width: 32px;
                            margin-left: 0;
                            flex: 1;
                        }
                        .rule-condition {
                            border-left: solid 3px @col_green_dark;
                            > div {
                                position: absolute;
                                left: -24px;
                                z-index: 1;
                                background-color: @col_green_dark;
                            }
                        }
                        .rule-target {
                            border-left: solid 3px @col_blue_dark;
                            > div {
                                position: absolute;
                                left: -24px;
                                z-index: 1;
                                background-color: @col_blue_dark;
                            }
                        }

                        &.has-condition-errors {
                            .rule-condition {
                                border-left: solid 3px @col_red_dark;
                                > div {
                                    background-color: @col_red_dark;
                                }
                            }
                        }

                        &.has-target-errors {
                            .rule-target {
                                border-left: solid 3px @col_red_dark;
                                > div {
                                    background-color: @col_red_dark;
                                }
                            }
                        }
                    }
                    .active {
                        .form-element {
                            background-color: transparent;
                            .mixin-shadow-none;
                        }
                    }
                }
            }
        }
    }

    .has-target-errors {
        .action {
            border-right: solid 3px @col_red_dark;
            .action-icon {
                .icon_line_error_target_24;
                .i24;
            }
        }
        .icon-32-color-target {
            .icon_line_error_target_24;
            .i24;
        }
    }
    .rule-target .target-icon,
    .sidebar-rule .action-icon {
        display: block;
        .icon-32-color-target;
    }
}

.icon-32-color-condition {
    .icon_line_condition_24;
    .i24;
}

.icon-32-color-target {
    .icon_line_target_24;
    .i24;
}
