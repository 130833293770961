// This is new and maintainable attempt at button styling

/* Common reusable Button Effects and styles to be utilized by our other buttons*/

// MIXINS - ONLY USE THESE IN LESS
// --------------------------------------------------
@button-radius-value: 2px;

//Button Basics
.btn-icon {
    background-color: transparent;
    border-radius: @button-radius-value;
    padding: 4px;
    border: none;

    &:hover,
    &:active,
    &:focus,
    &.active {
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-plain {
    background: none;
    border: none;
}

.button-confirm-wrapper,
.button-icon-text ~ .button-icon-text {
    margin-left: 5px;
}

/*
.btn-default {
  background: @col_markup;
  border-color: @col_markup;
  border-radius: @button-radius-value;
  &:hover, &:active, &:focus{
    background: @col_white;
    outline: none !important;
    border-color: @col_gray_1;
  }
}

.btn-primary{
  color: @col_white;
  background-color: @col_trimble_blue_mid;
  border-color: @col_trimble_blue;
  &:hover, &:focus, &:active,.active{
    color: @col_white !important;
    background-color: @col_trimble_blue !important;
    border-color: @col_trimble_blue !important;
  }
}
*/

.mixin-button-disabled {
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
}

.mixin-button() {
    font-size: 1.4rem;
    cursor: pointer;
    border-radius: @button-radius-value;

    &:active,
    &.active {
        box-shadow: none;
    }

    &.disabled {
        .mixin-button-disabled;
    }
}

.mixin-button-large {
    font-size: 1.6rem;
}

// Button Colours
// --------------------------------------------------
// Primary
.mixin-button-effect-blue {
    background-color: @col_blue;
    color: @col_white;
    border: 1px solid @col_blue_dark;

    &:hover {
        background-color: @col_blue_light;
        color: @col_white;
    }

    &:focus {
        background-color: @col_blue_dark;
        color: @col_white;
    }

    &:disabled {
        .mixin-button-disabled;
    }
}

// Success
.mixin-button-effect-green {
    background-color: @col_green;
    color: @col_white;
    border: 1px solid @col_green_dark;

    &:hover {
        background-color: @col_green_light;
        color: @col_white;
    }

    &:focus {
        background-color: @col_green_dark;
        color: @col_white;
    }

    &:disabled {
        .mixin-button-disabled;
    }
}

// Danger
.mixin-button-effect-red {
    background-color: @col_red_dark;
    color: @col_white;
    border: 1px solid @col_red_dark;

    &:hover {
        background-color: @col_red;
        color: @col_white;
    }

    &:focus {
        background-color: @col_red_dark;
        color: @col_white;
    }

    &:disabled {
        .mixin-button-disabled;
    }
}

// Default Gray
.mixin-button-effect-default {
    background-color: @col_gray_1;
    color: @col_trimble_gray;
    border: 1px solid @col_gray_2;

    &:hover {
        background-color: @col_gray_0;
        color: @col_trimble_gray;
    }

    &:focus {
        background-color: @col_gray_2;
        color: @col_trimble_gray;
        outline: 0;
    }

    &:disabled {
        .mixin-button-disabled;
    }
}

.mixin-button-effect-yellow {
    background-color: @col_yellow;
    color: @col_trimble_gray;
    border: 1px solid @col_yellow_dark;

    &:hover {
        background-color: @col_yellow_light;
        color: @col_trimble_gray;
    }

    &:focus {
        background-color: @col_yellow_dark;
        color: @col_trimble_gray;
        outline: 0;
    }

    &:disabled {
        .mixin-button-disabled;
    }
}

// BUTTON COMPONENTS
// --------------------------------------------------
.mixin-button-text {
    align-items: center;
}

.mixin-button-icon {
    &.disabled {
        .mixin-button-disabled;

        &:hover {
            background-color: transparent;
        }
    }

    i {
        display: block;
        margin: 0;
        float: left;
    }
}

// BUTTON TYPES
// OPTIONAL Use a button content class to define special styling for the button
// --------------------------------------------------
.mixin-button-rounded(@height) {
    border-radius: @height / 2;

    .button-text {
        font-size: 1.8rem;
        /* this should be split out into some size classes */
    }

    i {
        margin: 4px;
    }
}

.mixin-button-states {
    &.button-default {
        .mixin-button-effect-default;
    }

    &.button-danger {
        .mixin-button-effect-red;
    }

    &.button-primary {
        .mixin-button-effect-blue;
    }

    &.button-success {
        .mixin-button-effect-green;
    }

    &.button-done {
        .mixin-button-effect-yellow;
    }

    &.button-plain {
        .mixin-active-colors-pale;
        border: solid 1px transparent;
    }
}

// CLASSES - USE THESE IN CODE
// CLASSES - USE THESE IN CODE
// CLASSES - USE THESE IN CODE
// Button Content
// REQUIRED Use a button content class to define what content is to be displayed in the button
// --------------------------------------------------
.button {
    //.btn;
    .mixin-button();
    .mixin-button-text;
    .mixin-button-states;
}

.mixin-fab-edit {
    width: 36px;
    height: 36px;
    .border-radius(50%);
    background: fade(@col_gray_half, 85%);
}

.button-icon {
    .btn;
    /* bootstrap */
    .btn-icon;
    .mixin-button-icon;

    &.fab-button {
        .mixin-fab-edit;
    }
}

.button-icon-text {
    // .btn; /* bootstrap */
    .flex-row-reverse;
    .mixin-fixed;
    .mixin-button();
    .mixin-button-icon;
    .mixin-button-text;
    .mixin-button-states;
    padding: 5px 20px;

    span {
        float: left;
    }
}

/* double confirmation button with 3 targettable sub buttons*/

.button-confirm-wrapper {
    display: inline-block;
    position: relative;

    .confirm-box {
        position: absolute;
        width: auto;
        white-space: nowrap;
        right: 0;
        top: -6px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        background-color: @col_gray_3;
        padding: 8px 4px;
        z-index: 9;

        & + .button:disabled {
            .mixin-button-disabled;
            opacity: 1;
        }

        &.ng-hide + .button:disabled {
            .mixin-button-disabled;
        }

        .button {
            padding: 4px 20px;
        }
    }

    .button + .button {
        margin-left: 4px;
    }
}

// override to push confirm buttons right in side-panels to avoid them being clipped under the left edge
.mixin-double-confirm-align-right {
    .flexbox;
    .flex-direction(row-reverse);
    right: auto;
    left: -4px;

    .button-cancel {
        margin-right: 4px;
        margin-left: 0px;
    }
}

.sidebar {
    .button-confirm-wrapper {
        width: 100%;
    }

    .confirm-box {
        .mixin-double-confirm-align-right;
    }
}

/* show-busy styles */

.action-button.in-progress,
.button.in-progress {
    opacity: 0.5;
    position: relative;

    i,
    .button-text,
    .confirm-text {
        opacity: 0;
    }

    &:before {
        display: block;
        width: 40px;
        height: 16px;
        content: '';
        position: absolute;
        left: 50%;
        top: 8px;
        background-image: url('../assets/images/loading-mini.gif');
        margin-left: -20px;
    }

    &.button-icon:before {
        background-image: url('../assets/images/loading-micro.gif');
        margin-left: -12px;
        width: 24px;
    }
}

.button:not(.in-progress) {
    opacity: 1;
}

.default-link {
    border: 0;
    padding: 0;
    font-size: 100%;
    font-family: inherit;
    text-decoration: underline;
    cursor: pointer;

    &-dark {
        color: @col_trimble_blue_mid;
        background-color: @col_gray_half;
    }

    &-light {
        color: @col_blue_light;
        background-color: transparent;

        &:hover,
        &:focus {
            color: @col_blue_light;
        }
    }

    &:disabled {
        text-decoration: none;
        color: inherit;
    }
}

.button-text-action {
    cursor: pointer;
    font-weight: 600;
    color: @col_trimble_blue_pale;
    span {
        font-size: 14px;
        font-weight: 600;
        color: @col_trimble_blue_pale;
        &:hover {
            text-decoration: underline;
        }
    }
}
