img {
    display: block;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-weight: 500;
}

@gutter-pad: 15px;

.mar-lr-4 {
    margin: 0 4px;
}

.pad-lr-4 {
    padding: 0 4px;
}

.user-select-none {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#map,
.map-wrapper,
.map-wrapper .leaflet-container {
    width: 100%;
    height: calc(100% - 44px); // account for app-top-bar height
    background: @col_gray_2 url(../assets/images/map_bg.png);
    position: fixed;
    top: 44px; // account for app-top-bar height
    left: 0px;

    .leaflet-control-attribution.leaflet-control {
        font-size: 1rem;
    }
}

.leaflet-google-layer {
    background-color: transparent !important;
}

.leaflet-draw-tooltip-subtext {
    display: none;

    & + br {
        display: none;
    }
}

#nav-menu {
    margin-top: 50px;
    height: 300px;
}

body.download .map-wrapper {
    display: none;
}

.leaflet-google-layer ~ .leaflet-map-pane:not(.leaflet-zoom-anim) .leaflet-tile-pane {
    opacity: 0;
}
