.gsp-toggle-container {
    .toggle-btn {
        display: inline-block;
        outline: 0;
        min-width: 2.5em;
        height: 1.25em;
        position: relative;
        cursor: pointer;
        user-select: none;
        background: @col_gray_6;
        border-radius: 1.25em;
        padding: 0.5px;
        margin-top: 1.5px;
        transition: all 0.4s ease;
        border: 1px solid @col_trimble_blue_mid;

        &::after {
            left: 0;
            position: relative;
            display: block;
            content: '';
            width: 50%;
            height: 100%;
            border-radius: 1.25em;
            background: @col_white;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
        }

        &:disabled {
            .mixin-button-disabled;
        }
    }

    .toggle-btn.toggle-btn-on {
        &::after {
            left: 50%;
        }
        background: @col_trimble_blue_mid;

        &:active {
            box-shadow: none;
        }
        &:active::after {
            margin-left: -0.8em;
        }
    }

    .toggle-btn.toggle-btn-off {
        border: 1px solid @col_gray_6;
    }
    .toggle-btn:active::after {
        padding-right: 0.8em;
    }

    .toggle-input {
        display: none;
    }

    label {
        margin-left: @padding-small;
    }
}

.toggle-workspace-image {
    margin: 15px 0;
    display: block;
}

.toggle-post-processing .toggle-btn {
    z-index: 1;
}
