.tooltip-content {
    color: #ffffff;
    max-width: 250px;
    border-radius: 2px;
    background: rgba(54, 53, 69, 0.9);
    word-wrap: break-word;
    padding: 5px 10px 5px 10px;
    text-align: center;
    display: none;
    &.show {
        .animated(0.3s);
        .fadeIn;
        position: fixed;
        display: block;
        z-index: 1100; // needs to be changed. Problem because of the dropdown z-index which is 1000
    }
}
.tooltip-content.show.top::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(54, 53, 69, 0.9) transparent transparent transparent;
}

.tooltip-content.show.bottom::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(54, 53, 69, 0.9) transparent;
}

.tooltip-content.left::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(54, 53, 69, 0.9);
}

.tooltip-content.right::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(54, 53, 69, 0.9) transparent transparent;
}
