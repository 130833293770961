@options-heading-margin: 5px;

#page-template-editor {
    label {
        .field-label;
    }

    h4 {
        /* TODO this should be standardised against a global style please */
        color: @col_trimble_blue_mid;
        font-weight: 600;
        margin-bottom: @options-heading-margin;
    }

    .preview-wrapper {
        width: 80%;
        margin-left: @padding-small;

        .summary-dropdown {
            display: block;
        }
    }

    .preview-field {
        position: relative;
        .flex-row;
        .justify-between;
        .align-center;

        .summary-dropdown {
            background: transparent;
            margin-left: -10px;
            width: 113%;

            .toggle-button {
                border: none;
                height: 1.8rem;

                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
        }

        .image-dropdown-container {
            .dropdown-container {
                background: transparent;
            }

            position: absolute;
            right: 0;
            width: 70px;
            height: 70px;
            z-index: 1;
            border: none;
            background: none;
            color: @col_white;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;

            option {
                color: @col_black;
            }

            .toggle-button {
                width: 70px;
                height: 70px;

                &:hover,
                &:active {
                    text-decoration: none;
                }

                .toggle-text {
                    color: @col_gray_2;
                    padding-top: 35px;
                    padding-left: 0;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: inline-block;
                    text-align: center;
                    font-size: 11px;
                }

                i {
                    display: none;
                }

                .tfx-arrow-down {
                    display: none;
                }
            }
        }
    }

    #summary-preview-tile {
        width: 100%;
        overflow: visible;
        /*cursor: pointer;*/
        border: solid 1px @col_gray_1;
        background-color: @col_white;

        .toggle-button {
            background: transparent;
            border: none;
            overflow: hidden;
        }
    }

    .summary-preview-tile-label {
        .text-overflow;
        line-height: 1.4;
    }

    #summary-preview-tile .field-data {
        .flex-row;
        .align-start;
        padding: @padding-tiny;
        // border:solid 1px @col_gray_1;
    }

    #summary-preview-tile .thumbnailFieldIdContainer select:focus {
        outline: 0;
    }

    #summary-preview-tile .dropdown-container.thumbnailFieldIdContainer {
        padding-top: 0;
    }

    #summary-preview-tile .thumbnail {
        background: url(../../../../assets/images/templateEditor/photo-placeholder-64-64.png) no-repeat center -4px @col_trimble_gray;
        display: block;
        float: right;
        text-align: center;
        vertical-align: middle;
        position: relative;
        height: 70px;
        width: 70px;
        color: @col_white;
    }

    [draggable] {
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        user-select: none;
        /* Required to make elements draggable in old WebKit */
        -khtml-user-drag: element;
        -webkit-user-drag: element;
        cursor: move;
    }

    /* Why is this styles panel being used by both the template tab, and the fields tab... need a better name*/

    .styles-panel {
        padding: @padding-medium;

        .styles-section {
            margin-bottom: @padding-medium;

            &.advanced {
                h4,
                i {
                    display: inline-block;
                }

                i {
                    cursor: pointer;
                    vertical-align: sub;
                }
            }

            .linked-layer {
                .flex-row;
                align-items: flex-start;
                margin-bottom: @padding-default;
            }

            .layer-desc {
                margin-left: @padding-small;
                .text-overflow;
            }

            .layer-name {
                .text-overflow;
                font-weight: 600;
            }

            .workspace-name {
                .text-overflow;
            }

            .sub-heading {
                font-weight: 600;
            }

            > label {
                margin-bottom: @padding-small;
                font-size: 16px;
                color: @col_trimble_blue_mid;
                font-weight: 400;
            }

            .styles-item-group {
                .flex-row;
                .justify-content(space-between);
                margin-bottom: @padding-small;

                .style-item-row {
                    .flex-row;
                    width: 100%;
                    height: 32px;

                    .radio-label-wrap {
                        display: flex;
                    }
                }

                .type-value {
                    width: 145px;

                    .toggle-button {
                        padding-left: 0;
                        padding-right: 0;
                    }

                    .option-label {
                        padding-left: 0;
                    }
                }

                gsp-checkbox {
                    width: 100%;
                }

                .tag-container {
                    width: 145px;
                }

                label {
                    .checkbox2 {
                        margin-right: @padding-small;
                    }
                }

                #templatename.ng-invalid {
                    border: 1px solid @col_red;
                }
                &.flex-column {
                    .flex-column;
                }
            }

            .min-accuracy {
                gsp-checkbox {
                    margin-left: -5px;
                }
            }

            .dropdown-menu {
                bottom: inherit;
            }
        }
    }
    .number-positions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        #positions-value {
            width: 55px;
            right: 0;
        }
    }
    .logging-rate {
        label {
            .flex-row;
            align-items: center;
            flex: 9;
        }
        &-time {
            .gsp-form-input {
                width: 85px;
                margin-right: @padding-tiny;
            }
        }
        &-distance {
            .gsp-form-input {
                width: 55px;
            }

            #logging-rate-distance-unit {
                width: 85px;
                margin-left: @padding-tiny;
                .toggle-text {
                    padding-left: @padding-tiny;
                }
                .dropdown-menu {
                    min-width: 85px;
                }
            }

            .toggle-button {
                padding: 0;
            }
        }
    }
}
