/* ################ */
/* This page defines the style of the fields as they sit in the left hand column of the tabs/ panels prior to them being dragged in*/
/* ################ */

#page-template-editor {
    .field-tile {
        background-color: @col_gray_half;
        border: solid 1px @col_gray_1;
        width: 135px;
        margin-bottom: 10px;
        height: 50px;
        .center;
        &:hover,
        &.active {
            background-color: @col_white;
            .mixin-shadow-medium;
        }
        & ul {
            padding-left: 0;
        }
        & li {
            list-style: none;
        }

        &.cdk-drag-disabled {
            &:hover,
            &:active {
                background-color: none;
                box-shadow: none;
            }
        }
    }
    .expanded {
        .header-ct {
            background-color: @col_white;
        }
    }

    .field-tile {
        .flex-row;
        .justify-content(space-between);
    }

    .field-list-group {
        .flex-row;
        .justify-content(space-between);
        margin-bottom: 10px;
        .field-list-action-wrapper {
            display: flex;
            align-items: center;
        }
        .show-more {
            padding-left: @padding-small;
        }
    }
}
