workspace-panel {
    display: flex;
    flex-flow: column nowrap;

    header {
        .dropdown-menu {
            bottom: inherit;
        }
    }

    .context-menu {
        box-shadow: none;
        padding: 0;
    }
}

.workspaces {
    .mixin-custom-scroll;

    .workspace-list {
        .flex-box;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: @padding-large @padding-large @padding-default;

        .workspace-list-component {
            width: 100%;
            .flex-box;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        .archived-workspaces-heading {
            width: 100%;
            display: flex;
            align-items: center;
            margin: @padding-default 0 @padding-default 0;
            h2 {
                font-size: 16px;
                font-weight: 600;
            }
        }

        .workspace-list-item {
            flex: 0 0 auto;
            -webkit-flex: 0 0 auto;
            width: 265px;
            margin-bottom: @padding-default;
            cursor: pointer;
            position: relative;
            .box-shadow(@shadow);
            background-color: @col_white;

            &.deleted {
                opacity: 0.3;
            }

            .tile-details {
                height: 70px;
                box-sizing: border-box;

                .workspace-list-title {
                    font-weight: 600;
                    font-size: 16px;
                    width: 200px;
                    .text-overflow;
                }

                .workspace-list-desc {
                    .text-overflow;
                    width: 200px;
                    font-size: 12px;
                }
            }

            .workspace-thumbnail {
                .flex-box;
                width: 265px;
                height: 125px;
                background-color: @col_gray_0;
                justify-content: center;
                align-items: center;
            }

            .workspace-select {
                width: 73px;
                height: 85px;
                position: absolute;
                top: -38px;
                right: -32px;
                display: block;
                transform: rotate(135deg);
            }

            .workspace-select-wrapper {
                width: 100%;
                height: 125px;
                position: absolute;
                overflow: hidden;
            }

            .workspace-archived-badge {
                position: absolute;
                top: 98px;
            }

            &.active {
                .box-shadow(@shadow);

                .workspace-select {
                    background-color: @col_trimble_blue_mid;
                }

                .selected-tick {
                    .selected-tick-class;
                }

                &:hover {
                    .workspace-select {
                        background-color: @col_trimble_blue_mid;
                    }
                }
            }

            &:hover {
                .box-shadow(@shadow);

                .workspace-select {
                    background-color: @col_gray_3;
                }

                .selected-tick {
                    .selected-tick-class;
                }
            }

            .wrap-edit-workspace {
                width: 50px;
                height: 50px;
                margin: 1rem;
                position: absolute;
                background-color: @col_white;
                opacity: 0.6;
                border-radius: 40px;
                top: 0;
                right: 0;
                padding: 10px;

                &:hover {
                    background-color: @col_white;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }

        .workspace-list-message {
            display: flex;
            justify-content: center;
            width: 100%;
        }
    }
}

.workspace-edit-form {
    &-group {
        &-input {
            margin-top: 1rem;
            textarea {
                .gsp-form-input;
                min-height: 145px;
                padding: 10px;
                .mixin-fw;
            }
        }
    }
}

.workspace-area {
    display: flex;
    .sub-heading {
        padding-bottom: @padding-default;
        padding-top: @padding-small;
    }
    .workspace-area-body {
        width: @wizard-workspace-body-width-ratio;
        .workspace-mini-map {
            #search-tool {
                position: absolute;
                z-index: 2;
                width: 70%;
                top: 17.5%;
                left: 1.5%;
            }
        }
    }
    #workspace-mini-map {
        height: 300px;
        width: 100%;
    }
    .hint-text {
        width: @wizard-hint-text-width-ratio;
        margin-left: @padding-default;
    }
}

.workspace-details-panel {
    .workspace-details {
        display: flex;
        h4 {
            padding-bottom: @padding-small;
        }
        .thumbnail,
        .coordinate-system-details {
            width: 50%;
        }
    }
}

#workspace-details-popup {
    .thumbnail {
        .button-switches {
            max-width: 265px;
            margin-top: @padding-small;

            gsp-button {
                .button-confirm-wrapper,
                button {
                    margin: 0;
                    width: 100%;
                }

                button {
                    flex-flow: column;
                }
            }

            gsp-toggle {
                &.disabled {
                    opacity: 0.5;
                }

                .gsp-toggle-container {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                }
                label,
                .toggle-button {
                    margin: auto 0;
                }
            }
        }
    }

    .workspace-mini-map-container {
        .workspace-mini-map {
            #search-tool {
                position: absolute;
                z-index: 2;
                width: 15%;
                margin: @padding-small;
            }
        }
        .button-row {
            display: flex;
            flex-direction: row-reverse;
            .button-confirm-wrapper {
                margin-top: @padding-small;
            }
        }
    }

    #workspace-mini-map {
        height: 250px;
        width: 100%;
    }
}

.workspace-duplication-details,
.workspace-creation-name,
.workspace-edit-panel {
    height: 100%;
    width: 100%;
    custom-scroll {
        width: 24%;
    }
    .align-start;
    h4 {
        padding-bottom: @padding-small;
    }
    .duplicate-workspace {
        &-details {
            min-width: 66%;
            padding-bottom: 0;
            label {
                white-space: normal;
            }
        }
        &-coordinate-system {
            padding-bottom: 0;
        }
    }
}

.workspace-coordinate-system-details {
    height: 100%;
    padding-bottom: 0;

    loading {
        width: 100%;
    }

    h4 {
        padding-bottom: @padding-small;
    }
    .details {
        padding-left: @padding-default;
    }

    .title {
        font-size: 14px;
        font-weight: 400;
    }

    .group {
        display: flex;
        .item {
            overflow-wrap: break-word;
            padding-right: @padding-medium;
            width: 60%;
        }
        .unit {
            width: 40%;
        }
    }

    .height-selection-container {
        width: 70%;
        padding-bottom: 15px;
    }

    .aboveSeaLevel {
        display: flex;
        i {
            margin-left: -10px;
        }

        p {
            line-height: 32px;
        }
    }

    .section,
    .group {
        padding-bottom: @padding-small;
    }
}

.workspace-coordinate-system {
    display: flex;

    loading {
        .loading-small {
            margin-top: 15vh;
        }
    }

    h4 {
        padding: @padding-small 0 @padding-default 0;
    }

    .coordinate-system {
        width: @wizard-workspace-body-width-ratio;
        .selection {
            display: flex;
            width: 100%;
            padding-top: @padding-small;
            align-items: center;
            .label {
                width: 20%;
                .text-overflow;
            }
            .input-container {
                width: 80%;
                padding-left: @padding-small;
            }
            &:last-of-type {
                margin-bottom: @padding-small;
            }
        }

        .selection.multi-selection {
            .input-container {
                display: flex;
                gsp-dropdown:nth-of-type(odd) {
                    width: 60%;
                }

                .secondary-selection-title {
                    display: flex;
                    align-items: center;
                    padding: 0 @padding-small;
                    width: 10%;
                }
            }
        }

        // Hack to fix the issue with dropdown items getting hidden when the list is large
        .selection.zonegroup {
            .dropdown-container.open {
                .dropdown-menu {
                    ul {
                        max-height: 190px;
                    }
                }
            }
        }
        .selection.zone {
            .dropdown-container.open {
                .dropdown-menu {
                    ul {
                        max-height: 150px;
                    }
                }
            }
        }
        .selection.datum {
            .dropdown-container.open {
                .dropdown-menu {
                    ul {
                        max-height: 110px;
                    }
                }
            }
        }
        .selection.geoid {
            .dropdown-container.open {
                .dropdown-menu {
                    ul {
                        max-height: 170px;
                    }
                }
            }
        }

        .selection.datum.no-projection-datum {
            .dropdown-container.open {
                .dropdown-menu {
                    ul {
                        max-height: 70px;
                    }
                }
            }
        }
        .selection.geoid.no-projection-geoid {
            .dropdown-container.open {
                .dropdown-menu {
                    ul {
                        max-height: 130px;
                    }
                }
            }
        }
    }
    // --------------------------------------------------------------------------------

    .hint-text {
        width: @wizard-hint-text-width-ratio;
        margin-left: @padding-default;
        padding-top: @padding-small;
    }

    .notification {
        margin-top: @padding-small;
    }
}

.workspace-template-options {
    .section-heading {
        padding: @padding-small 0 @padding-small 0;
    }
    .section-description {
        padding: @padding-small 0 @padding-default 0;
    }
    .template-option {
        padding: @padding-small 0 @padding-default 0;
        display: flex;
        .option-control {
            margin-top: 1px;
            .radio-label-wrap {
                display: flex;
                align-items: center;
                label {
                    margin-bottom: 0;
                }
            }
        }
        .option-description {
            flex: 10;
            .description {
                font-weight: 400;
                font-size: 13px;
                color: @col_gray_7;
                margin-left: 24px;
            }
        }
        .option-icon {
            flex: 1;
            margin: 0 @padding-default 0 @padding-default;
            padding: @padding-small @padding-default @padding-small @padding-default;
        }
    }
}

.workspace-archive {
    padding: @padding-default 0 @padding-default 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .archive-decription {
        padding-bottom: @padding-default;
    }
    .workspace-move-option {
        flex: 1;
    }
    .archive-workspace-move-wrapper {
        flex: 7;
        display: flex;
        .archive-icon {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0.2;
        }
    }
}

workspace-share {
    #workspace-share-wizard {
        h5 {
            font-weight: 600;
        }
    }
    .workspace-share-info {
        display: flex;
        flex-direction: column;
        .loading-share {
            margin-top: -10vh;
        }
        .workspace-share-lock {
            .text-overflow {
                font-weight: 600;
            }
        }
    }
    .share-workspace-description {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
    }
    .share-workspace-instructions {
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        li {
            margin-left: 2rem;
            list-style-type: disc;
        }
    }
}

.workspace-context-menu-item {
    display: flex;
    align-items: center;
    i {
        margin-right: @padding-tiny;
    }
}
