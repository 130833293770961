.input-field-label {
    margin-bottom: 20px;
    h3 {
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 14px;
    }
}
.form-control {
    height: 32px;
}
.fnt_os_med {
    font-family: 'Open Sans';
    font-weight: 500;
    font-size: 1.4rem;
}

//INPUT
.default-input {
    .mixin-input-padding;
    background-color: @col_white;
    vertical-align: middle;
    height: 32px;
    .fnt_os_med;
    color: @col_trimble_gray;
}

.gsp-input {
    .default-input;
    width: 100%;
    .transition(all 0.25s linear);
    //border-left:4px solid white;
    //border-right:4px solid white;
    border-top: 0px;
    border-bottom: 0px;
    &:focus {
        border-left-color: @col_trimble_blue_mid;
        border-right-color: @col_trimble_blue_mid;
    }
    &.pointer {
        cursor: pointer !important;
    }
    -webkit-appearance: none !important;
}

.gsp-input.ng-invalid.ng-touched {
    border-left-color: @col_orange !important;
    border-right-color: @col_orange !important;
}

.gsp-inplace-edit-icon {
    font-size: 20px;
    width: 32px;
    text-align: center;
    &:hover {
        background-color: white;
    }
}

.gsp-form-input {
    .default-input;
    height: 32px;
    width: 250px;
    border: 1px solid @col_gray_1;
    .mixin-input-width;
    &.ng-invalid-field-required,
    &.ng-invalid-required {
        border: 1px solid @col_red;
    }
}

.gsp-form-input[disabled='disabled'] {
    display: none;
}
.gsp-form-input[disabled='disabled'] + .disabled-txt {
    display: block;
}
.gsp-form-input.disabled-txt {
    opacity: 0.5;
    background-color: @col_gray_1;
}
.input-small {
    height: 32px;
    width: 150px;
}
.input-xs {
    height: 32px;
    width: 50px;
}
.date-picker-wrapper {
    position: relative;
    display: inline-block;
    width: auto;
}
.date-picker-picker {
    position: absolute;
    top: 0;
    left: 155px;
    z-index: 99999;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type='number'] {
    -moz-appearance: textfield;
}

.search {
    > .gsp-form-input {
        display: block;
    }
    > .gsp-form-input[disabled='disabled'] {
        cursor: not-allowed;
    }
}

gsp-text-area {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;

    textarea {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        resize: vertical;
    }
}

.no-resize {
    resize: none;
}

.password-wrapper {
    .gsp-form-input;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 @padding-small 0 0;
    input {
        padding: 0 @padding-small 0 @padding-small;
        width: 100%;
        border: 0px;
        min-height: 0px;
    }
    .visibility-toggle {
        opacity: 0.7;
    }
}
