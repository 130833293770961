.time-picker-wrapper {
    .flex-box;
    gap: 10px;
    align-items: center;
    .time-selection {
        width: 100px;
    }
    .period-selection {
        width: 100px;
    }
    .time-zone-selection {
        flex: 1;
    }
}
