.existing-layers {
    header {
        .mixin-flexi-menu-header;
        padding-bottom: @padding-default;
    }
    .search {
        padding: 0 20px;
    }
    .datum-filter-info {
        padding: @padding-small @padding-default @padding-small @padding-default;
    }
    .spacer {
        padding: @padding-small;
    }
    footer {
        padding: @padding-default;
        .footer-action-buttons {
            .flex-row;
            justify-content: space-between;
        }

        .chkbox-div {
            margin-left: 5px;
        }
    }
}
.add-layer-list {
    &.content {
        padding: 0 0 @padding-small 0;
        h3 {
            padding: 0 20px;
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 14px;
        }
        .ng-scroll-content {
            display: block;
        }
        .list-content {
            ng-scrollbar {
                height: 100%;
            }
            > li {
                display: -webkit-flex;
                display: flex;
                -webkit-flex-flow: row nowrap;
                flex-flow: row nowrap;
                padding: 10px @padding-default;
                cursor: default;
                .layer-sym {
                    -webkit-flex: 0 32px;
                    flex: 0 32px;
                    margin-right: 10px;
                    align-self: flex-start;
                }
                .layer-title {
                    -webkit-flex: 1;
                    flex: 1;
                    align-self: center;
                    text-align: left;
                    .text-overflow;
                    font-weight: 600;
                    .relationship {
                        font-weight: normal;
                        font-size: 13px;
                        .text-overflow;
                    }
                }
                .layer-edit {
                    -webkit-flex: 0 32px;
                    flex: 0 32px;
                    align-self: center;
                }
                &.selected {
                    background-color: @col_blue_pale;
                }
                &.no-hover {
                    &:hover,
                    &.selected {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

.layer-library-info-toast {
    bottom: 100px;
    right: 15px;
    margin-right: 0px !important;
}

.link-template-popup {
    .linking-template-header {
        font-size: 1.6rem;
        font-weight: 600;
    }
    .linking-template-process {
        margin-bottom: 5px;
        .linking-template-status {
            width: 100%;
            margin: 5px 0 5px 0;
            display: flex;
            justify-content: space-between;
            .status {
                display: flex;
                margin: 0 10px 0 10px;
                align-items: center;
                span {
                    font-style: italic;
                    font-weight: 300;
                    font-size: 1.2rem;
                    line-height: 1.3;
                }
            }
        }
    }
    .link-template-error {
        margin-top: 5px;
        height: 40px;
        .error-message {
            font-size: 1.3rem;
        }
    }
}

.existing_template_panel {
    .existing-layers {
        .search {
            margin: @padding-small @padding-default;
            margin-top: 0px;
            padding: 0px;
            display: flex;
            align-content: center;
            align-items: center;
            border: 1px solid #e2e2e7;
            input {
                display: inline-block;
                width: 90%;
                border: 0px;
            }
            .filter-icon {
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 32px;
                width: 32px;
                &.active {
                    background-color: @col_trimble_blue_mid;
                }
            }
        }
    }
}
.search-filter-item {
    label {
        margin: 0px;
    }
}
