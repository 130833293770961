.layer-list {
    max-height: 100%;

    header {
        .mixin-flexi-menu-header;
        padding-top: 18px;
    }

    .ng-scroll-content {
        width: 100%;
    }

    .layer-list-content {
        .mixin-flexi-menu-content;
        overflow: hidden;
    }

    .map-provider li,
    .map-cache-list .list-item.no-hover {
        /*special case*/
        min-height: 50px;
    }

    .map-provider,
    .map-cache-list,
    .layers-list,
    .upload-status {
        border-bottom: @border-style;
    }

    .layer-list-content ul,
    .map-cache-list ul,
    .map-provider ul,
    .upload-status {
        padding-top: @padding-small;
        padding-bottom: @padding-small;
    }

    footer {
        .mixin-flexi-menu-footer;
    }

    .add-menu {
        .list-content {
            position: relative;

            .data-tip {
                position: absolute;
                left: 15px;
                bottom: -40px;
            }
        }
    }

    .layer-view {
        cursor: pointer;

        &.hide_layer {
            opacity: 0.5;
        }
    }

    .layer-loading {
        opacity: 0.5;

        .layer-view {
            position: relative;
        }
    }

    .layer-pending-icon {
        margin-left: 4px;
        margin-right: 4px;
    }

    li {
        .mixin-panel-list-item;
        position: relative;

        &.no-hover {
            text-align: center;
            min-height: 50px;
            justify-content: center;

            &:hover,
            &.selected {
                background-color: transparent;
            }
        }

        &:hover {
            .layer-edit {
                background: @col_blue;
                color: white;
                .border-radius(@border-radius-value);

                .template-icon {
                    .icon_line_white_template_24;
                    .i24;
                }

                .template-draft-icon {
                    .icon_line_white_template_draft_24;
                    .i24;
                }

                .draft-icon {
                    .icon_line_white_draft_template_24;
                    .i24;
                }

                .template-add-icon {
                    .icon_line_white_template_24;
                    .i24;
                }

                div {
                    .flex-column;
                    .justify-center;
                    padding-left: 10px;
                }
            }
        }

        .layer-geom {
            position: relative;

            .assim-donut {
                width: 32px;
                height: 32px;
                justify-content: center;
                .flex-row;

                path {
                    animation: assimilate 1.5s ease-in infinite;
                }
            }

            .badge {
                position: absolute;
                right: 24px;
                top: 5px;
            }
        }

        .layer-title {
            .flex(1, 1, 0);
            align-self: center;
            text-align: left;
            .text-overflow;
            padding: 5px;

            .layer-name {
                .text-overflow;
            }

            .layer-status {
                font-weight: 600;
                font-size: 10px;

                > .linked-icon {
                    display: inline-block;
                    vertical-align: bottom;
                }
            }
        }

        .import-error {
            align-self: center;
        }

        .layer-edit {
            flex: 0 auto;
            .flex-box;
            height: 32px;
            align-items: center;

            div {
                display: none;
            }

            .template-icon {
                .icon_line_template_24;
                .i24;
            }

            .template-draft-icon {
                .icon_line_template_draft_24;
                .i24;
            }

            .draft-icon {
                .icon_line_draft_template_24;
                .i24;
            }
        }

        .layer-view {
            .flex(0, 0, 24px);
            height: 100%;
        }

        .layer-more {
            visibility: hidden;
            flex: 0 32px;
        }

        &:hover {
            .layer-more {
                visibility: visible;
            }
        }

        &.locked {
            .lock-template {
                position: absolute;
                left: 40px;
                top: 8px;
                .i16;
                .icon_solid_lock_32;
            }
        }
    }

    .layer-add-icon {
        justify-content: center;
        border-bottom-left-radius: @border-radius-value;
        border-bottom-right-radius: @border-radius-value;

        &:hover {
            background: @col_trimble_blue;
        }

        cursor: pointer;
        padding: @default-padding-value;
    }
}

.import-file-heading {
    font-weight: bold;
    flex: 1 auto;
    align-self: center;
    text-align: left;
    .text-overflow;
    padding: 5px;
}

.map-icon-placeholder {
    i {
        border-radius: 50%;
        .mixin-shadow-small;
    }
}

.tip {
    .mixin-tip;
    text-align: center;
    position: relative;

    div {
        padding: 0 @padding-medium 12px @padding-medium;
        font-weight: 600;

        span {
            font-weight: 700;
        }
    }

    i {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 10px;
    }

    &.no-todo {
        margin-top: 10px;
        border-bottom: solid 1px @border-color;
    }
}

.upload-status {
    max-height: 188px;
    overflow-y: hidden;

    .active-upload-title {
        padding: 0 @default-padding-value;
        .flex-row;
        justify-content: space-between;
    }

    h2 {
        font-size: 1.6rem;
    }

    .progress {
        display: inline-block;
        width: 90%;
        height: 15px;
        border: 1px solid #ccc;
        margin-bottom: 12px;
        margin-left: 10px;
        margin-top: 10px;
    }

    .progress div {
        font-size: 0.9rem;
        background: #d1eedd;
        width: 0;
        height: 100%;
        line-height: 15px;
        padding-left: 5px;
    }

    .file-progress {
        display: inline-block;
        width: 80%;
        position: absolute;
        top: 30px;
        left: 20px;
    }

    .file-progress div {
        font-size: smaller;
        height: 2px;
        border-bottom: 2px solid @col_gray_4;
        width: 0;
    }

    .upload-file-list {
        padding-bottom: @padding-default;

        li {
            position: relative;
            display: block;
            line-height: 32px;
            padding: 0 @default-padding-value;
            background-color: transparent;
            border: 0px;
            word-break: break-all;
        }
    }

    .file-icon-and-progress-bar {
        .flex-row;
        .icon {
            height: 13px;
            width: 16px;
            padding: 14px 22px 0 0;
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: left;
            margin-bottom: -2px;
        }
    }
}

.edit-layer-panel {
    .edit-layer-form {
        &-group {
            min-height: 40px;
            .flex-row-new;
            &-label {
                .flex4-new;
                padding: 0 1rem;
            }
            &-input {
                .flex8-new;
                padding: 0 1rem;
                &.layer-name {
                    padding: 0 1rem 0 0;
                }
            }
        }
    }
}

.map-cache-list {
    .map-cache-list-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .mixin-flexi-menu-header;
        h4 {
            color: #005f9e;
            font-weight: 600;
            font-size: 1.5rem;
        }
        .mapcache-view-all-toggle {
            cursor: pointer;

            &.hide_mapcache {
                opacity: 0.5;
            }
        }
    }
    .map-cache-icon {
        margin: 4px;
        border-radius: 50%;
        box-shadow: 1px 1px 2px rgb(54 53 69 / 65%);
    }
}
