//LESS class pertinent for CSS Flexbox module classes
//this class uses mixins from _prefixer.less

.flex-box {
    display: flex;
}

.flex-container {
    width: 100%;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.flex-column {
    .flex-box;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
}

.flex-column-reverse {
    .flex-box;
    -webkit-flex-flow: column-reverse nowrap;
    flex-flow: column-reverse nowrap;
}

.flex-row-new {
    .flex-box;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: -1rem;
    margin-right: -1rem;
}

.flex-row-reverse {
    .flex-box;
    -webkit-flex-flow: row-reverse nowrap;
    flex-flow: row-reverse nowrap;
}

.mixin-fixed {
    /* won't size */
    flex-shrink: 0;
    flex-grow: 0;
}

.mixin-scrollable {
    /* overflow scroll */
    flex: 1 1 auto;
    overflow: hidden;
}

.fh,
.mixin-fh,
.mixin-full-height {
    height: 100%;
}

.fw,
.mixin-fw,
.mixin-full-width {
    /* overflow scroll */
    width: 100%;
}

.fhw,
.mixin-fhw,
.mixin-full-fill {
    .mixin-full-width;
    .mixin-full-height;
}

/* ################################ */
// THIS IS OBSELETE CODE BELOW THE LINE
/* ################################ */

.flex-ct,
.flexbox {
    .flex-box; /* redirects to definitions above */
}

//shortcut to combine flex-ct and flex-direction
.flex-ct-column {
    .flex-column; /* redirects to definitions above */
}

//This is the same as flex-ct
/*.flex-box-row,*/
.flex-ct-row {
    .flex-row; /* redirects to definitions above */
}

/**
*  FLEX ALIGN ---- This defines the alignment along the  CROSS axis, perpendicular to main axis
*
*/

// these are classes for children of a flex container
// telling the parent how much space it will take up.
.flex0-new {
    .flex-new(0);
}
.flex1-new {
    .flex-new(1);
}
.flex2-new {
    .flex-new(2);
}
.flex3-new {
    .flex-new(3);
}
.flex4-new {
    .flex-new(4);
}
.flex5-new {
    .flex-new(5);
}
.flex6-new {
    .flex-new(6);
}
.flex7-new {
    .flex-new(7);
}
.flex8-new {
    .flex-new(8);
}
.flex9-new {
    .flex-new(9);
}
.flex10-new {
    .flex-new(10);
}
.flex11-new {
    .flex-new(11);
}
.flex12-new {
    .flex-new(12);
}

/* created for layer/file specific rows where content was not centering for IE, this is better than using flex-row until further testing */
//TODO: get rid of this
.list-flex-row {
    height: 100%;
    padding: 5px 0px;
    &.active {
        background-color: @col_gray_3;
    }
    &:hover {
        background-color: @col_gray_3;
    }
    .side {
        width: 50px;
    }
}

/* USE these classes instead for stacking ( IE Bootstrap style ) */
/* Default is ROW*/

/* FLEX DIRECTION ( establishes Main Axis along which flex items are laid out)*/

.flex-row-wrap {
    .flex-flow(row, wrap);
}

/**
*   FLEX WRAP ---- defines whether the flex container is single-line or multi-line-----------------*/
/*


Defualt
*/
.flex-wrap-nowrap {
    .flex-wrap(nowrap);
}

/* wrap-reverse: items are stacked in reverse of how they are in dom */
.flex-wrap-reverse {
    .flex-wrap(reverse);
}

.flex-wrap-wrap {
    .flex-wrap(wrap);
}

/**
*  FLEX JUSTIFY --- This defines the alignment along the MAIN axis and how space is distributed
*/

/* Default  items are packed toward the start line*/
.justify-start {
    .justify-content(flex-start);
}

/*items are evenly distributed in the line with equal space around them*/
.justify-around {
    .justify-content(space-around);
}

/* items are evenly distributed in the line; first item is on the start line, last item on the end line*/
.justify-between {
    .justify-content(space-between);
}

/* items will be packed (centered) on the middle of line */
.justify-center {
    .justify-content(center);
}

/* items are packed toward the end line*/
.justify-end {
    .justify-content(flex-end);
}

/**
*  FLEX ALIGN ---- This defines the alignment along the  CROSS axis, perpendicular to main axis
*
*/

.align-start {
    .align-items(flex-start);
}

/*items are evenly distributed in the line with equal space around them*/
.align-end {
    .align-items(flex-end);
}

.align-center {
    .align-items(center);
}

/* Default - stretch to fill the container */
.align-stretch {
    .align-items(stretch);
}

.align-baseline {
    .align-items(baseline);
}

/**
*  CONTENT ALIGN - defines layout and spacing in the cross axis
* NOTE : This property has NO EFFECT when the flexbox has only a single line.
*/

/* Default - stretch to fill the container */
.content-stretch {
    .align-content(stretch);
}

.content-start {
    .align-content(flex-start);
}

.content-end {
    .align-content(flex-end);
}

.content-center {
    .align-content(center);
}

.content-between {
    .align-content(space-between);
}

.content-around {
    .align-content(space-around);
}

/*
center shortcut - Only use this class on a flex container or it won't work
ie <div class ='flex-ct center'>
*/
.center {
    .align-items(center);
    .justify-content(center);
}

/**
     FLEXBOX
**/

.flex(@grow,@shrink,@basis) {
    flex-grow: @grow;
    -webkit-flex-grow: @grow;
    -moz-flex-grow: @grow;
    -ms-flex-grow: @grow;
    -ms-flex-preferred-size: @basis;
    flex-shrink: @shrink;
    -webkit-flex-shrink: @shrink;
    -moz-flex-shrink: @shrink;
    -ms-flex-shrink: @shrink;
    flex-basis: @basis;
    -webkit-flex-basis: @basis;
    -moz-flex-basis: @basis;
    -ms-flex-basis: @basis;
}

/**
* Doug Wood - This is 2009 CSS3 spec for flex orientation.
 */

.flex-new(@grow) {
    flex: @grow;
    -webkit-flex: @grow;
    -moz-flex: @grow;
    -ms-flex: @grow;
    //-ms-flex-preferred-size: auto; // this jacks up text wrapping in ie10 and spacing in ie11
    padding: 1rem;
}

.flex(@grow,@shrink,@basis) {
    flex: @arguments;
    -webkit-flex: @arguments;
    -moz-flex: @arguments;
    -ms-flex: @arguments;
    -ms-flex-preferred-size: @basis;
}

.ordinal-group(@num) {
    -moz-box-ordinal-group: @num;
    -webkit-box-ordinal-group: @num;
    ordinal-group: @num;
}

.order(@order) {
    -webkit-order: @order;
    -ms-flex-order: @order;
    order: @order;
}

.orient (row) {
    -webkit-box-orient: horizontal;
}
.orient (row-reverse) {
    -webkit-box-orient: horizontal;
}
.orient (column) {
    -webkit-box-orient: vertical;
}

.ms-justify(space-between) {
    -ms-flex-pack: justify;
}
.ms-justify(flex-start) {
    -ms-flex-pack: start;
}
.ms-justify(flex-end) {
    -ms-flex-pack: end;
}
.ms-justify(center) {
    -ms-flex-pack: center;
}
.ms-justify(space-around) {
    -ms-flex-pack: justify;
}

.box-sizing() {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

//the current spec for flex module
//.flex-container(@direction:row){
//
//  .flexbox;
//  .orient(@direction);
//  -webkit-box-direction: normal ;
//  -webkit-flex-direction: @direction ;
//  -ms-flex-direction: @direction ;
//  flex-direction: @direction ;
//}
.flex-direction(@direction:row) {
    .orient(@direction);
    -webkit-box-direction: normal;
    -webkit-flex-direction: @direction;
    -ms-flex-direction: @direction;
    flex-direction: @direction;
}
.flex-wrap(@wrap : nowrap) {
    -webkit-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    flex-wrap: @wrap;
}
// a combination of both direction and wrap
.flex-flow(@direction : row, @wrap : nowrap) {
    .flex-direction(@direction);
    .flex-wrap(@wrap);
}

.justify-content(@a : center) {
    -webkit-box-pack: @a;
    -webkit-justify-content: @a;
    -ms-flex-pack: @a;
    justify-content: @a;
    .ms-justify(@a);
}

.align-items(@a:center) {
    -webkit-box-align: @a;
    -webkit-align-items: @a;
    -ms-flex-align: @a;
    align-items: @a;
}
.align-content(@a:center) {
    -webkit-align-content: @a;
    -ms-flex-line-pack: @a;
    align-content: @a;
}
