.context-prompts {
    padding-top: 100px;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .prompt {
        width: 200px;
        height: 200px;
        text-align: center;
        padding: 20px 30px;
        margin-bottom: 30px;
        float: left;
        i {
            margin: 0 auto;
        }
        .title {
            font-size: 16px;
            padding-top: 20px;
            display: block;
            font-weight: 600;
        }
        .description {
            display: block;
            color: @col_gray_7;
        }
        &:hover {
            cursor: pointer;
            box-shadow: 1px 1px 5px rgba(54, 53, 69, 0.5);
            background-color: @col_white;
        }
    }
}
