ng-scrollbar.scroll-container {
    display: block;
    min-height: 100% !important;

    --scrollbar-size: 9px !important;
    --scrollbar-hover-size: 14px !important;
    --scrollbar-thumb-hover-color: #999999f9 !important;
    --scrollbar-track-transition: height ease-out 200ms, width ease-out 500ms !important;

    .ng-scrollbar-wrapper[verticalHovered='true'],
    .ng-scrollbar-wrapper[verticalDragging='true'] {
        --scrollbar-track-color: #eee !important;
        transition: @scrollbar-fade-transition;
        .scrollbar-thumb {
            width: 11px !important;
            margin-left: 2px !important;
            transition: @scrollbar-fade-transition;
        }
    }
    .ng-scrollbar-wrapper[horizontalHovered='true'] {
        --scrollbar-track-color: #eee !important;
        transition: @scrollbar-fade-transition;
    }

    .ng-scrollbar-track {
        --scrollbar-padding: 0px;
        border-radius: 0;
        transition: @scrollbar-fade-transition;
    }

    scrollbar-y.scrollbar-control {
        margin-right: 1px;
        justify-content: flex-end;
        align-items: flex-start;
        .scrollbar-thumb {
            border-radius: 7px;
            width: 6px !important;
            margin-left: 2px !important;
            transition: @scrollbar-fade-transition;
        }
    }
    scrollbar-x.scrollbar-control {
        height: 7px !important;
        justify-content: flex-start;
        align-items: flex-end;
        .scrollbar-thumb {
            border-radius: 7px;
            transition: @scrollbar-fade-transition;
        }
    }
}
.scroll-active {
    --scrollbar-thumb-color: #999 !important;
    --scrollbar-track-color: #eee !important;
}

// Adds inset shadows to top/bottom of container when scrolling

.scroll-shadow-top::before {
    content: '';
    position: absolute;
    inset: 0 0 auto 0;
    height: 7px;
    -webkit-box-shadow: inset -1px 7px 10px -9px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: inset -1px 7px 10px -9px rgba(0, 0, 0, 0.45);
    box-shadow: inset -1px 7px 10px -9px rgba(0, 0, 0, 0.45);
    z-index: 1;
}

.scroll-shadow-end::after {
    content: '';
    position: absolute;
    inset: auto 0 0 0;
    height: 7px;
    -webkit-box-shadow: inset -1px -7px 10px -9px rgba(0, 0, 0, 0.45);
    -moz-box-shadow: inset -1px -7px 10px -9px rgba(0, 0, 0, 0.45);
    box-shadow: inset -1px -7px 10px -9px rgba(0, 0, 0, 0.45);
    z-index: 1;
}

// Fixes context menus being offset within scrollbars

.ng-scroll-viewport {
    contain: none !important;
}
