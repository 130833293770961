/* ################################################ */
/* This following code is for Default Input type checkboxes and applying CSS */

.chkbox-div {
    position: relative;
    vertical-align: middle;
    height: 32px;
    width: 100%;
    .flex-row;
    padding-top: 7px;
    padding-bottom: 7px;

    label {
        margin-bottom: 0px;
        &::before {
            content: ' ';
            position: absolute;
            left: -1px;
            top: 8px;
            background: url(../assets/images/icons/checkbox_not_selected.svg) no-repeat;
            height: 16px;
            width: 16px;
            display: inline-block;
        }
    }

    &.search-filter-item {
        .chkbox {
            cursor: pointer;
            &:checked + label {
                &:before {
                    left: 10px;
                    top: 12px;
                }
            }
        }
        label {
            cursor: pointer;
            &::before {
                left: 10px;
                top: 12px;
            }
        }
    }
}

.chkbox {
    opacity: 0;
    margin: 0 !important;
    margin-right: 10px !important;
    position: relative;
    z-index: 2;
    height: auto;
    display: inline-block;

    &:checked + label {
        &:before {
            content: ' ';
            position: absolute;
            left: -1px;
            top: 8px;
            background: url(../assets/images/icons/checkbox_selected.svg) no-repeat;
            height: 16px;
            width: 16px;
            display: inline-block;
        }
    }
}

/* ################################################ */
/* The following Code is for the Checkbox Directive */
@default-checkbox-height: 16px;
@default-checkbox-width: 16px;

.checkbox2 {
    width: @default-checkbox-width;
    position: relative;
    display: inline-block;
}

.checkbox2 label {
    width: 16px;
    height: @default-checkbox-height;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 !important;
    background: #fcfff4;
    border: 1px solid @col_gray_3;
    .border-radius(1px);
}

.checkbox2 label:after {
    content: '';
    width: @default-checkbox-width;
    height: 7px;
    position: absolute;
    top: 0px;
    left: 3px;
    .check-tick;
    border-color: @col_yellow;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    .transform(rotate(-45deg));
}

.checkbox2 label:hover::after {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
}

.checkbox2 input[type='checkbox'] {
    opacity: 0;
}

.checkbox2 input[type='checkbox']:disabled + label:hover {
    .check-disable;
}

.checkbox2 input[type='checkbox']:checked + label:after,
.checkbox2 input[type='checkbox']:checked + label:hover::after,
.checkbox2 input[type='checkbox']:indeterminate + label:after,
.checkbox2 input[type='checkbox']:indeterminate + label:hover::after {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    content: '';
    width: @default-checkbox-width;
    height: 7px;
    position: absolute;
    top: 0px;
    left: 3px;
    .check-tick;
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    .transform(rotate(-45deg));
}

.checkbox2 input[type='checkbox']:checked + label:hover::after,
.checkbox2 input[type='checkbox'] + label:hover::after,
.checkbox2 input[type='checkbox']:indeterminate + label:hover::after {
    border-color: @col_yellow;
}

.checkbox2 input[type='checkbox']:indeterminate + label:after,
.checkbox2 input[type='checkbox']:indeterminate + label:hover::after {
    width: 8px;
    height: 1px;
    top: 6px;
    .transform(rotate(0));
}

.check-tick {
    border: 2px solid #333;
    border-top: none;
    border-right: none;
}

.check-disable {
    cursor: not-allowed;
}

/* Toggle Switch */
.toggle-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 25px;
    margin: 4px 0 4px 0;

    &:hover {
        background-color: @col_gray_3;
        cursor: pointer;
    }

    &:active {
        background-color: @col_gray_3;
    }
}

.toggle {
    position: relative;
    box-sizing: border-box;
    width: 12px;
    height: 12px;
    border: 1px solid @col_gray_10;
    background-color: @col_gray_4;
    z-index: 100;
    border-radius: 6px;

    &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: @col_yellow;
        z-index: 100;
        .transition(all 0.5s ease-in-out);
        border-radius: 5px;
    }
}

.toggle.off {
    &:after {
        background-color: @col_gray_4;
    }
}

.toggle.semi:after {
    width: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

//If a checkbox needs a div wrapped around it,
// use this so it keeps it height and stays
// centered
.cb-wrap {
    height: @default-checkbox-height;
}

.radiobtn {
    display: inline-block;
}
