.rtc-configuration {
    height: 100%;
    &-form-group {
        margin-bottom: 2rem;

        h4 {
            margin-bottom: 5px;
            font-size: 14px;
        }

        .dropdown-container .toggle-button {
            margin-left: 0;
            margin-right: 0;
        }
    }

    custom-scroll:first-child {
        width: 66%;
    }

    .rtc-edit-form-group,
    .rtc-configuration-form-group,
    .rtc-workspaces-content {
        // custom styling to make this button width 100%
        #rtc-load-ntrip,
        #rtc-load-datums {
            .button-confirm-wrapper {
                width: 100%;
                margin-left: 0px;
            }
            .button-main {
                width: 100%;
                justify-content: center;
                margin-top: 1rem;
            }
        }
    }

    &__options {
        padding-right: 3rem;
        border-right: 1px solid @col_gray_1;
        gsp-notification:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }

    &__workspaces {
        padding-left: 2rem;
        height: 400px;

        .description {
            padding-top: 1rem;
        }

        .share-all {
            border-bottom: 1px solid @col_gray_1;
            padding-bottom: 1rem;
            .item {
                padding: 0 0.5rem;
            }
        }
        .rtc-workspaces-content {
            .rtc-workspaces-item:first-of-type {
                padding-top: 2rem;
            }
            .rtc-workspaces-item {
                padding: 0 0.5rem;
                .name {
                    max-width: 150px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
    }

    &__internet,
    &__serial {
        .rtc-edit-form-wrapper {
            padding-right: 2rem;
            height: 100%;
        }

        .dropdown-container .toggle-button {
            margin-left: 0;
            margin-right: 0;
        }

        .gsp-form-input {
            width: 100%;
        }

        .rtc-edit-form {
            border-right: 1px solid @col_gray_3;
            height: 530px;
            &-group {
                margin-bottom: 2rem;

                h5 {
                    display: inline-block;
                }

                gsp-help-text {
                    display: inline;
                }
            }

            .rtc-edit-form-group {
                .auth-icon {
                    display: inline-block;
                }

                gsp-checkbox label.text-overflow {
                    white-space: normal;
                }
            }
        }

        .rtc-data-list {
            padding-left: 2rem;

            .rtc-search-container {
                display: flex;
                justify-content: space-between;

                p {
                    margin: 1em 0 1em 0;
                }

                .gsp-text-wrapper {
                    width: 50%;
                    margin: @padding-small 0 @padding-small 0;
                }
            }
        }

        .rtc-data-list--empty {
            .center;
            display: flex;
            .loading {
                width: 100px;
            }
        }

        .rtc-data-list-items {
            height: 450px;
            min-height: 450px;
            max-height: 450px;

            .datum-list li,
            .ntrip-list li {
                padding: 1rem;
                margin-bottom: 1rem;
                background: @col_white;
                cursor: pointer;
            }

            .ntrip-list .title {
                font-size: 16px;
            }

            .ntrip-list {
                .ntrip-list-items {
                    .title {
                        max-width: 180px;
                    }

                    .format {
                        max-width: 90px;
                    }

                    .system {
                        max-width: 180px;
                    }

                    .auth {
                        max-width: 60px;
                    }
                }
            }
        }

        .positioning-placeholder-image {
            text-align: center;

            img {
                opacity: 0.2;
                width: 200px;
                margin: 0 auto;
            }
        }

        .selected-ntrip,
        .selected-datum {
            background-color: @col_white;
            h5 {
                padding-left: @padding-small;
                padding-top: @padding-small;
            }
        }
    }
}
