.loading {
    height: 100px;
    width: 100%;
    background: transparent url('../assets/images/loading_spinner.svg') no-repeat center center;
}

.loading-small {
    height: 60px;
    width: 100%;
    background: transparent url('../assets/images/loading_spinner.svg') no-repeat center center;
}

.component-loading {
    position: fixed;
    top: 30%;
    left: 0;
    .loading;
}

.loading-align-center {
    .loading,
    .loading-small {
        margin-top: 25vh;
    }
}

.app-loading,
body.workspace-loading .loader,
body.template-editor-loading .loader {
    position: fixed;
    left: 0;
    z-index: 410;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: fade(@col_gray_2, 65%);
    background-image: url('../assets/images/loading_spinner.svg');
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: center;
}

body.workspace-loading .app-loading {
    background-color: transparent;
}

body.template-editor-loading .loader {
    background-color: @col_gray_light;
}

.loading-no-access {
    background-color: @col_gray_light;
    text-align: center;
    position: absolute;
    left: 34%;
    top: 40%;
    padding: @padding-medium;
    width: 36%;
    .mixin-shadow-medium;

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// All progress bar related
mat-progress-bar.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: @col_trimble_blue_mid;
    margin-top: 4px;
    height: 1px;
    overflow-y: hidden;

    // "Import from file" - few adjustments on height and color status
    &.progress-success {
        --mdc-linear-progress-active-indicator-color: @col_blue_light;
        height: 2px;
    }

    &.progress-inprogress {
        --mdc-linear-progress-active-indicator-color: @col_blue_pale;
        height: 2px;
    }
    &.progress-error {
        --mdc-linear-progress-active-indicator-color: @col_red_dark;
        height: 2px;
    }

    &.progress-warning {
        --mdc-linear-progress-active-indicator-color: @col_yellow_dark;
        height: 2px;
    }

    // "Duplicate Workspace" wizard - it has different thickness visually due to have different positioning properties
    &.wizard-progress {
        margin-bottom: 2px;
        padding-bottom: 2px;
    }

    // default loading background
    .mdc-linear-progress__buffer-bar {
        background-color: @col_progress_light_blue;
    }
}

@keyframes assimilate {
    0% {
        fill: @col_trimble_blue_mid;
    }

    50% {
        fill: @col_trimble_blue_light;
    }

    100% {
        fill: @col_trimble_blue_mid;
    }
}
