.date-wrapper {
    position: relative;
    width: auto;
    & .btn-sm {
        padding: 3px 7px;
    }

    & .btn {
        padding: 7px 9px;
        background-color: @col_white;
    }

    & form {
        position: relative;
        margin-bottom: 1px;
        & .any-time {
            background: @col_gray_2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            &:hover {
                background: @col_gray_1;
            }
            & .form-control {
                background: transparent;
            }
        }
    }

    & .form-control {
        box-shadow: none;
        &::-ms-clear {
            display: none;
        }
    }

    .date-label {
        .form-control;
        font-style: italic;
    }
    & input .form-control {
        padding: 2px 10px 2px 20px;
    }
    & .ng-invalid input {
        border-left: 3px solid @col_red !important;
        border-right: 3px solid @col_red !important;
    }

    & .datepicker-wrapper {
        background: white;
        position: fixed;
        z-index: 10;
        padding: 8px;
        .box-shadow(2px 2px 12px @col_trimble_gray);
        table {
            outline: 0;
            .btn-default {
                background-color: @col_white;
                border: none;
                &:hover {
                    background-color: @col_gray_2;
                }
                .text-info {
                    color: @col_gray_dark_1;
                }
                &.active {
                    background-color: @col_trimble_blue;
                    span,
                    .text-info {
                        color: @col_white;
                    }
                }
                strong {
                    font-weight: 400;
                    font-size: 1.6rem;
                }
            }
        }
    }
}
.pika-single,
.pika-single.is-bound {
    .mixin-popup-panel;
    border: none;
    color: @col_trimble_gray;

    .pika-label {
        background-color: transparent;
    }

    .pika-button {
        height: 32px;
        width: 32px;
        text-align: center;
        border-radius: 0;
        background-color: transparent;
        color: @col_trimble_gray;
    }
    .is-today {
        color: @col_trimble_gray;
    }
    .is-today .pika-button {
        color: inherit;
        background-color: @col_trimble_yellow;
    }

    .is-selected .pika-button {
        background-color: @col_trimble_blue;
        box-shadow: none;
        color: @col_white;
    }

    .pika-button:hover {
        background-color: @col_blue_pale !important;
        color: @col_trimble_gray !important;
        border-radius: 0 !important;
    }

    abbr[title],
    abbr[data-original-title] {
        cursor: default;
        border-bottom: none;
        color: @col_gray_7;
    }

    .pika-title select {
        color: @col_trimble_gray;
    }
}

.pikaday-wrapper {
    display: table;
    .pikaday-icon {
        display: table-cell;
        padding: 0px 0;
        vertical-align: middle;
        height: 32px;
        > i {
            display: inline-block;
            padding: 0 0;
            border: 1px solid @col_gray_1;
            border-left: none;
            border-top-right-radius: @border-radius-value;
            border-bottom-right-radius: @border-radius-value;
            background-color: @col_white;
            width: 32px;
            height: 32px;
        }
    }

    .pickaday-icon i {
        display: inline-block;
    }

    input.gsp-form-input {
        display: table-cell;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
        .mixin-placeholder-size(1.4rem);
    }
}
