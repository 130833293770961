.label-holder {
    background: @col_white;
    border: solid 1px @col_gray_1;
    padding: 3px 3px 0 3px;
    .border-radius(2px);
    overflow-wrap: break-word;
    min-width: 400px;
    min-height: 32px;
    .flex-row;
    flex-wrap: wrap;
    .new-label {
        border: solid 1px transparent;
        min-height: 24px;
        height: 26px;
        align-self: flex-start;
    }
    span {
        &.individual-label {
            min-height: 24px;
            position: relative;
            .flex-row;
            background-color: @col_gray_2;
            margin: 0 0 3px 3px;
            padding: 0 5px;
            word-break: break-all;
            position: relative;
            //line-height:32px;
            .align-items(center);
            .justify-between;
            .close-tag {
                margin-left: 10px;
                background-color: @col_gray_2;
                cursor: pointer;
            }
        }
    }
}
