/* ######################################################## */
/* THESE CLASSES ARE UP TO DATE BUT HAVE BEEN MANUALLY ENTERED */
/* ######################################################## */

//icon sizing
.mixin-icon-24 {
    width: 24px;
    height: 24px;
    display: block;
    background-repeat: no-repeat;
}

.mixin-icon-32 {
    width: 32px;
    height: 32px;
    display: block;
    background-repeat: no-repeat;
}

.mixin-icon-48 {
    width: 48px;
    height: 48px;
    display: block;
    background-repeat: no-repeat;
}

.mixin-icon-64 {
    width: 64px;
    height: 64px;
    display: block;
    background-repeat: no-repeat;
}

@var-icon-path-24: '../assets/images/SVGs-24scale/';
/* Path for 24, 48, 96px */
@var-icon-path-32: '../assets/images/SVGs-32scale/';
/* Path for 16, 32, 64px */

/* Icon File references themselves TODO, automate this */

//.icon_line_arrow_down_24-48-96{ background-image:url('@{var-icon-path-24}icon_line_arrow_down_24-48-96.svg');}
//.icon_line_arrow_up_24-48-96{ background-image:url('@{var-icon-path-24}icon_line_arrow_up_24-48-96.svg');}

//.icon_line_arrow_down_16-32-64{ background-image:url('@{var-icon-path-32}icon_line_arrow_down_16-32-64.svg');}
//.icon_line_arrow_up_16-32-64{ background-image:url('@{var-icon-path-32}icon_line_arrow_up_16-32-64.svg');}

/* usable classes in code TODO, automate this */

//.icon_line_arrow_up_32{.icon_line_arrow_up_16-32-64; .mixin-icon-32;};
//.icon_line_arrow_down_32{.icon_line_arrow_down_16-32-64; .mixin-icon-32;};

/* symbology icon defautls */

.svg-icon {
    width: 32px;
    height: 32px;
}

/* Adding display:block to SVG icons */

/* symbology icon defautls */

/* ######################################################## */
/* ALL ICONS BELOW THIS POINT ARE LEGACY */
/* ######################################################## */

/* Map needed for map tool bar*/

/*Map provider icons classes*/

.trimble-map-icon {
    background: url(../assets/images/maps/trimble-map-icon.png);
    .mixin-icon-32;
}

.trimble-digital-globe-icon {
    background: url(../assets/images/maps/trimble-digital-globe-icon.png);
    .mixin-icon-32;
}

.google-map-icon {
    background: url(../assets/images/maps/google-map-icon.png);
    .mixin-icon-32;
}

.google-satellite-icon {
    background: url(../assets/images/maps/google-satellite-icon.png);
    .mixin-icon-32;
}

.google-terrain-icon {
    background: url(../assets/images/maps/google-terrain-icon.png);
    .mixin-icon-32;
}

.esri-topo-icon {
    background: url(../assets/images/maps/esri-topo-icon.png);
    .mixin-icon-32;
}

.esri-terrain-icon {
    background: url(../assets/images/maps/esri-terrain-icon.png);
    .mixin-icon-32;
}

.esri-shaded-icon {
    background: url(../assets/images/maps/esri-shaded-icon.png);
    .mixin-icon-32;
}

.esri-satellite-icon {
    background: url(../assets/images/maps/esri-satellite-icon.png);
    .mixin-icon-32;
}

.esri-physical-icon {
    background: url(../assets/images/maps/esri-physical-icon.png);
    .mixin-icon-32;
}

.esri-ocean-icon {
    background: url(../assets/images/maps/esri-ocean-icon.png);
    .mixin-icon-32;
}

.esri-natgeo-icon {
    background: url(../assets/images/maps/esri-natgeo-icon.png);
    .mixin-icon-32;
}

.esri-map-icon {
    background: url(../assets/images/maps/esri-map-icon.png);
    .mixin-icon-32;
}

.esri-gray-icon {
    background: url(../assets/images/maps/esri-gray-icon.png);
    .mixin-icon-32;
}

.esri-delorme-icon {
    background: url(../assets/images/maps/esri-delorme-icon.png);
    .mixin-icon-32;
}

/*Map provider icons classes*/

/*Template editor icons*/

.icon-24-terraflex-required {
    .icon_line_asterisk_24;
}

.icon-24-terraflex-editable {
    .icon-24-terraflex-read-only;
    opacity: 0.5;
}

.icon-24-terraflex-not-required {
    .icon_line_asterisk_24;
    opacity: 0.5;
}

.icon-24-terraflex-read-only {
    .icon_solid_lock_24;
}

.icon-24-sitevision-visible {
    background-image: url(../assets/images/SVGs-24scale/icon_line_visibility_show_24-48-96.svg);
}

.icon-24-sitevision-hidden {
    background-image: url(../assets/images/SVGs-24scale/icon_line_visibility_hide_24-48-96.svg);
    opacity: 0.5;
}

.icon-32-dark-selection-single-new {
    .i32;
    .icon_solid_select_single_32;
}

.icon-32-dark-selection-rectangle-new {
    .i32;
    .icon_line_select_rectangle_32;
}

.icon-32-dark-selection-polygon-new {
    .i32;
    .icon_line_select_polygon_32;
}

.icon-32-dark-selection-single-append {
    .i32;
    .icon_solid_select_single_add_32;
}

.icon-32-dark-selection-rectangle-append {
    .i32;
    .icon_line_select_rectangle_add_32;
}

.icon-32-dark-selection-polygon-append {
    .i32;
    .icon_line_select_polygon_add_32;
}

.icon-32-dark-selection-single-remove {
    .i32;
    .icon_solid_select_single_subtract_32;
}

.icon-32-dark-selection-rectangle-remove {
    .i32;
    .icon_line_select_rectangle_subtract_32;
}

.icon-32-dark-selection-polygon-remove {
    .i32;
    .icon_line_select_polygon_subtract_32;
}

.icon-16-dark-radio-selected {
    .i16;
    background: url(../assets/images/icons/icon-16-dark-radio-selected.png);
}

.icon-16-dark-check-selected {
    .i16;
    background: url(../assets/images/icons/icon-16-dark-check-selected.png);
}

.icon-24-dark-radio-selected {
    .i24;
    background: url(../assets/images/icons/icon-24-dark-radio-selected.png);
}

.icon-24-dark-check-selected {
    .i24;
    background: url(../assets/images/icons/icon-24-dark-check-selected.png);
}

/*Model editor icons*/
.icon-48-symbology-icon {
    .i48;
    background: url(../assets/images/symbology/symbol-icon.svg);
}

/* Rules 
.icon-24-light-delete{
  background: url(../assets/images/icons/icon-24-light-delete.png);
  .mixin-icon-24;
}
*/

/*.icon-32-dark-critical{
  background: url('@{var-icon-path-32}icon_solid_alert_critical_16-32-64.svg');
  .mixin-icon-32;
}*/

.icon-48-placeholder {
    .i48;
    background: url('../assets/images/icons/icon-48-dark-placeholder.png');
}

.icon-24-success {
    .i24;
    background-image: url('../assets/images/svg/check_circle.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-24-uploading {
    .i24;
    background-image: url('../assets/images/icons/icon_hollow_cloud_up_16-32-64.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-24-progress {
    .i24;
    background-image: url('../assets/images/svg/progress-circle.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-24-error {
    .i24;
    background-image: url('../assets/images/svg/error.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon-24-warning {
    .i24;
    background-image: url('../assets/images/svg/warning.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.icon_content_copy {
    .i24;
    background-image: url('../assets/images/icons/content_copy.svg');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}
