/* Various Reusable Variables */

// BORDER
@border-style: 1px solid @col_gray_1;
@border-radius-value: 2px;
@border-color: @col_gray_1;
@border-color-dark: @col_gray_2;

//Padding
@padding-tiny: 5px;
@padding-small: 10px;
@padding-small-medium: 15px;
@padding-medium: 30px;
@padding-large: 40px;

@padding-default: 20px;

/* Component variables start */

// Grids
@half-gutter-width: 0.5rem;
@gutter-compensation: -0.5rem;
@outer-margin: 2rem;
@grid-columns: 12;
@wizard-workspace-body-width-ratio: 75%;
@wizard-hint-text-width-ratio: 25%;

// Badges
@badge-font-weight: normal;
@badge-line-height: 1;
@badge-color: #fff;
@badge-bg: @col_gray_3;
@badge-border-radius: 2px;

@scrollbar-fade-transition: background-color 180ms linear, opacity 180ms, width 180ms linear;

/* Component variables end */

//Menu padding
@menu-padding: 0.4rem;

.div-horizontal-center {
    max-width: 800px;
    margin: 0 auto;
    padding: @padding-default;
}

.list-bottom-border {
    border-bottom: solid 1px @col_gray_1;
    padding: 0 20px 10px;
    margin: 0 -20px 10px;
}

/* Mixins */
.mp0 {
    margin: 0;
    padding: 0;
}
.m0 {
    margin: 0;
}
.p0 {
    padding: 0;
}

.text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
}

/* Common Widths */

@left-panel-width: 28rem;

/* ############################################### */
/* Deprecated Stuff */
/* ############################################### */

@shadow: 1px 1px 2px rgba(54, 53, 69, 0.65); /*deprectated, please use the mixins below */
@default-padding-value: @padding-default; // TODO: depreciate to @padding-default
