.mixin-text-label {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    color: @col_gray_7;
    word-wrap: break-word;
}

.mixin-small-font {
    font-size: 12px;
    font-weight: normal;
}

.mixin-error-message {
    background: @col_red;
    color: @col_white;
    font-size: 11px;
    display: block;
    padding: 0.5em 1em;
}

.feature-label {
    .mixin-text-label;
}

.feature-panel {
    .flex-box;
    justify-content: flex-start;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    height: 100%;

    .button-confirm-wrapper {
        .confirm-box {
            top: -6px;
            display: flex;
            flex-direction: row;
            z-index: 2;
            position: absolute;
            right: 0;

            .button-cancel {
                margin-left: 0px;
            }
            .button-danger {
                margin-left: 4px;
            }
        }
    }

    loading {
        width: 100%;
        height: 100%;
    }
}

.field-edit-group {
    .editable-control {
        margin-top: 5px;
        label {
            margin-bottom: 0;
            &.radio-label-wrap {
                overflow: visible;
            }
            &.radio-label-wrap ~ span {
                vertical-align: text-bottom;
                .text-overflow;
                display: inline-block;
            }
        }
        .yes-no-field {
            padding: 2px 0;
        }
        .choice {
            .radio-label-wrap {
                display: flex;
                align-items: center;
            }

            &.other-checkbox-radiobox {
                margin: 5px 0 0 5px;
            }

            &.other-textbox {
                padding-left: @padding-small-medium;
                padding-left: 15px;
                margin-left: 10px;
                margin-bottom: 10px;
                border-left: 2px solid @col_gray_1;
                border-bottom: 2px solid @col_gray_1;
                overflow: visible;
                height: 41px;

                .feature-label {
                    padding-top: 10px;
                }

                .gsp-form-input {
                    width: 100%;
                }
            }
        }
        .input-error {
            border: solid 1px @col_red;
        }
    }
}

.feature-nav {
    header {
        .mixin-flexi-menu-header;
        justify-content: center;
        padding: 0;
        .button-confirm-wrapper {
            margin-left: 0;
        }
    }
    section {
        .mixin-flexi-menu-content;
    }
    width: 36px;
    background-color: @col_gray_0;
    .flex-column;
    .list-count {
        width: 36px;
        height: 32px;
        .flex-row;
        justify-content: center;
    }
    ul {
        li {
            .mixin-feature-list;
        }
    }
}

.feature-field {
    .choice {
        .chkbox {
            flex: 0 0 auto;
        }
        label {
            margin-bottom: 0;
            .text-overflow;
        }
    }
    .error-message {
        .mixin-error-message;
    }
    .error-message ~ input {
        border: solid 1px @col_red;
    }
}

.feature-data {
    word-wrap: break-word;
    margin-top: -3px;
    user-select: text;
    .choice-list {
        > li {
            .flex-row;
            i {
                flex: 0 0 auto;
                margin-left: -5px;
                margin-right: 5px;
                &.custom-icon {
                    margin: 2px;
                }
            }
            label {
                margin-bottom: 0;
                max-width: 200px;
                .text-overflow;
            }
        }
    }
    th,
    td {
        padding: 5px 15px 5px 0px;
    }

    &.link {
        display: block;
    }
}

.feature-main-panel {
    width: 284px;
    .flex-column;
    flex-grow: 1;
    justify-content: flex-start;
    header {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        justify-content: space-between;
        padding: @padding-default;
        padding-bottom: 0px;
    }
    section {
        overflow: hidden;
        position: relative;
        flex: 1 1 auto;
        .page-header-tab-container {
            height: 45px;
            padding-top: @padding-tiny;
            background-color: @col_gray_0;

            .ng-scroll-content {
                width: auto;
            }
            .nav-tabs {
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                white-space: nowrap;
            }
            .nav-tabs > li {
                float: none;
                .menu-sub-heading {
                    max-width: 200px;
                    height: 40px;
                    text-align: center;
                    padding: @padding-small;
                    .text-overflow;
                }
                &:hover {
                    cursor: pointer;
                }
                &.active {
                    background-color: @col_gray_half;
                    margin: 0;
                }
                &.empty-page-header {
                    width: 50px;
                }
            }
        }
        .page-count {
            .mixin-small-font;
            flex: 0 0 auto;
            color: @col_trimble_blue_mid;
        }

        .feature-field-group {
            padding-bottom: @padding-large;
        }

        .ng-scroll-content {
            width: 100%;
        }
    }
    footer {
        -webkit-flex: 0 0 auto;
        flex: 0 0 auto;
        padding: @padding-default;
        > div {
            .flex-box;
            justify-content: space-between;
        }
    }
    h2 {
        .heading-text {
            padding-right: 10px;
        }
        &.feature-page-title {
            .flex-box;
            -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 10px;
            align-items: baseline;
        }
    }
    h3 {
        &.feature-page-title {
            border-top: solid 1px @col_gray_0;
            margin: 20px -20px 10px -20px;
            padding: 20px 20px 0;
            font-size: 16px;
            color: @col_trimble_gray;
            font-weight: 300;
        }
    }
    .mini-map {
        height: 160px;
    }
    .restore-layer {
        height: 32px;
        padding: 0 @padding-default;
        color: @col_white;
        .flex-row;
        justify-content: space-between;
        .restore {
            font-weight: 600;
            cursor: pointer;
        }
    }
}

.metadata {
    .flex-column;
    justify-content: space-between;
    margin-bottom: 16px;
    position: relative;
    .metadata-items {
        .flex-row;
        justify-content: space-between;
        .metadata-fields-compressed {
            align-self: flex-start;
            &:first-child {
                width: 65%;
            }
        }
    }
    p {
        word-wrap: break-word;
        word-break: break-all;
        margin: 0;
        .text-overflow;
    }
    .button-confirm-wrapper {
        margin-left: 0;
    }
    .manage-feature {
        position: fixed;
        right: 10px;
        top: 80px;
        z-index: 1;
    }
    .accuracy-fields {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 98px;
        .accuracy-field {
            margin-bottom: @padding-tiny;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .accuracy-field-horizontal {
            .accuracy-field();
            align-self: flex-start;
        }
        .accuracy-field-vertical {
            .accuracy-field();
            align-self: flex-end;
        }
    }
    .post-processing-controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .post-processing-provider {
            max-width: 100%;
            .post-processing-status {
                display: flex;
                min-height: 24px; // To account for help text button
            }
            .unprocessed-feature-label {
                font-size: 13px;
                font-style: italic;
                font-weight: 400;
                color: @col_gray_8;
            }
        }
        .post-processing-action {
            display: none;
        }
        &.controls-active:hover {
            .post-processing-provider {
                width: 70%;
            }
            .post-processing-action {
                display: block;
            }
        }
    }
    .custom-tag-section {
        > .tag-input-alternative {
            border: none;
            padding: 0;
            background: none;
        }
    }
    .no-tags-selected {
        .button-icon-text {
            padding-top: 0 !important;
        }
    }
}

.page-content {
    &.empty-page-header {
        padding-top: @padding-default;
    }
}

.metadata,
.page-content,
.group-content,
.feature-page > .field-content {
    padding-left: @default-padding-value;
    padding-right: @default-padding-value;
}

.field-content {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .summary-button {
        cursor: pointer;
    }

    .feature-field-container {
        width: 100%;
    }
}

.group-content {
    .field-content {
        margin-bottom: 8px;
    }
}

.attrListItem.Image {
    width: 100px;
    height: 100px;
    margin: 2px;
    display: block;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-top: solid 4px @col_white;
    border-right: solid 4px @col_white;
    border-bottom: solid 10px @col_white;
    border-left: solid 4px @col_white;
    cursor: pointer;
    .border-radius(@border-radius-value);
}

.attrListItem.Signature {
    width: 160px;
    height: 60px;
    margin-top: 5px;
    display: block;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    .border-radius(@border-radius-value);
}

.mini-map {
    .leaflet-pane.leaflet-map-pane {
        z-index: auto;
    }
}

feature-panel {
    .modal-popup {
        overflow: hidden;
    }
}

.accuracy-button {
    cursor: pointer;
    padding: @padding-tiny 0 @padding-tiny 0;

    .accuracy-button-icon {
        .icon-accuracy;
        position: relative;
        left: 5px;
    }

    &.selected {
        background-color: @col_trimble_blue_mid !important;
        .accuracy-button-icon {
            .icon-accuracy-white;
        }
    }
}

.image-field-wrapper {
    display: flex;
    flex-wrap: wrap;
}
