.panel-pointer {
    content: '';
    height: 30px;
    width: 20px;
    top: 25px;
    left: 290px;
    position: absolute;
    background-color: @col_gray_half;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.parent-filter {
    width: 320px;
    filter: drop-shadow(1px 1px 2px rgba(54, 53, 69, 0.65));
    position: absolute;
    right: 325px;
    top: 15px;
    z-index: 11;
}
.chart-panel {
    display: flex;
    flex-direction: column;
    background-color: @col_gray_half;
    border-radius: 2px;
    height: 100%;
    width: 95%;

    &__container {
        padding-left: @padding-default;
        padding-right: @padding-default;

        h2 {
            padding-top: @padding-default;
            .text-overflow;
        }
        &:last-child {
            padding-bottom: @padding-small;
        }
    }

    span {
        margin: @padding-small 0 @padding-small 0;
    }

    .previous-selection {
        display: flex;
        cursor: pointer;
        border-top: solid 1px @col_gray_0;
        justify-content: center;
        align-items: center;
        padding: @padding-small 0;

        &:hover {
            background-color: @col_trimble_blue;
            .previous-selection__text {
                color: #fff;
            }
        }

        &__icon {
            background-color: @col_trimble_blue;
            padding: 4px;
            border-radius: 50px;
            margin: @padding-small @padding-small @padding-small 0;
        }

        &__text {
            display: inline-flex;
            align-items: center;
            color: @col_trimble_blue;
        }
    }
}

.summary-panel {
    min-height: 380px;
}

gsp-chart {
    margin-bottom: @padding-default;
}
