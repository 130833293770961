.error-tooltip-content {
    max-width: 300px;
    word-wrap: break-word;
    padding: 5px 12px 5px 12px;
    text-align: left;
    display: none;
    z-index: 5000;
    position: absolute;
    box-shadow: 1px 1px 10px rgba(54, 53, 69, 0.13);
    font-size: 12px;
    background-color: #fff;
    border: 1px solid @col_gray_1;
    // padding: 4px;
    border-radius: 0;
    color: @col_trimble_gray;
    &.show {
        .animated(0.3s);
        .fadeIn;
        position: fixed;
        display: block;
        z-index: 1100; // needs to be changed. Problem because of the dropdown z-index which is 1000
    }
}
.error-tooltip-content.show.top::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.9) transparent transparent transparent;
}

.error-tooltip-content.show.bottom::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(255, 255, 255, 0.9) transparent;
}

.error-tooltip-content.left::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.9);
}

.error-tooltip-content.right::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent rgba(255, 255, 255, 0.9) transparent transparent;
}

.error-highlight {
    border: 1px solid @col_red !important;
    border-radius: 2px;
}

// fix for automatic red box shadow in firefox for required input elements
input:required {
    box-shadow: none;
}

.qtip-custom-style {
    box-shadow: 1px 1px 10px rgba(54, 53, 69, 0.13) !important;
    font-size: 12px !important;
    background-color: #fff;
    border: 1px solid @col_gray_1;
    padding: 4px !important;
    border-radius: 0;
    color: @col_trimble_gray;
}
