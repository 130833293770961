template-wizard {
    .flex-column;
    height: 100vh;
    #wizard-template-editor {
        z-index: 11;
        background: @col_gray_light;
        .flex-column;
        height: 100vh;
        overflow: hidden;
        header {
            .flex-box;
            padding: @padding-default;
            border-bottom: solid 1px @col_gray_2;
            .title {
                .flex-column;
                width: 100%;
                .layer-detail {
                    .flex-row;
                    .align-center;
                    h4 {
                        padding-left: @padding-default;
                    }
                }
            }
            .button-set {
                .flex-row;
                padding: 0 @padding-default;
                > * {
                    .flex-column;
                }
            }
        }
    }

    .field-values {
        .instructions {
            width: 190px;
            height: 100%;
            .flex-column;
            padding: @padding-default;
            padding-top: 70px;
            flex: 0 0 auto;
            border-right: solid 1px @col_gray_2;
            .instruction-title {
                font-weight: 600;
            }
            div {
                &:first-child {
                    margin-bottom: 10px;
                }
                margin-bottom: 40px;
            }
            /*&::after{
        content: '\00a0';
        display: block;
        width: 170px;
        border-right:solid 1px @col_gray_2;
        position: absolute;
        bottom:0;
        top:90px;
      }*/
        }
        custom-scroll {
            width: 100%;
        }
        .fields-label {
            .flex-column;
            padding: @padding-large;
            padding-top: 110px;
            flex: 0 0 auto;
            align-self: flex-start;
            height: 100%;

            div {
                line-height: 42px;
            }
            .sample {
                display: flex;
                height: 100%;
                align-items: center;
            }
        }
        .fields {
            height: 100%;
            > div {
                height: 100%;
            }
            .flex-row;
            align-items: flex-start;
            padding: @padding-large @padding-default @padding-small @padding-default;
        }
    }
    .sample-data {
        height: 455px;
        .flex-column;
        .justify-start;
        border-top: 1px solid @col_gray_light;
        margin-top: 32px;
        overflow: hidden;
        .sample-data-item {
            padding: 0 @padding-default;
            min-height: 32px;
            border-bottom: 1px solid @col_gray_light;
            .text-overflow;
            line-height: 32px;
            vertical-align: middle;
            color: @col_gray_8;
        }
    }
    .field-values {
        height: 100vh;
        .flex-row;
        .align-start;
        overflow-x: auto;
        overflow-y: hidden;
        .group-container {
            .flex-row;
            padding: 0 10px;
        }
        .field-value {
            width: 250px;
            height: ~'calc(100vh - 275px)';
            margin: 70px 2px 0 2px;
            .mixin-fixed;
            .flex-column;
            background-color: @col_gray_half;
            border: 1px solid @col_gray_2;
            .meta-data {
                display: none;
                .meta-data-set {
                    .flex-column;
                    padding: 12px;
                    padding-left: 20px;
                    // needed for text ellipsis to work
                    // https://stackoverflow.com/questions/43809612/prevent-a-child-element-from-overflowing-its-parent-in-flexbox
                    min-width: 0;
                    .meta-name,
                    .meta-type {
                        .flex-row;
                        .text-overflow;
                    }
                }
                .next-button {
                    .flex-column;
                    .justify-center;
                    padding-right: 12px;
                    gsp-button {
                        .flex-row;
                    }
                }
            }
            &.active {
                margin: -4px 0 2px;
                height: ~'calc(100vh - 208px)';
                .mixin-floating-panel-light;
                .mixin-shadow-large;
                .meta-data {
                    min-height: 70px;
                    border-top: 6px solid @col_blue;
                }
                .field-set {
                    border-top: solid 1px @col_gray_0;
                    margin: 0px;
                    .field-name {
                        background: @col_white;
                        font-weight: 600;
                        word-wrap: break-word;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .field-type {
                        .toggle-button,
                        .dd-inner-li {
                            background: @col_white;
                        }
                    }
                }
                .meta-data {
                    .flex-row;
                }
            }
            &.verified {
                margin: 67px 2px 0 2px;
                .field-set {
                    border-top: 3px solid @col_green;
                    margin-bottom: 0;
                    .field-name-wrapper {
                        .status-indicator {
                            display: block;
                        }
                    }
                }
            }
            .field-set {
                .field-name-wrapper {
                    .flex-box;
                    .flex-row;
                    border-bottom: 1px solid @col_gray_0;
                    .status-indicator {
                        height: 32px;
                        width: 40px;
                        border-radius: 20px;
                        background: @col_green;
                        display: none;
                    }
                }
                .field-name {
                    background: @col_gray_half;
                    border: none;
                    border-radius: 0;
                    .flex-column;
                    padding-left: @padding-default;
                    height: 40px;
                    .text-overflow;
                }
                .field-type {
                    height: 42px;
                    .flex-box;
                    .field-type-icon,
                    .field-type-name {
                        .flex-column;
                        background: @col_white;
                        .justify-center;
                    }
                    .field-type-icon {
                        border-right: 2px solid @col_gray_0;
                        width: 40px;
                        .align-center;
                    }
                    .field-type-name {
                        width: 100%;
                        padding-left: @padding-default;
                    }
                    .toggle-button,
                    .dd-inner-li {
                        background: @col_gray_half;
                        border: none;
                        border-radius: 0;
                        height: 40px;
                        padding: 0 5px;
                        .toggle-text {
                            padding-left: @padding-default;
                        }
                    }
                    .dd-inner-li {
                        padding-left: 0;
                    }
                    .toggle-button {
                        border-bottom: 1px solid @col_gray_0;
                        padding: 0;
                    }
                    .dropdown-icon {
                        border-right: 1px solid @col_gray_0;
                        .align-center;
                        height: 100%;
                        width: 41px;
                        height: 40px;
                    }
                    .dropdown-menu {
                        bottom: inherit;
                        padding: 0;
                        border: none;
                    }
                }
            }
        }
    }
}
