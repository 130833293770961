//mixin classes for lists

.list-item {
    padding: @padding-small @padding-default @padding-small;

    &:hover {
        cursor: pointer;
        background-color: @col_blue_pale;
    }

    a.list-item-title {
        color: inherit;
        text-decoration: none;
    }
}

.list-item.disabled {
    &:hover {
        background-color: transparent;
    }
}

.gsp-context-menu-trigger {
    visibility: hidden;
    position: fixed;
}

.menu-title {
    letter-spacing: initial;
    line-height: @padding-large;
    height: @padding-large;
    padding: 0 16px;
}

h5.mat-mdc-menu-item[disabled] {
    color: unset;
}

.cdk-overlay-pane {
    .mat-mdc-menu-panel {
        min-height: 56px;
        min-width: 160px !important;
        background: @col_white;
        .mat-mdc-menu-content .mat-mdc-menu-item {
            display: flex;
            min-height: 40px !important;
            line-height: 40px;
            height: 40px;
            .mat-mdc-menu-item-text {
                display: flex;
                align-items: center;
                font-family: Roboto, Helvetica Neue, sans-serif;
                font-size: 14px;
                letter-spacing: initial;
            }
            &:hover,
            &.cdk-keyboard-focused:not([disabled]) {
                background-color: @col_blue_pale !important;
            }

            &.cdk-program-focused:not([disabled]) {
                background-color: unset;
            }
        }
    }
    .mdc-menu-surface {
        box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    }
}
