/*
Icons/doodads on the map
*/

.leaflet-marker-pane {
    //------------------

    // had to prefix the iconKey because the values come off the data feed
    // as a hex color, and those are invalid css styles because you can't start
    // a css style name with a number

    .marker,
    .marker * {
        outline: none;
        /* suppress blue selection border that browser adds */
    }

    .marker {
        //margin-left: -12px; margin-top: -12px;
        display: block;

        cursor: url(../assets/images/cursors/select.cur);

        .marker-inner {
            border-radius: 50%;
        }

        .marker-background {
            width: 100%;
            height: 100%;
        }

        &.unselected {
            width: 8px;
            height: 8px;
            margin: -4px;

            .marker-inner {
                width: 100%;
                height: 100%;
                .mixin-shadow-small;
            }
        }

        &.selected {
            width: 18px;
            height: 18px;
            margin: -9px;

            .marker-background {
                border-radius: 50%;
                background-color: @col_white;
                .mixin-shadow-medium;
                padding: 3px;
            }

            .marker-inner {
                width: 12px;
                height: 12px;
            }
        }

        &.active {
            width: 48px;
            height: 48px;
            margin: -46px 0 0 -18px;

            .marker-background {
                background-image: url(../assets/images/sym-pointer.svg);
                padding: 2px;
            }

            .marker-inner {
                width: 32px;
                height: 32px;
            }
        }

        .marker-task {
            display: none;
            z-index: -1;
        }

        //&.in-task .marker-task::before{
        //    content:'';
        //    border:2px solid @col_markup; /* use background-color on this... put border on the other? */
        //}

        &.in-task .marker-task,
        &.updated .marker-task {
            border-radius: 50%;
            display: block;
            position: absolute;
            width: 32px;
            height: 32px;
            opacity: 1;
            background: transparent;
            //background-color:@col_markup;/* delete  this after no errors */
        }

        &.updated .marker-task {
            border-width: 2px;
            border-style: solid;
        }

        &.in-task .marker-task {
            border-width: 2px;
            border-style: dashed;
        }

        &.unselected.in-task .marker-task,
        &.unselected.updated .marker-task {
            top: -12px;
            right: 0;
            bottom: 0;
            left: -12px;
        }

        &.selected.in-task .marker-task,
        &.selected.updated .marker-task {
            top: -7px;
            right: 0;
            bottom: 0;
            left: -7px;
        }

        &.active.in-task .marker-task,
        &.active.updated .marker-task {
            top: 30px;
            right: 0;
            bottom: 0;
            left: 2px;
        }
    }
}
