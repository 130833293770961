.marker-cluster {
    border-radius: 50%;
    position: absolute;
    opacity: 0.6;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    .marker-cluster-outer {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
    }
    .marker-cluster-inner {
        left: 14%;
        top: 14%;
        width: 72%;
        height: 72%;
        border-radius: 50%;
        position: absolute;
        display: flex;
        align-items: center;

        span {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 12px; /*'open sans', "Helvetica Neue", Arial, Helvetica, sans-serif;*/
            font-weight: 600;
            color: @col_white;
            text-shadow: 0.5px 0.5px 1px #363545; //use variable
        }
    }
}
