@import '../../node_modules/ngx-toastr/toastr.css';

.undo-link {
    text-decoration: underline;
    cursor: pointer;
}

.toast-success {
    .mixin-success;
    background-color: fade(@col_green, 90%);
    background-image: url(../assets/images/icons/success.svg);
}

.toast-error {
    .mixin-critical;
    background-color: fade(@col_red, 90%);
    background-image: url(../assets/images/icons/error_toast.svg);
}

.toast-info {
    .mixin-information;
    background-color: fade(@col_blue, 90%);
    background-image: url(../assets/images/icons/info.svg);
}

.toast-warning {
    .mixin-warning;
    background-color: fade(@col_orange, 90%);
    background-image: url(../assets/images/icons/warning_toast.svg);
}
