rtc-panel {
    flex-flow: column nowrap;

    .rtc-section {
        flex: 1 1 auto;
        overflow: hidden;

        .dropdown-menu {
            bottom: inherit;
        }
    }
}

.mat-mdc-tab-body-content rtc-panel {
    display: flex;

    .rtc-section {
        .rtc__list {
            height: 13%;
        }
        custom-scroll {
            height: 87%;
        }
    }
}

// Temporary solution for safari z-index issue where rtc modal is not shown correctly
// Better fix is to move it outside of mat-mdc-tab-group
.unset-z-index .mat-mdc-tab-body-wrapper .mat-mdc-tab-body-active {
    z-index: unset;
}

.rtc-delete {
    > .loading {
        background-position: right;
    }
}

.rtc {
    display: flex;
    flex-flow: column nowrap;
    height: 90%;

    &__list {
        &-item {
            padding: 20px 20px 20px 40px;
            border-top: 1px solid @col_gray_1;
            min-height: 105px;

            &-title {
                .rtc-name {
                    max-width: 130px;
                    word-break: break-all;
                }
            }
            &-description {
                max-width: 300px;
                color: @col_gray_8;
                word-break: break-all;
            }
            &-heading {
                .rtc-list-dropdown {
                    height: 32px;
                }

                &.source {
                    .dropdown-container {
                        width: 150px;
                    }
                }
            }
            &-controls {
                padding-left: 0;
                padding-right: 0;
            }

            &--header {
                color: @col_trimble_blue_mid;
                padding-bottom: 0;
                border-top: none;
                min-height: auto;
                &:hover {
                    background-color: transparent !important;
                }
            }

            &:hover,
            &.active {
                background-color: @col_gray_light;
            }

            &--disabled {
                background-color: @col_gray_half;
                .rtc__list-item-title,
                .rtc__list-item-description,
                .rtc-title,
                .rtc-link .button-container {
                    opacity: 0.5;
                }
            }
        }
        &--empty {
            text-align: center;
            img {
                opacity: 0.2;
                width: 300px;
                margin: 0 auto;
            }
        }
    }
}
