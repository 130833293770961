.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: @font-size-small;
    font-weight: @badge-font-weight;
    line-height: @badge-line-height;
    color: @badge-color;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: @badge-bg;
    border-radius: @badge-border-radius;

    &--primary {
        background-color: @col_trimble_blue_mid;
    }

    &--secondary {
        background-color: @col_trimble_yellow;
        color: @col_black;
    }

    &--hollow {
        background-color: transparent;
        border: 1px solid @col_gray_6;
        color: @col_gray_6;
    }

    // old style badge for layer list no position feature count badge
    &--old {
        position: absolute;
        right: 24px;
        top: 5px;
        margin: 0;
        padding: 0;
        border-radius: 10px;
        height: 16px;
        line-height: 16px;
        min-width: 24px;
        background-color: @col_gray_8;
        color: @col_white;
        font-size: 11px;
        font-weight: 600;
        .text-overflow;
        max-width: 24px;
        text-align: center;
    }
}
