.mat-accordion {
    .mat-expansion-panel {
        background: none;
        box-shadow: none !important;
        &:hover {
            background: none;
        }
    }

    .mat-expansion-panel-header {
        padding: 0 2px;

        &.import-message-panel-header {
            padding: 0;
            .mat-content {
                display: block;
                margin-right: @padding-tiny;
            }
            .mat-expansion-indicator {
                padding: 0 2.5px @padding-tiny 2.5px;
                margin-bottom: @padding-tiny;
                width: 15px;
            }
        }
    }

    .mat-expansion-panel-spacing {
        margin: 0;
    }

    .mat-expansion-panel-body {
        padding: 0;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
        background: none;
    }
}
