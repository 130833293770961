/* Layout For the editor */

#page-template-editor,
body > field-item {
    .field-name-label {
        max-width: 440px;
    }
    .rule-field-item {
        .field-name-label {
            max-width: 350px;
        }
    }
    .checkbox2 {
        margin-right: 10px;
    }
    .group {
        > .field-header-update {
            position: absolute;
            right: 1px;
        }
    }
    .control-group {
        & > * {
            &:first-child {
                & .page-header-list {
                    border-top: none;
                    margin-top: -15px;
                }
                .pageheader {
                    .page-divider {
                        content: '';
                        display: block;
                        height: 0;
                        margin-top: 0;
                        margin-bottom: 0;
                        background-color: @col_gray_light;
                        margin-left: 0;
                        margin-right: 0;
                        border-top: none;
                        border-bottom: none;
                    }
                }
            }
            & > li {
                &.page-header-list {
                    .update-options-icons {
                        bottom: 0;
                    }
                }
            }
        }
    }
    .editor-field {
        flex: 1 1 auto;
        .flex-column;
        /* defines the overall field layout with stacked blocks */
        background-color: @col_gray_half;
        /* this defines the common design for the blocks that stack */
        border-bottom: 1px solid @col_gray_1;
        .editor-field-header {
            .flex-row;
        }
        .editor-field-header {
            align-items: center;
            .field-header-options {
                flex: 1 1 auto;
                .field-header-inputs {
                    height: 64px;
                    flex: 1 1 auto;
                    position: relative;
                    padding-right: @padding-default;
                    .field-icon {
                        justify-content: center;
                    }
                    .field-name {
                        padding-left: @default-padding-value;
                        justify-content: center;
                        .field-name-label {
                            .text-overflow;
                            align-self: flex-start;
                        }
                        &.page-header-label {
                            padding-left: @padding-small;
                        }
                    }
                    .field-icon,
                    .field-name,
                    .field-check,
                    .field-actions {
                        .flex-column;
                        justify-content: center;
                        align-content: center;
                        align-items: center;
                        height: 100%;
                    }
                    .field-icon {
                        flex: 0 0 auto;
                    }
                    .field-actions {
                        flex: 1;
                    }
                    field-name {
                        display: flex;
                        flex: 1 0 auto;
                    }
                    .field-name {
                        flex: 1 0 auto;
                    }
                    .field-check {
                        flex: 0 0 auto;
                        align-items: flex-start;
                        padding-left: @default-padding-value;
                        div {
                            .flex-row;
                        }
                        .checkbox2 {
                            margin-right: 10px;
                        }
                    }
                    .field-actions {
                        > * > div {
                            .flex-row;
                        }
                        .property-icons {
                            align-self: flex-end;
                            opacity: 0.5;
                            display: inline-flex;
                            width: 40px;
                            justify-content: center;
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                    .field-update {
                        max-width: 64px;
                    }
                    .field-status {
                        min-width: 20px;
                    }
                }
            }
        }
        .editor-field-section {
            /* this is the block that sites under the header */
            .grouped-elements {
                .mixin-full-width;
            }

            .template-fields-info {
                padding: 0 @padding-default @padding-small @padding-default;
            }

            .flex-column;
            .mixin-full-width;
            .properties-edit,
            .condition-edit {
                .mixin-full-width;
                .flex-column;
                /* background-color:@col_white; */
                padding: @padding-default;
                padding-left: 70px;
                padding-right: @padding-large;
                border-top: solid 1px @col_gray_1;
            }
            .properties-edit {
                display: inline-block;
            }
        }
        &.pageheader {
            .page-divider {
                content: '';
                display: block;
                height: 2rem;
                margin-top: @padding-default;
                /*margin-bottom: 5px;*/
                background-color: @col_gray_light;
                margin-left: -32px;
                margin-right: -32px;
                border-top: solid 1px @col_gray_1;
                border-bottom: solid 1px @col_gray_1;
            }
            .field-header-options {
                position: relative;
            }
            .field-name-label {
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }
    .field-header-options,
    .field-header-update,
    .field-section-options,
    .field-section-update {
        .flex-column;
        .field-header-inputs {
            .flex-row;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-content: stretch;
        }
    }
    .field-header-update,
    .field-section-update {
        background-color: @col_gray_half;
    }
    .field-header-options,
    .field-section-options {
        flex: 1;
    }
}

// fix group fields layout issue in rules tab
body > field-item {
    .tfx-group.rule-field-item {
        .field-name {
            width: 400px;
        }
    }
}

labeller {
    flex: 0;
}

/* Styling for the various Components within the layout */

/*############################## */

/* Field Row component */

/*############################## */

.editor-field-row {
    .mixin-full-width;
    .flex-row;
    choice-field,
    coded-choice-field {
        width: 100%;
        .editor-field-row {
            .button-confirm-wrapper {
                margin-left: 0;
            }
            .button-container {
                margin-right: 0;
            }
        }
        .edit-text .editor-field-row:last-child {
            width: 27%;
            justify-content: space-between;
        }
    }
    gsp-checkbox {
        overflow: hidden;
    }
    margin-bottom: 10px;
    > * > label,
    .multi-select {
        .flex-row;
        align-items: center;
        .mixin-fixed;
        span {
            min-width: 120px;
            &.warning {
                display: block;
                font-size: 12px;
            }
        }
    }
    input[type='text'],
    input[type='number'] {
        &.ng-invalid {
            border: solid 1px @col_red;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.editor-field-choice {
        justify-content: space-between;
        .transition(all 0.2s ease-in-out);
        .mixin-active-colors-pale;
        > div {
            .flex-column;
            justify-content: center;
        }

        .code-description-wrapper {
            flex-flow: row nowrap !important;
            max-width: 59%;

            .code {
                max-width: 29%;
                margin-right: 10px;
            }

            .description {
                max-width: 70%;
                margin-right: 10px;
            }
        }
    }
    textarea {
        min-height: 145px;
        padding: 10px;
        overflow: hidden;
        border: solid 1px @col_gray_1;
    }
    .date-wrapper {
        margin-right: @padding-default;
        width: 140px;
        input.gsp-form-input {
            width: 110px;
        }
        .pikaday-wrapper.pikaday-icon {
            min-width: 32px;
        }
    }
    .button-container {
        margin-left: 0;
        margin-right: 10px;
    }
}

.add-rule .button-container button {
    margin-left: 65px;
    margin-bottom: 20px;
}

/* END OF #page-template-editor scope */
