/* Template editor - layout styles */
/*html,body{
  width:100%;
  height:100%;
  margin:0;
  padding:0;
} Does this need to be re-specified here?*/

#page-template-editor {
    .flex-row;

    width: 100%; /*mixin these somehow */
    height: 100%;

    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;

    .optionsPanel {
        .flex-row;
        align-items: stretch;
        align-content: stretch;
        .mixin-fh; /*full height*/
        justify-content: flex-start;
        background-color: @col_gray_light;
        border-right: 1px solid @col_gray_1;
        z-index: 5; /*brings panel in front of the shadow that is in the header*/

        .optionsTabs,
        .options {
            .ng-scroll-content {
                max-width: 100%;
            }
            .flex-box;
            .flex-column;
            align-items: stretch;
            align-content: stretch;
            .mixin-fh; /*full height*/
            justify-content: flex-start;
            flex: 0 0 auto;
            header {
                flex: 0 auto;
            }
            section {
                .mixin-custom-scroll;
            }
            footer {
                flex: 0 auto;
            }
        }
        .optionsTabs {
            //width:64px;
        }
        .options {
            width: 340px;
        }
    }
    .editorPanel {
        .flex-box;
        .flex-column;
        align-items: stretch;
        align-content: stretch;
        height: 100%;
        justify-content: flex-start;
        header {
            flex-shrink: 0;
        }
        .master-form > section {
            .mixin-custom-scroll;
        }
        footer {
            flex: 0 auto;
        }
    }
    .editorPanel {
        flex: 1;
        > * {
            height: 100%;
        }
    }
}

/* mixin this thing please */
.scrollable-content {
    flex: 1 1 auto;
    overflow-y: auto;
}
