@nav-row-height : 4.8rem;
@header-height : 128px;
@nav-width: 256px;
@left-gutter: 1.6rem;
@header-pad : 56px;
@secondaryWidth: 300px;
@header-controls-height: 85px;

/*
TABS ==============
*/
.tab-header {
    padding: 1rem @header-pad 0px @header-pad;
    height: 5rem;
}

.tab-header-controls {
    .flex-row;
    .align-center;
    height: 5rem;
    padding-left: @header-pad;
    > .icon-col-32 {
        .checkbox2 {
            background-color: @col_gray_3;
            width: 24px;
            height: 24px;
            padding: 4px;
            margin-left: -4px;
            label {
                top: 4px;
                left: 4px;
            }
            &:hover {
                background-color: @col_gray_2;
            }
        }
    }
}

.gsp-list-header,
.controls-ct-column {
    .checkbox2 {
        background-color: @col_gray_3;
        width: 24px;
        height: 24px;
        padding: 4px;
        margin-left: -4px;
        label {
            top: 4px;
            left: 4px;
        }
        &:hover {
            background-color: @col_gray_2;
        }
    }
}

/*
basically the entire app is wrapped in this
*/
.main-container {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
    .flex-row;
    .content-stretch;
}

/* parent container that wraps all content*/
.main_content {
    .flex1;
    height: 100vh;
    overflow-y: auto;
}

//Fixed header for list pages
.gsp-fixed-header {
    .fix-header & {
        position: fixed;
        background: @col_gray_2;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        /*-webkit-box-shadow: 1px 2px 2px 2px #d1d1d1;
        box-shadow: 1px 2px 2px 2px #d1d1d1;*/
        margin-left: 256px;
    }
}

/*
Container for the 'NAV' area of the map (on the left )
*/
.gsp-left-container {
    &.light {
        background-color: @col_gray_3;
    }
    background-color: @col_trimble_gray;
    // .flex(0 0 @nav-width);
    height: 100vh;
}

.logo-container {
    .logo {
        width: 256px;
        height: 64px;
    }
}

.bdr-bottom {
    border-bottom: 1px solid black;
}

/*
  Represents the Right Container of the app,
  basically all the content that lies to the right of the NAVIGATION area
*/
.gsp-content {
    .flex-ct-column;
    .flex1;
    height: 100%;
    padding: 0;
    //overflow: auto;
}

.gsp-content-row {
    position: relative;
    .flex-ct;
    .flex1;
    height: 100vh;
    padding: 0;
    //overflow: auto;
}

/*
  HEADERS of each section
*/
.gsp-message-display {
    padding-left: @header-pad;
    padding-right: @header-pad;
    height: @header-height;
}

/*
  Container that typically lies under the header which contains buttons/pagers, etc.
*/

.controls-ct {
    .flex-row;
    .align-center;
    // padding-left: @header-pad;
    //padding-right: @header-pad;
    padding: 10px 0 10px @header-pad;
    //min-height: 85px;
    &.groups {
        .flex-ct;
        .align-start;
    }
}

.controls-ct-column {
    .flex-ct-column;
    padding: 10px @header-pad;
    min-height: 85px;
    min-width: 600px;
    > .groups {
        display: flex;
        align-items: flex-start;
    }
    /*> .row {

    .flex-ct-row;
    .align-center;
    // padding-left: @header-pad;
    //padding-right: @header-pad;
    padding: 10px @header-pad;
    min-height: 85px;
    min-width: 600px;
    &.groups {
      .flex-ct;
      .align-start;
    }
  }*/
}

.btn-group {
    .btn ~ .btn {
        margin-left: 1px;
    }
    .button-container {
        display: inline-block;
        margin-right: 1px;
    }
}

.controls-ct-column {
    .flex-ct-column;
    padding: 10px @header-pad;
    min-height: 75px;
    min-width: 600px;
    > .groups {
        display: flex;
        align-items: flex-start;
    }
    > .row {
        .flex-row;
        .align-center;
        margin-left: 0px;
        margin-right: 0px;
    }
    > .row ~ .row {
        margin-top: 10px;
    }

    .data-filter {
        > input[type='text'] {
            width: 210px;
            vertical-align: bottom;
        }
        > span {
            vertical-align: middle;
            padding-top: 10px;
        }
    }
}

/**
NAVIGATION ROWS
*/

/* TODO
// site wide navigation element
.nav-row {
    position: relative;
    .flex-ct;
    .box-sizing();
    .align-center;
    min-height: @nav-row-height;
    height: @nav-row-height;
    color: @col_gray_2;
    width: 100%;
    cursor: pointer;
    padding: 10px 58px 10px 18px;
    text-transform: uppercase;
      .nav-left-icon,.icons-nav{
        margin-right:8px;
      }
    &.active {
      font-weight: 400;
        .nav-left-icon.icons-nav{
        margin-right:8px;
        }
        .icon-32-mid-nav-project {
            .icon-32-dark-nav-project;
        }
        .icon-32-mid-nav-tasksapp {
            .icon-32-dark-nav-tasksapp;
        }
        .icon-32-mid-nav-zones {
            .icon-32-dark-nav-zones;
        }
        .icon-32-mid-nav-peopleapp {
            .icon-32-dark-nav-peopleapp;
        }
        .icon-32-mid-nav-survey {
            .icon-32-dark-nav-survey;
        }
        .icon-32-mid-nav-terraflex {
            .icon-32-dark-nav-terraflex;
        }
        .icon-32-mid-nav-org {
            .icon-32-dark-nav-org
        }

    }
    &.light {
        &:hover {
            background-color: @col_gray_light;

        }

        &.active {
            background-color: @col_gray_2;
            color: @col_trimble_gray;
            &:hover {
                cursor: default;
            }
        }
    }
    &.dark {
        color: @col_gray_2;
        //border-bottom: 1px solid @col_gray_4;
        &:hover {
            background-color: @col_trimble_gray;
            .project-icon-label {
                background-color: @col_trimble_gray;
            }
        }
        &.active {
            background-color: @col_gray_2;
            color: @col_trimble_gray;
            &:hover {
                cursor: default;
            }
            & .nav-tab-title {
                color: @col_trimble_gray;
            }
        }
    }
    &.configuration, &.project, &.user-row {
        background-color: @col_gray_10;
        padding-left: 18px;
        text-transform: none;
        padding-right: 58px;
        &:not(.active):hover {
            background-color: @col_trimble_gray;
        }
    }
    &.project {
        color: @col_yellow;
        border-bottom: 1px solid black;
    }
    & .nav-icon {
        position: absolute;
        top: 8px;
        bottom: 0;
        right: 8px;
    }
    &:nth-last-child(1) {
        border-bottom: 1px solid black;
    }
    & .toggle {
        position: absolute;
        top: 18px;
        right: 14px;
    }
    .project-icon-label {
        display: none;

    }
    .notification {
        position: absolute;
        top: 7px;
        left: 15px;
        min-width: 18px;
        height: 18px;
        font-size: 0.9em;
        padding: 0px 0px 0px 0px;
    }
} */
/*
.nav-row.help, .nav-row.documentation, .nav-row.log {
    background-color: @col_gray_10;
}

.collapse-toggle {
    position: fixed;
    bottom: 25px;
    width: 256px;
    z-index: 1500;
    cursor: pointer;
    i {
        display: block;
        float: right;
    }
}

.small-nav {
    .flex(0, 0, 70px);
    .nav-row {
        padding: 10px 10px 10px 10px;
    }
    .logo-container .logo {
        width: 70px;
    }
    .icon-32-mid-nav-org, .icon-32-dark-nav-org {
        margin-left: 9px;
    }
    .logo-icon {
        background-image: url(../images/svg/trimble-64-icon.svg);
        background-position: center;
    }
    .userImage {
        margin-left: 5px;
    }
    .collapse-toggle {
        width: 70px;
        i {
            .icon-32-light-nav-open
        }
    }
    .large-nav-menu {
        display: none;
    }
    .export-indicator {
        left: -7px;
        bottom: 60px;

    }
    .project-icon-label {
        display: block;
        position: absolute;
        left: 16px;
        top: 18px;
        font-size: 1.2rem;
        width: 44px;
        background-color: @col_gray_10;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }
    .nav-row.project {
        padding: 10px 10px 10px 5px;
    }
    .active .project-icon-label {
        background-color: @col_gray_2;
    }
}*/
/*
.collapsed-nav  .gsp-left-container {
    .small-nav;
}*/
/*
.collapsed-nav .fix-header .gsp-fixed-header {
    margin-left: 70px;
}*/
/*
@media screen and (max-width: 1300px) {
    .gsp-left-container {
        .small-nav;
        .collapse-toggle {
            display: none;
        }
    }
    .fix-header .gsp-fixed-header {
        margin-left: 70px;
    }
}*/
/*
.dev-feedback a {
    color: @col_gray_6;
} */

.full-page-wrapper {
    background: #f0f0f0;

    .full-page-content {
        margin: 0px auto;
        padding: 30px;
        width: 920px;
    }
}
