//---------------------------------------------------
//                      POPUPS LESS
//---------------------------------------------------
//
//  This module contains global css classes for popups.
//  The following is an example of a standard popup and gives a template to work with
//
//  <div class="modal-popup">
//      <div class="modal-content">
//          <header class="modal-header">
//              <h2 class="modal-title"><span>Title</span></h2>
//              <button class="btn-icon close"><i class="icon-ModelClose"></i></button>
//          </header>
//      </div>
//      <div class="modal-body"><p>Text here..</p></div>
//      <div class="modal-footer">
//          <gsp-button x-type="primary" x-action="">Ok</gsp-button>
//          <gsp-button x-type="default" x-action="">Cancel</gsp-button>
//      </div>
//  </div>
//

.modal-popup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: fade(@col_trimble_gray, 65%);
    z-index: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .modal-content {
        .flex-container;
        .mixin-floating-panel;
        width: 460px;
        z-index: 1000;
        .flex-column;
        padding: @padding-medium @padding-medium @padding-large @padding-medium;

        .modal-header {
            .mixin-fw;

            &.with-bottom-border {
                border-bottom: 1px solid #b7b9c3;
                margin-bottom: 1em;
            }

            display: flex;
            padding-bottom: @padding-small;
            //border-bottom: 1px solid @col_gray_1;
            // Override default styling for button
            button {
                background: transparent;
                border: none;
            }
        }

        .modal-title {
            .mixin-fw;

            &.with-custom-icon {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .field-icon,
                .field-name {
                    display: inline-block;
                }

                .field-name {
                    margin-left: 4px;
                    font-weight: 700;
                }
            }
        }

        .icon-ModelClose {
            display: block;
            .i24;
            .icon_line_cancel_24;
        }

        .modal-body {
            .flex-column;
            flex: 1 1 auto;
            height: 80%;
        }

        .modal-group {
            .mixin-fw;
            border-bottom: 1px solid @col_gray_1;
            padding: @padding-default 0;
            &:last-of-type {
                border: none;
                padding-bottom: 0;
            }
        }

        .modal-footer {
            //border-top: 1px solid @col_gray_1;
            .flex-row;
            flex-shrink: 0;
            flex-direction: row-reverse;
            padding-top: @padding-small;

            gsp-button {
                margin-right: 5px;
                &:first-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    &.extra-small-modal {
        .modal-content {
            width: 600px;
            height: 235px;
        }
    }

    &.small-modal {
        .modal-content {
            width: 600px;
            height: 660px;
        }
    }

    &.fluid-modal {
        .modal-content {
            min-width: 600px;
            min-height: 370px;
            height: auto;
        }
    }

    &.fluid-large-modal {
        .modal-content {
            min-width: 900px;
            min-height: 370px;
            height: auto;
        }
    }

    // Width to height ration needs fixing
    &.medium-modal {
        .modal-content {
            height: 540px;
            width: 800px;
        }
    }

    &.large-modal {
        .modal-content {
            width: 909px;
            height: 650px;
        }
    }

    &.xlarge-modal {
        .modal-content {
            height: 693px;
            width: 996px;
        }
    }

    &.xxlarge-modal {
        .modal-content {
            height: 720px;
            width: 996px;
        }
    }

    &.wide-modal {
        .modal-content {
            height: 500px;
            width: 900px;
        }
    }

    &.custom-modal {
        .modal-content {
            height: fit-content;
            width: fit-content;
        }
    }

    // ----------------------- Wizard ---------------------------
    gsp-wizard-header,
    gsp-wizard-footer {
        height: 10%;
    }
    // ----------------------------------------------------------
}

// ----------------------- Wizard ---------------------------
gsp-wizard {
    .modal-popup .modal-content .modal-body {
        height: 80%;
    }
}
// ----------------------------------------------------------

.centre-dialog {
    .transition(all 0.5s);
}
.centre-dialog.ng-enter {
    .modal-popup {
        .animated(0.5s);
        .fadeIn;
    }

    .modal-content {
        .animated(0.5s);
        .fadeIn;
    }
}
.centre-dialog.ng-leave {
    .modal-popup {
        .animated(0.5s);
        .fadeOut;
    }

    .modal-content {
        .animated(0.5s);
        .fadeOut;
    }
}

// ----------------------- Sensor Popup ---------------------------
.sensor-popup {
    loading {
        padding: 10%;
    }

    .sensor-label,
    .mw-label {
        font-weight: 700;
        margin: 1.5em 0 0.5em;
    }

    #sensor,
    #measurementWorkflow {
        max-width: 70%;
    }
}
// ----------------------------------------------------------------
