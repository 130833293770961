.modal-body {
    margin: 0;
    padding: 0;
}

.modal-header {
    padding: 0;
    margin: 0;
    border: none;
}

.modal-footer {
    padding: 0;
    margin: 0;
    border-top: none;
}
