#menu-tabs {
    nav {
        .flex-box;
        .flex-column;
        justify-content: space-between;
        width: 4.8rem;
    }

    ul {
        .over-map;
        /* lifts it above the map */
        .mixin-floating-panel;
        /* Pulls in shadow, and styling */
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        width: 100%;
        /* pulls width from parent */
        margin-bottom: 10px;
        .mixin-menu-padding;

        > li {
            .mixin-active-colors-strong;
            width: 100%;
            /*overrides the standard width */
            text-align: center;

            /*pulls things off the left edge */
            &.filter-menu.active-filter {
                background-color: @col_trimble_yellow !important;
            }
        }

        li .button-confirm-wrapper {
            margin-left: 0;
        }
    }

    .button:hover {
        background-color: transparent;
    }
}

.uib-dropdown {
    margin-top: 20px;
}
