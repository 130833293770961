/* ################################# */
/* Responsive content for the template editor */
/* ################################# */

body {
    #page-template-editor {
        .master-form {
            > header {
                .responsive(@tablet, {max-width: 100%;});
                .responsivemax(70em, {flex-flow: row wrap;});
                .template-header-title {
                    .responsive(@tablet, {display: none;});
                }
                div {
                    &:last-child {
                        .responsive(@tablet, {text-align: center;});
                    }
                }
            }
            > section {
                .responsive(@medium-max, {/* check if this is working */ margin-left: -1px; margin-right: -1px;});
            }
        }
        .scroll-content {
            /* This targets the page container and removes it on small screens. */
            > .control-group {
                .responsive(@tablet, {padding: 30px; margin: 25px 0 150px 0; border: none; max-width: 100%;});
                > li {
                    &:first-child {
                        &.page-header-list {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .optionsPanel {
            .responsive(@large-max, {flex-flow: column nowrap;});

            .options,
            .styles-panel {
                .responsive(@large-max, {width: 280px;});
            }
            .rules-panel {
                .responsive(@large-max, {padding: 20px 15px;});
            }
            .styles-panel {
                .responsive(@large-max, {padding: 20px;});
            }

            .field-tile {
                .responsive(@large-max, {width: 100%;});
            }
            .optionsTabs {
                .responsive(
                    @large-max,
                    {flex-flow: row nowrap; width: 100%; height: initial; justify-content: space-between; border-bottom:
                        1px solid @col_gray_1; border-right: none;}
                );

                section {
                    .responsive(@large-max, {display: flex; justify-content: space-around;});

                    div {
                        .responsive(@large-max, {min-width: 48px; padding: 22px 0;});

                        > span {
                            .responsive(@large-max, {display: none;});
                        }
                    }
                }
            }
        }
        .gsp-header {
            .responsive(@medium-max, {height: 43px;});
        }

        .data-update-main .wrap-title {
            .responsive(@medium-max, {width: 40%;}) !important;
        }

        .date {
            .editor-field {
                .editor-field-row {
                    label {
                        .responsive(@medium-max, {width: 100%;});
                    }
                }
            }
        }

        .editor-field {
            .editor-field-row {
                .responsive(@medium-max, {flex-flow: row nowrap;});
                .responsive(@tablet, {flex-flow: row wrap;});

                > label {
                    .responsive(@tablet, {flex: 0 1 auto; flex-flow: row wrap; width: 50%;});
                    .gsp-form-input {
                        .responsive(@tablet, {width: 100%;});
                    }
                }
                textarea {
                    width: 100%;
                }

                .date-wrapper {
                    .gsp-form-input {
                        width: 110px;
                    }
                }
            }

            .field-header-inputs {
                .responsive(@tablet, {width: 75%; flex: 0 1 auto;});
            }

            .field-name {
                .responsive(@tablet, {flex: 0 1 auto;});
            }
        }
        &.rule {
            .label-holder {
                .responsive(@tablet, {min-width: 340px;});
            }
            .editor-field.active .editor-field-header .field-header-options {
                .responsive(@tablet, {height: auto;});
                .field-header-inputs {
                    .responsive(@tablet, {height: 64px; width: 100%;});
                }
            }
        }
    }
}

#page-template-editor .editor-field.active .editor-field-header .field-header-options {
    .responsive(@tablet, {height: 125px;});
    .field-header-inputs {
        .responsive(@tablet, {height: auto;});
        .field-check {
            .responsive(@tablet, {padding-left: 70px;});
        }
    }
}
