// make the hidden attribute work stronger
[hidden] {
    display: none !important;
}

.link {
    color: @col_blue;
    cursor: pointer;
    font-weight: bold;
}

.selected-tick-class {
    position: absolute;
    right: 0;
    top: 25px;
    transform: rotate(224deg);
}

.beta {
    position: fixed;
    z-index: 1000;
    bottom: 30px;
    left: 10px;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
