.cdk-overlay-pane {
    .map-preview-group.mat-mdc-menu-panel {
        max-width: 685px;
        .mat-mdc-menu-content {
            padding: @padding-small @padding-small @padding-small 0;
            position: relative;
            cursor: pointer;

            display: flex;
            flex-direction: row;

            div {
                height: 100%;
                width: 100%;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            .map-preview-item {
                margin: 0 0 0 @padding-small;
                padding: 0;

                .mat-mdc-menu-item-text {
                    flex-direction: column;

                    .map-preview-label {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        bottom: 0;
                        height: 30%;
                        margin-top: -20%;
                        background-color: fade(@col_trimble_gray, 75%);
                        color: @col_gray_2;
                        transition: color 0.5s ease;
                        -webkit-transition: color 0.5s ease;
                        -moz-transition: color 0.5s ease;
                        text-align: center;
                    }

                    &:hover {
                        background-color: unset !important;

                        .map-preview-label {
                            color: @col_white;
                        }
                    }
                }
            }
        }
    }
}
